import React, {useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    GetAllPropertiesWithREQGridThunk,
    onChangeColumnForFilterConditionValue,
    onChangeColumnForFilterValue,
    onFilterPropertiesGrid, onFilterPropertiesWithReqGrid, onFilterSurfacesGrid, onFilterUpcomingPropertiesGrid,
    onSetFiltersButtonsModalOpened,
    onSetFiltersButtonsSelected,
    onSetProperties, onSetPropertiesWithReq, onSetSurfaces, onSetUpcomingProperties,
    selectBrokeragePropertiesDefaultColumnValues,
    selectContactPropertiesSearchedAddress,
    selectPropertiesColumnsForFilters,
    selectPropertiesGridDefaultColumnsValues,
    selectPropertiesGridFiltersForArray,
    selectPropertiesWithReqColumnsForFilters,
    selectPropertiesWithReqGridFiltersForArray,
    selectSurfacesColumnsForFilters,
    selectSurfacesDefaultGridColumns,
    selectSurfacesGridFiltersForArray,
    selectUpcomingPropertiesColumnsForFilters, selectUpcomingPropertiesDefaultColumnValues,
    selectUpcomingPropertiesGridFiltersForArray,
} from "../../../store/propertiesReducer";
import s from './PropertyFilterButton.module.scss';
import moment from "moment";
import {
    onChangeActivitiesGridFilterConditionValue, onChangeActivitiesGridFilterValue,
    onSetActivitiesGridData,
    onSetActivitiesGridFiltersButtonsModalOpened,
    onSetActivitiesGridFiltersButtonsSelected,
    onSetFiltersForActivitiesGrid,
    selectActivitiesGridDefaultRows,
    selectActivitiesGridFilterColumns,
    selectActivitiesGridFiltersForColumns, setActivitiesCurrentKind, setActivitiesCurrentType
} from "../../../store/activitesGrid";
import {gridDataHelper} from "../../../helpers/localStorageHelper";
import {
    onChangeColdOfferRequirementsGridFilterValue, onChangeRequirementsColdOfferGridFilterConditionValue,
    onChangeRequirementsGridFilterConditionValue,
    onChangeRequirementsGridFilterValue,
    onChangeRequirementsSearchedByContactsGridFilterConditionValue,
    onChangeSearchedByContactsRequirementsGridFilterValue,
    onSetColdOfferRequirements, onSetColdOfferRequirementsGridFiltersButtonsSelected, onSetColdOffersRemarksEditMode,
    onSetFiltersForRequirementsColdOfferGrid,
    onSetFiltersForRequirementsGrid,
    onSetRequirements,
    onSetRequirementsColdOfferGridFiltersButtonsModalOpened,
    onSetRequirementsGridFiltersButtonsModalOpened,
    onSetRequirementsGridFiltersButtonsSelected,
    onSetRequirementsSearchedByContactsGridFiltersButtonsModalOpened,
    onSetSearchedByContactsFiltersForRequirementsGrid,
    onSetSearchedByContactsRequirements,
    onSetSearchedByContactsRequirementsGridFiltersButtonsSelected,
    selectRequirementColdOfferGridColumnsForFilters,
    selectRequirementGridColumnsForFilters, selectRequirementsColdOfferGridColumns,
    selectRequirementsColdOfferGridFiltersForArray,
    selectRequirementsGridFiltersForArray,
    selectRequirementsSearchedByContactsDefaultGridColumns,
    selectRequirementsSearchedByContactsGridColumnsForFilters,
    selectRequirementsSearchedByContactsGridFiltersForArray
} from '../../../store/requirementsReducer';
import {
    onChangeAttrCodesGridFilterConditionValue,
    onChangeAttrCodesGridFilterValue,
    onChangeAuditGridFilterConditionValue,
    onChangeAuditGridFilterValue, onSetAttrCodesCurrentDomain,
    onSetAttrCodesGridFiltersButtonsModalOpened,
    onSetAttrCodesGridFiltersButtonsSelected,
    onSetAttrCodesReqs,
    onSetAuditGridFiltersButtonsModalOpened,
    onSetAuditGridFiltersButtonsSelected, onSetBrokeragePropsCurrentStatus, onSetFiltersForAttrCodesGrid,
    onSetFiltersForAuditGrid,
    selectActivityAttributeCodeDefaultRows,
    selectAttrCodesColumnsForFilters,
    selectAttrCodesFiltersForArray,
    selectAuditDefaultColumns,
    selectAuditGridColumnsForFilters,
    selectAuditGridFiltersForArray,
    setUserActivities
} from "../../../store/parametersReducer";
import {
    onChangeBrokerageReqsGridFilterConditionValue,
    onChangeBrokerageReqsGridFilterValue,
    onSetBrokerageReqs,
    onSetBrokerageReqsGridFiltersButtonsModalOpened, onSetBrokerageReqsGridFiltersButtonsSelected,
    onSetFiltersForBrokerageReqsGrid,
    selectBrokerageReqsColumnsForFilters, selectBrokerageReqsDefaultColumns,
    selectBrokerageReqsGridFiltersForArray
} from "../../../store/brokerageReqsReducer";
import {selectUserData} from "../../../store/userReducer";
import backArrow from './../../../img/icons/mobileFiltersArrow.svg'
import {sessionStorageGridFilters, sessionStorageUtilityValues} from "../../../helpers/sessionStorageHelper";
import {
    onChangeRRSurfacesGridFilterConditionValue,
    onChangeRRSurfacesGridFilterValue,
    onFilterRRSurfacesGrid,
    onSetGridFiltersButtonsModalOpened, onSetRRSurfaces,
    onSetRRSurfacesGridFiltersButtonsModalOpened,
    onSetRRSurfacesGridFiltersButtonsSelected,
    selectSurfacesDefaultGridRows,
    selectSurfacesGridColumnsForFilters,
    selectSurfacesGridFilters
} from "../../../store/surfacesReducer";

type PropertyFilterButtonPropsType = {
    textValue: string,
    buttonTitle: string,
    gridType: string,
    isMobile?: boolean
}

const PropertyFilterButton = ({textValue, buttonTitle, gridType, isMobile}: PropertyFilterButtonPropsType) => {
    const [isHovering, setIsHovering] = React.useState(false);
    const dispatch = useAppDispatch()
    const textRef = useRef<HTMLParagraphElement | null>(null);
    const [textWidth, setTextWidth] = useState(0);

    //property grid data
    const propertiesGridColumnFilters = useAppSelector(selectPropertiesGridFiltersForArray)
    let propertiesColumnsForFilters = useAppSelector(selectPropertiesColumnsForFilters)
    //all properties grid data
    const upcomingPropertiesGridColumnFilters = useAppSelector(selectUpcomingPropertiesGridFiltersForArray)
    let upcomingPropertiesColumnsForFilters = useAppSelector(selectUpcomingPropertiesColumnsForFilters)
    const brokeragePropertiesGridColumnFilters = useAppSelector(selectPropertiesWithReqGridFiltersForArray)
    let brokeragePropertiesColumnsForFilters = useAppSelector(selectPropertiesWithReqColumnsForFilters)
    const upcomingPropertiesDefaultGridColumns = useAppSelector(selectUpcomingPropertiesDefaultColumnValues)
    const brokeragePropertiesDefaultGridColumns = useAppSelector(selectBrokeragePropertiesDefaultColumnValues)
    //
    let activitiesGridColumnsForFilters = useAppSelector(selectActivitiesGridFilterColumns)
    const activitiesColumnsFilters = useAppSelector(selectActivitiesGridFiltersForColumns)
    //surfaces grid data
    let surfacesColumnsForFilters = useAppSelector(selectSurfacesColumnsForFilters)
    const surfacesGridColumnFilters = useAppSelector(selectSurfacesGridFiltersForArray)
    //audit grid data
    const auditsGridColumnFilters = useAppSelector(selectAuditGridFiltersForArray)
    let auditColumnsForFilters = useAppSelector(selectAuditGridColumnsForFilters)
    //req grid data
    const requirementsGridColumnFilters = useAppSelector(selectRequirementsGridFiltersForArray)
    let requirementsColumnsForFilters = useAppSelector(selectRequirementGridColumnsForFilters)
    //brokerage reqs data
    const brokerageReqsGridColumnFilters = useAppSelector(selectBrokerageReqsGridFiltersForArray)
    let brokerageReqsColumnsForFilters = useAppSelector(selectBrokerageReqsColumnsForFilters)
    //attr codes grid data
    const attrCodesGridColumnFilters = useAppSelector(selectAttrCodesFiltersForArray)
    let attrCodesColumnsForFilters = useAppSelector(selectAttrCodesColumnsForFilters)
    //Requirements/Searched by contacts grid data
    const requirementsSearchedByContactsGridColumnFilters = useAppSelector(selectRequirementsSearchedByContactsGridFiltersForArray)
    let requirementsSearchedByContactsColumnsForFilters = useAppSelector(selectRequirementsSearchedByContactsGridColumnsForFilters)
    //req cold offers grid data
    const requirementsColdOfferGridColumnFilters = useAppSelector(selectRequirementsColdOfferGridFiltersForArray)
    let requirementsColdOfferColumnsForFilters = useAppSelector(selectRequirementColdOfferGridColumnsForFilters)
    //RR surfaces grid data
    const rrSurfacesGridColumnFilters = useAppSelector(selectSurfacesGridFilters)
    let rrSurfacesColumnsForFilters = useAppSelector(selectSurfacesGridColumnsForFilters)
    // console.log(textValue)
    // console.log(upcomingPropertiesColumnsForFilters)

    let currentColumn =
        gridType === 'Properties' ? propertiesColumnsForFilters.find((c: any) => c.title === textValue)
            : gridType === 'Activities Grid' ? activitiesGridColumnsForFilters.find((c: any) => c.title === textValue)
                : gridType === 'Surfaces' ? surfacesColumnsForFilters.find((c: any) => c.title === textValue)
                    : gridType === 'Requirements' ? requirementsColumnsForFilters.find((c: any) => c.title === textValue)
                        : gridType === 'Requirements/Cold Offer' ? requirementsColdOfferColumnsForFilters.find((c: any) => c.title === textValue)
                            : gridType === 'Audit' ? auditColumnsForFilters.find((c: any) => c.title === textValue)
                                : gridType === 'Brokerage/Requirements' ? brokerageReqsColumnsForFilters?.find((c: any) => c.title === textValue)
                                    : gridType === 'AttrCodes' ? attrCodesColumnsForFilters?.find((c: any) => c.title === textValue)
                                        : gridType === 'All Properties/Upcoming' ? upcomingPropertiesColumnsForFilters?.find((c: any) => c.title === textValue)
                                            : gridType === 'All Properties/REQ' ? brokeragePropertiesColumnsForFilters?.find((c: any) => c.title === textValue)
                                                : gridType === 'Requirements/Searched by contacts' ? requirementsSearchedByContactsColumnsForFilters?.find((c: any) => c.title === textValue)
                                                    : gridType === 'SurfacesRR' ? rrSurfacesColumnsForFilters?.find((c: any) => c.title === textValue)
                                                    : []
    //

    const onFilterClick = () => {
        if (gridType === 'Activities Grid') {
            dispatch(onSetActivitiesGridFiltersButtonsModalOpened({
                headerName: currentColumn.headerName,
                isModalOpened: true
            }))
        } else if (gridType === 'Requirements') {
            dispatch(onSetRequirementsGridFiltersButtonsModalOpened({
                headerName: currentColumn.headerName,
                isModalOpened: true
            }))
        } else if (gridType === 'Requirements/Cold Offer') {
            dispatch(onSetRequirementsColdOfferGridFiltersButtonsModalOpened({
                headerName: currentColumn.headerName,
                isModalOpened: true
            }))
            dispatch(onSetColdOffersRemarksEditMode(false))
            sessionStorageUtilityValues.removeReqRefForReqRemarksEdit()
        } else if (gridType === 'Requirements/Searched by contacts') {
            dispatch(onSetRequirementsSearchedByContactsGridFiltersButtonsModalOpened({
                headerName: currentColumn.headerName,
                isModalOpened: true
            }))
        } else if (gridType === 'Audit') {
            dispatch(onSetAuditGridFiltersButtonsModalOpened({
                headerName: currentColumn.headerName,
                isModalOpened: true
            }))
        } else if (gridType === 'Brokerage/Requirements') {
            dispatch(onSetBrokerageReqsGridFiltersButtonsModalOpened({
                headerName: currentColumn.headerName,
                isModalOpened: true
            }))
        } else if (gridType === 'AttrCodes') {
            dispatch(onSetAttrCodesGridFiltersButtonsModalOpened({
                headerName: currentColumn.headerName,
                isModalOpened: true
            }))
        }
        else if (gridType === 'SurfacesRR') {
            console.log('13')
            dispatch(onSetGridFiltersButtonsModalOpened({
                headerName: currentColumn.headerName,
                isModalOpened: true
            }))
        }
        else {
            dispatch(onSetFiltersButtonsModalOpened({
                headerName: currentColumn.headerName,
                isModalOpened: true,
                gridType: gridType
            }))
        }
    }


    const defaultPropertiesGridColumns = useAppSelector(selectPropertiesGridDefaultColumnsValues)
    const defaultActivitiesGridRows = useAppSelector(selectActivitiesGridDefaultRows)
    const defaultSurfacesGridColumns = useAppSelector(selectSurfacesDefaultGridColumns)
    const defaultRequirementGridColumns = useAppSelector(selectRequirementsSearchedByContactsDefaultGridColumns)
    const defaultAuditGridColumns = useAppSelector(selectAuditDefaultColumns)
    const defaultBrokerageReqsGridColumns = useAppSelector(selectBrokerageReqsDefaultColumns)
    const defaultAttrCodesGridColumns = useAppSelector(selectActivityAttributeCodeDefaultRows)
    const defaultRequirementsSearchedByContacts = useAppSelector(selectRequirementsSearchedByContactsDefaultGridColumns)
    const requirementColdOfferDefaultGridColumns = useAppSelector(selectRequirementsColdOfferGridColumns)
    const rrSurfacesDefaultGridColumns = useAppSelector(selectSurfacesDefaultGridRows)

    const userData = useAppSelector(selectUserData)
    const searchedAddress = useAppSelector(selectContactPropertiesSearchedAddress)

    const handleMouseEnter = () => {
        setIsHovering(true);
        const width = textRef!?.current!?.offsetWidth!!
        setTextWidth(width)
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    const onResetFilter = () => {
        if (currentColumn.title === 'DOMAIN_CD') {
            dispatch(onSetAttrCodesCurrentDomain(null))
        }
        if (currentColumn.title === 'STATUS' && (gridType === 'All Properties/REQ' || gridType === 'All Properties/Upcoming')) {
            dispatch(GetAllPropertiesWithREQGridThunk({
                requestData: {
                    p_agent_ref: Number(userData.user_ref),
                    p_status: 1,
                },
            }))
        }
        // if(currentColumn.title === 'PROP_ADDR_STREET_ADDRESS' && gridType === 'All Properties/Upcoming'){
        //     dispatch(onSetUpcomingProperties({properties: upcomingPropertiesDefaultGridColumns, gridType: gridType}))
        // }
        if (currentColumn.title === 'ACT_KIND') {
            dispatch(setActivitiesCurrentKind(null))
        }
        if (currentColumn.title === 'ACT_TYPE') {
            dispatch(setActivitiesCurrentType(null))
        }
        const filtersForArray: any =
            gridType === 'Properties' ? propertiesGridColumnFilters.filter((f: any) => f.property !== textValue)
                : gridType === 'Activities Grid' ? activitiesColumnsFilters.filter((c: any) => c.property !== textValue)
                    : gridType === 'Surfaces' ? surfacesGridColumnFilters.filter((c: any) => c.property !== textValue)
                        : gridType === 'Requirements' ? requirementsGridColumnFilters.filter((c: any) => c.property !== textValue)
                            : gridType === 'Requirements/Cold Offer' ? requirementsColdOfferGridColumnFilters.filter((c: any) => c.property !== textValue)
                                : gridType === 'Audit' ? auditsGridColumnFilters.filter((c: any) => c.property !== textValue)
                                    : gridType === 'Brokerage/Requirements' ? brokerageReqsGridColumnFilters.filter((c: any) => c.property !== textValue)
                                        : gridType === 'AttrCodes' ? attrCodesGridColumnFilters.filter((c: any) => c.property !== textValue)
                                            : gridType === 'All Properties/Upcoming' ? upcomingPropertiesGridColumnFilters.filter((f: any) => f.property !== textValue)
                                                : gridType === 'All Properties/REQ' ? brokeragePropertiesGridColumnFilters.filter((f: any) => f.property !== textValue)
                                                    : gridType === 'Requirements/Searched by contacts' ? requirementsSearchedByContactsGridColumnFilters.filter((f: any) => f.property !== textValue)
                                                        : gridType === 'SurfacesRR' ? rrSurfacesGridColumnFilters.filter((f: any) => f.property !== textValue)
                                                        : []


        let arrayForFiltering =
            gridType === 'Properties' ? defaultPropertiesGridColumns
                : gridType === 'Activities Grid' ? defaultActivitiesGridRows
                    : gridType === 'Surfaces' ? defaultSurfacesGridColumns
                        : gridType === 'Requirements' ? defaultRequirementGridColumns
                            : gridType === 'Requirements/Cold Offer' ? requirementColdOfferDefaultGridColumns
                                : gridType === 'Audit' ? defaultAuditGridColumns.filter((c: any) => c.property !== textValue)
                                    : gridType === 'Brokerage/Requirements' ? defaultBrokerageReqsGridColumns
                                        : gridType === 'AttrCodes' ? defaultAttrCodesGridColumns
                                            : gridType === 'All Properties/Upcoming' ? upcomingPropertiesDefaultGridColumns
                                                : gridType === 'All Properties/REQ' ? brokeragePropertiesDefaultGridColumns
                                                    : gridType === 'Requirements/Searched by contacts' ? defaultRequirementsSearchedByContacts
                                                        : gridType === 'SurfacesRR' ? rrSurfacesDefaultGridColumns
                                                        : []

        function convertToNumber(euroFormatStr: any) {
            if (typeof euroFormatStr === 'string') {
                const standardFormatStr = euroFormatStr.replace('.', '').replace(',', '.');
                return parseFloat(standardFormatStr)
            } else {
                return euroFormatStr
            }
        }


        if (filtersForArray.length) {
            filtersForArray.forEach((a: any) => {
                if (searchedAddress !== '' && a.property === "PROP_ADDR_STREET_ADDRESS") {
                    const value = searchedAddress.trim();
                    if (value.endsWith('%')) {
                        arrayForFiltering = arrayForFiltering
                            .filter((p: any) => p[a.property] !== null)
                            .filter((obj: any) => obj[a.property].toLowerCase().startsWith(value.slice(0, -1).toLowerCase()));
                    } else {
                        arrayForFiltering = arrayForFiltering
                            .filter((p: any) => p[a.property] !== null)
                            .filter((obj: any) => obj[a.property].toLowerCase().startsWith(value.toLowerCase()) || obj[a.property].toLowerCase().includes(value.toLowerCase()));
                    }
                }
                if (a.type === 'class java.lang.String' && a.property === "ACTIVITY_EXISTED") {
                    arrayForFiltering = arrayForFiltering
                        .filter((p: any) => p[a.property] !== null)
                        .filter((obj: any) => {
                            if (obj[a.property] !== null && (a.value === 'Yes' || a.value === 'yes')) {
                                return Number(obj[a.property]) === 1;
                            } else if (obj[a.property] !== null && (a.value === 'no' || a.value === 'No')) {
                                return Number(obj[a.property]) === 0;
                            } else {
                                return obj[a.property].toLowerCase().startsWith(a.value.toLowerCase())
                                    || obj[a.property].toLowerCase().includes(a.value.toLowerCase());
                            }
                        });
                }
                if (a.type === 'class java.lang.String' && a.property !== "ACTIVITY_EXISTED" && a.property !== "STATUS") {
                    if (a.value.trim().endsWith('%')) {
                        arrayForFiltering = arrayForFiltering.filter((p: any) => p[a.property] !== null).filter((obj: any) => obj[a.property]!.toLowerCase().startsWith(a.value!.slice(0, -1).toLowerCase()));
                    } else {
                        arrayForFiltering = arrayForFiltering.filter((p: any) => p[a.property] !== null).filter((obj: any) => obj[a.property]!.toLowerCase().startsWith(a.value!.trim().toLowerCase()) || obj[a.property]!.toLowerCase().includes(a.value!.trim().toLowerCase()));
                    }
                }
                if (a.type === 'class java.lang.String' && a.property === "STATUS") {
                    arrayForFiltering = arrayForFiltering.filter((obj: any) => {
                        const objValue = obj[a.property]?.toLowerCase() || null;
                        return objValue === null || objValue === 'active' || objValue === 'passive';
                    });
                }
                if (a.type === 'class java.math.BigDecimal') {
                    arrayForFiltering = arrayForFiltering.filter((obj: any) =>
                        obj[a.property] !== null && a.condition === 'less' ? convertToNumber(obj[a.property]) < convertToNumber(a.value)
                            : obj[a.property] !== null && a.condition === 'equals' ? convertToNumber(obj[a.property]) === convertToNumber(a.value)
                                : obj[a.property] !== null && a.condition === 'higher' ? convertToNumber(obj[a.property]) > convertToNumber(a.value)
                                    : obj[a.property] !== null && a.condition === 'between' ? convertToNumber(obj[a.property]) >= convertToNumber(a.startValue) && convertToNumber(obj[a.property]) <= convertToNumber(a.endValue) : null);
                }

                if (a.type === 'class java.time.LocalDateTime' || a.type === "class oracle.sql.TIMESTAMP") {
                    arrayForFiltering = arrayForFiltering.filter((obj: any) => {
                        const date = moment(obj[a.property]);
                        return a.dateCondition === 'onDate' ? date.isSame(a.date, "day") : a.dateCondition === 'before' ? date.isBefore(a.date) : date.isAfter(a.date);
                    });
                }
                if (gridType === 'Requirements') {
                    dispatch(onSetRequirements({requirementsRows: arrayForFiltering}))
                }
                else if (gridType === 'Requirements/Cold Offer') {
                    dispatch(onSetColdOfferRequirements({requirementsRows: arrayForFiltering}))
                }
                else if (gridType === 'Requirements/Searched by contacts') {
                    dispatch(onSetSearchedByContactsRequirements({requirementsRows: arrayForFiltering}))
                } else if (gridType === 'Activities Grid') {
                    dispatch(onSetActivitiesGridData({properties: arrayForFiltering}))
                } else if (gridType === 'Audit') {
                    dispatch(setUserActivities(arrayForFiltering))
                } else if (gridType === 'Brokerage/Requirements') {
                    dispatch(onSetBrokerageReqs(arrayForFiltering))
                } else if (gridType === 'AttrCodes') {
                    dispatch(onSetAttrCodesReqs(arrayForFiltering))
                    gridDataHelper.setAttrGridFilters(arrayForFiltering)
                } else if (gridType === 'Properties') {
                    dispatch(onSetProperties({properties: arrayForFiltering, gridType: gridType}))
                    sessionStorageGridFilters.setPropertiesGridFilters(filtersForArray)
                } else if (gridType === 'Surfaces') {
                    dispatch(onSetSurfaces({properties: arrayForFiltering, gridType: gridType}))
                    sessionStorageGridFilters.setSurfacesGridFilters(filtersForArray)
                } else if (gridType === 'All Properties/REQ') {
                    dispatch(onSetPropertiesWithReq({properties: arrayForFiltering, gridType: gridType}))
                    sessionStorageGridFilters.setPropertiesWithReqGridFilters(filtersForArray)
                } else if (gridType === 'All Properties/Upcoming') {
                    dispatch(onSetUpcomingProperties({properties: arrayForFiltering, gridType: gridType}))
                    sessionStorageGridFilters.setContactPropertiesGridFilters(filtersForArray)
                }
                else if (gridType === 'SurfacesRR') {
                    dispatch(onSetRRSurfaces({gridRows: arrayForFiltering}))
                    sessionStorageGridFilters.setRRSurfacesGridFilters(filtersForArray)
                }
            })
        } else {
            if (gridType === 'Requirements') {
                dispatch(onSetRequirements({requirementsRows: defaultRequirementGridColumns}))
            }
            else if (gridType === 'Requirements/Cold Offer') {
                dispatch(onSetColdOfferRequirements({requirementsRows: requirementColdOfferDefaultGridColumns}))
            }
            else if (gridType === 'Requirements/Searched by contacts') {
                dispatch(onSetSearchedByContactsRequirements({requirementsRows: defaultRequirementsSearchedByContacts}))
            } else if (gridType === 'Activities Grid') {
                dispatch(onSetActivitiesGridData({properties: defaultActivitiesGridRows}))
            } else if (gridType === 'Audit') {
                dispatch(setUserActivities(defaultAuditGridColumns))
            } else if (gridType === 'Brokerage/Requirements') {
                dispatch(onSetBrokerageReqs(defaultBrokerageReqsGridColumns))
            } else if (gridType === 'AttrCodes') {
                dispatch(onSetAttrCodesReqs(defaultAttrCodesGridColumns))
            } else if (gridType === 'Properties') {
                dispatch(onSetProperties({properties: defaultPropertiesGridColumns, gridType: gridType}))
            } else if (gridType === 'Surfaces') {
                dispatch(onSetSurfaces({properties: defaultSurfacesGridColumns, gridType: gridType}))
            } else if (gridType === 'All Properties/Upcoming') {
                dispatch(onSetUpcomingProperties({
                    properties: upcomingPropertiesDefaultGridColumns,
                    gridType: gridType
                }))
            } else if (gridType === 'All Properties/REQ') {
                dispatch(onSetPropertiesWithReq({
                    properties: brokeragePropertiesDefaultGridColumns,
                    gridType: gridType
                }))
            }
            else if (gridType === 'SurfacesRR') {
                dispatch(onSetRRSurfaces({gridRows: rrSurfacesDefaultGridColumns}))
            }
        }
        if (gridType === 'Requirements') {
            dispatch(onSetFiltersForRequirementsGrid({gridFilters: filtersForArray}))
            sessionStorageGridFilters.setRequirementsGridFilters(filtersForArray)
            dispatch(onSetRequirementsGridFiltersButtonsModalOpened({
                headerName: currentColumn.headerName,
                isModalOpened: false
            }))
            dispatch(onSetRequirementsGridFiltersButtonsSelected({
                headerName: currentColumn.headerName,
                isFilterSelected: false
            }))
            dispatch(onChangeRequirementsGridFilterValue({
                title: currentColumn.headerName,
                value: '',
                startValue: '',
                endValue: ''
            }))
            dispatch(onChangeRequirementsGridFilterConditionValue({title: currentColumn.headerName, value: ''}))
        }else if (gridType === 'Requirements/Cold Offer') {
            dispatch(onSetFiltersForRequirementsColdOfferGrid({gridFilters: filtersForArray}))
            sessionStorageGridFilters.setRequirementsColdOfferGridFilters(filtersForArray)
            dispatch(onSetRequirementsColdOfferGridFiltersButtonsModalOpened({
                headerName: currentColumn.headerName,
                isModalOpened: false
            }))
            dispatch(onSetColdOfferRequirementsGridFiltersButtonsSelected({
                headerName: currentColumn.headerName,
                isFilterSelected: false
            }))
            dispatch(onChangeColdOfferRequirementsGridFilterValue({
                title: currentColumn.headerName,
                value: '',
                startValue: '',
                endValue: ''
            }))
            dispatch(onChangeRequirementsColdOfferGridFilterConditionValue({
                title: currentColumn.headerName,
                value: ''
            }))
        }
        else if (gridType === 'Requirements/Searched by contacts') {
            dispatch(onSetSearchedByContactsFiltersForRequirementsGrid({gridFilters: filtersForArray}))
            sessionStorageGridFilters.setRequirementsSearchedByContactsGridFilters(filtersForArray)
            dispatch(onSetRequirementsSearchedByContactsGridFiltersButtonsModalOpened({
                headerName: currentColumn.headerName,
                isModalOpened: false
            }))
            dispatch(onSetSearchedByContactsRequirementsGridFiltersButtonsSelected({
                headerName: currentColumn.headerName,
                isFilterSelected: false
            }))
            dispatch(onChangeSearchedByContactsRequirementsGridFilterValue({
                title: currentColumn.headerName,
                value: '',
                startValue: '',
                endValue: ''
            }))
            dispatch(onChangeRequirementsSearchedByContactsGridFilterConditionValue({
                title: currentColumn.headerName,
                value: ''
            }))
        } else if (gridType === 'Activities Grid') {
            dispatch(onSetFiltersForActivitiesGrid({gridFilters: filtersForArray}))
            sessionStorageGridFilters.setActivitiesGridFilters(filtersForArray)
            dispatch(onSetActivitiesGridFiltersButtonsModalOpened({
                headerName: currentColumn.headerName,
                isModalOpened: false
            }))
            dispatch(onSetActivitiesGridFiltersButtonsSelected({
                headerName: currentColumn.headerName,
                isFilterSelected: false
            }))
            dispatch(onChangeActivitiesGridFilterValue({
                title: currentColumn.headerName,
                value: '',
                startValue: '',
                endValue: ''
            }))
            dispatch(onChangeActivitiesGridFilterConditionValue({title: currentColumn.headerName, value: ''}))
        } else if (gridType === 'Audit') {
            dispatch(onSetFiltersForAuditGrid({gridFilters: filtersForArray}))
            sessionStorageGridFilters.setAuditGridFilters(filtersForArray)
            dispatch(onSetAuditGridFiltersButtonsModalOpened({
                headerName: currentColumn.headerName,
                isModalOpened: false
            }))
            dispatch(onSetAuditGridFiltersButtonsSelected({
                headerName: currentColumn.headerName,
                isFilterSelected: false
            }))
            dispatch(onChangeAuditGridFilterValue({
                title: currentColumn.headerName,
                value: '',
                startValue: '',
                endValue: ''
            }))
            dispatch(onChangeAuditGridFilterConditionValue({title: currentColumn.headerName, value: ''}))
        } else if (gridType === 'Brokerage/Requirements') {
            dispatch(onSetFiltersForBrokerageReqsGrid({gridFilters: filtersForArray}))
            sessionStorageGridFilters.setBrokeragePropertiesGridFilters(filtersForArray)
            dispatch(onSetBrokerageReqsGridFiltersButtonsModalOpened({
                headerName: currentColumn.headerName,
                isModalOpened: false
            }))
            dispatch(onSetBrokerageReqsGridFiltersButtonsSelected({
                headerName: currentColumn.headerName,
                isFilterSelected: false
            }))
            dispatch(onChangeBrokerageReqsGridFilterValue({
                title: currentColumn.headerName,
                value: '',
                startValue: '',
                endValue: ''
            }))
            dispatch(onChangeBrokerageReqsGridFilterConditionValue({title: currentColumn.headerName, value: ''}))
        } else if (gridType === 'AttrCodes') {

            dispatch(onSetFiltersForAttrCodesGrid({gridFilters: filtersForArray}))
            gridDataHelper.setAttrGridFilters(filtersForArray)
            dispatch(onSetAttrCodesGridFiltersButtonsModalOpened({
                headerName: currentColumn.headerName,
                isModalOpened: false
            }))
            dispatch(onSetAttrCodesGridFiltersButtonsSelected({
                headerName: currentColumn.headerName,
                isFilterSelected: false
            }))
            dispatch(onChangeAttrCodesGridFilterValue({
                title: currentColumn.headerName,
                value: null,
                startValue: '',
                endValue: ''
            }))
            dispatch(onChangeAttrCodesGridFilterConditionValue({title: currentColumn.headerName, value: ''}))
        }
        else if (gridType === 'SurfacesRR') {
            dispatch(onFilterRRSurfacesGrid({gridFilters: filtersForArray, gridType: gridType}))
            sessionStorageGridFilters.setRRSurfacesGridFilters(filtersForArray)
            dispatch(onSetRRSurfacesGridFiltersButtonsModalOpened({
                headerName: currentColumn.headerName,
                isModalOpened: false
            }))
            dispatch(onSetRRSurfacesGridFiltersButtonsSelected({
                headerName: currentColumn.headerName,
                isFilterSelected: false
            }))
            dispatch(onChangeRRSurfacesGridFilterValue({
                title: currentColumn.headerName,
                value: '',
                startValue: '',
                endValue: ''
            }))
            dispatch(onChangeRRSurfacesGridFilterConditionValue({title: currentColumn.headerName, value: ''}))
        }else {
            //properties grid/all propertied grid

            dispatch(onSetFiltersButtonsModalOpened({
                headerName: currentColumn.headerName,
                isModalOpened: false,
                gridType: gridType
            }))
            dispatch(onSetFiltersButtonsSelected({
                headerName: currentColumn.headerName,
                isFilterSelected: false,
                gridType: gridType
            }))
            dispatch(onChangeColumnForFilterValue({
                title: currentColumn.headerName,
                value: '',
                gridType: gridType,
                startValue: '',
                endValue: ''
            }))
            dispatch(onChangeColumnForFilterConditionValue({
                title: currentColumn.headerName,
                value: '',
                gridType: gridType
            }))
            if (gridType === 'Properties') {
                dispatch(onFilterPropertiesGrid({gridFilters: filtersForArray, gridType: gridType}))
                sessionStorageGridFilters.setPropertiesGridFilters(filtersForArray)
            } else if (gridType === 'Surfaces') {
                dispatch(onFilterSurfacesGrid({gridFilters: filtersForArray, gridType: gridType}))
                sessionStorageGridFilters.setSurfacesGridFilters(filtersForArray)
            } else if (gridType === 'All Properties/REQ') {
                dispatch(onFilterPropertiesWithReqGrid({gridFilters: filtersForArray, gridType: gridType}))
                sessionStorageGridFilters.setPropertiesWithReqGridFilters(filtersForArray)
            } else if (gridType === 'All Properties/Upcoming') {
                dispatch(onFilterUpcomingPropertiesGrid({gridFilters: filtersForArray, gridType: gridType}))
                sessionStorageGridFilters.setContactPropertiesGridFilters(filtersForArray)
            }
            // gridDataHelper.setPropertiesGridFilters(filtersForArray)
        }
        if (gridType === 'All Properties/REQ' && currentColumn.title === 'STATUS') {
            const filterColumnsS = sessionStorageGridFilters.getPropertiesWithReqGridFilters()
            sessionStorageGridFilters.setPropertiesWithReqGridFilters(filterColumnsS.concat({
                "property": "STATUS",
                "value": "1",
                "type": "class java.lang.String",
                "condition": "1",
                "dateCondition": "onDate",
                "date": ""
            }))
            dispatch(onSetFiltersButtonsSelected({
                headerName: "STATUS",
                isFilterSelected: true,
                gridType: gridType
            }))
            dispatch(onSetBrokeragePropsCurrentStatus(null))
        }
    }
    let combinedValue
    if (currentColumn?.startValue && currentColumn?.endValue) {
        combinedValue = `${currentColumn?.startValue} - ${currentColumn?.endValue}`
    }

    return (
        <div
            // onClick={currentColumn?.isFilterSelected ? onResetFilter : onFilterClick}
            className={s.btnInner}
            // value={textValue}
            style={{
                backgroundColor: currentColumn?.isFilterSelected ? '#141618' : '#FFF',
                color: currentColumn?.isFilterSelected ? '#FFF' : '#111827',
                padding: isMobile && currentColumn?.isFilterSelected ? '12px' : isMobile && !currentColumn?.isFilterSelected ? '0' : '6px 12px',
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={onFilterClick}
        >
            {
                combinedValue
                    ?
                    <p
                        style={{
                            // minWidth: currentColumn?.isFilterSelected ? `${textWidth}px` : 'initial',
                            color: currentColumn?.isFilterSelected ? '#FFF' : '#111827',
                        }}
                    >
                        {currentColumn?.isFilterSelected && !isHovering
                            ? (currentColumn?.conditionValue === 'higher'
                                    ? `> ${combinedValue}`
                                    : currentColumn?.conditionValue === 'less'
                                        ? `< ${combinedValue}`
                                        : combinedValue
                            )
                            : currentColumn?.isFilterSelected && isHovering
                                ? buttonTitle?.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())
                                : buttonTitle?.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())
                        }

                    </p>
                    :
                    <p
                        ref={textRef}
                        style={{
                            minWidth: currentColumn?.isFilterSelected ? `${textWidth}px` : 'initial',
                            maxWidth: currentColumn?.isFilterSelected ? `400px` : 'initial',
                            overflow: currentColumn?.isFilterSelected ? 'hidden' : 'auto',
                            textOverflow: currentColumn?.isFilterSelected && !isHovering ? 'ellipsis' : 'initial',
                            whiteSpace: currentColumn?.isFilterSelected ? 'nowrap' : 'initial',
                            color: currentColumn?.isFilterSelected ? '#FFF' : '#111827',
                        }}

                    >
                        {
                            currentColumn?.dataType === 'class java.math.BigDecimal' && currentColumn?.isFilterSelected && !isHovering
                                ?
                                (
                                    currentColumn.conditionValue === 'higher'
                                        ? `> ${currentColumn.value === 'no position' ? '' : currentColumn.value}`
                                        : currentColumn.conditionValue === 'less'
                                            ? `< ${currentColumn.value}`
                                            : `= ${currentColumn.value}`
                                )
                                :
                                currentColumn?.dataType !== 'class java.math.BigDecimal' && currentColumn?.isFilterSelected && !isHovering
                                    ? currentColumn.value === 'no position' ? ''
                                        : Number(currentColumn.value) === 1 ? 'Active'
                                            : Number(currentColumn.value) === 2 ?
                                                'Sold/rented'
                                                : Number(currentColumn.value) === 3 ?
                                                    'Inactive'
                                                    : currentColumn.value === "All" ? 'All'
                                                        : currentColumn.value
                                    : buttonTitle?.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase()) === 'Domain Cd'
                                        ? 'Domain' :
                                        buttonTitle?.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())
                        }
                    </p>
            }

            {currentColumn?.isFilterSelected
                ?
                (gridType === 'All Properties/REQ' || gridType === 'All Properties/Upcoming') && currentColumn?.title === 'STATUS'
                    ?
                    null
                    :
                    <p
                        onClick={onResetFilter}
                        style={{
                            position: 'relative',
                            zIndex: '100',
                            width: '11px',
                            height: '23px',
                            color: '#fff',
                            marginBottom: 0,
                            marginTop: 0,
                            fontSize: '13px',
                            marginLeft: '5px',
                            marginRight: '0px'
                        }}>x</p>
                :
                <div onClick={onFilterClick} style={{
                    marginTop: '4px'
                }}>
                    {
                        !isMobile
                            ?
                            <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6.30026 8.3L3.70026 5.7C3.38359 5.38333 3.31292 5.021 3.48826 4.613C3.66292 4.20433 3.97526 4 4.42526 4H9.57526C10.0253 4 10.3376 4.20433 10.5123 4.613C10.6876 5.021 10.6169 5.38333 10.3003 5.7L7.70026 8.3C7.60026 8.4 7.49192 8.475 7.37526 8.525C7.25859 8.575 7.13359 8.6 7.00026 8.6C6.86692 8.6 6.74192 8.575 6.62526 8.525C6.50859 8.475 6.40026 8.4 6.30026 8.3Z"
                                    fill={'#191559'}/>
                            </svg>
                            :
                            <img src={backArrow} alt="open_filter"/>
                    }

                </div>
            }
        </div>

    );
};

export default PropertyFilterButton;