import React, {useEffect, useRef, useState} from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import s from './SurfaceDetails.module.scss'
import {utilityDataHelper} from "../../../helpers/localStorageHelper";
import {Select} from "antd";
import {propertiesApi, templatesApi} from "../../../app/api";
import {MapContainer, TileLayer, Marker, Popup} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import houseImg from "../../../img/icons/no-image.svg";
import mailImg from "../../../img/EV_BP_88x88_Mail.jpg";
import locationImg from "../../../img/EV_BP_88x88_Location.jpg";
import phoneImg from "../../../img/EV_BP_88x88_Phone.jpg";

const defaultIcon = L.icon({
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41]
});
const SurfaceDetails = () => {
    const componentRef = useRef<HTMLDivElement>(null);
    const surfaceData = utilityDataHelper.getRRSurfaceTemplateData().resultSetList[0];
    const surfaceImages: any[] = utilityDataHelper.getRRSurfaceTemplateImages().property_images;
    const templateDataForRequest = utilityDataHelper.getRRSurfaceTemplateDataForRequest()
    const [language, setLanguage] = useState<string>(templateDataForRequest.lang);
    const mapRef = useRef<any>(null);
    const [mapImage, setMapImage] = useState<string | null>(null);
    const [isCapturing, setIsCapturing] = useState(false);

    const handleChange = (value: string) => {
        setLanguage(value)
        onGetLinkForTemplate(value)
    };

    // Capture Map Image Once It's Loaded
    useEffect(() => {

            setTimeout(async () => {
                const mapContainer = mapRef.current.getContainer();
                const canvas = await html2canvas(mapContainer, {
                    useCORS: true,
                    backgroundColor: null,
                });
                setMapImage(canvas.toDataURL("image/png"));
            }, 1000);

    }, []);


    const handlePrint = () => {
        window.print();
    };

    const handleOpenPDF = async () => {
        if (!componentRef.current) return;
        setIsCapturing(true);

        await new Promise((resolve) => setTimeout(resolve, 500)); // Allow UI to update

        const pdf = new jsPDF("p", "mm", "a4", true);
        const margin = 10;
        const pageWidth = 210 - margin * 2;
        const pageHeight = 310 - margin * 2;

        // ✅ Capture main content
        const canvas = await html2canvas(componentRef.current, {
            useCORS: true,
            backgroundColor: '#FFF',
            scale: 1,
        });

        const imgData = canvas.toDataURL("image/jpeg", 0.7);
        const imgWidth = pageWidth;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        let yPos = 0;

        while (yPos < imgHeight) {
            pdf.addImage(imgData, "JPEG", margin, margin - yPos, imgWidth, imgHeight);
            yPos += pageHeight;

            if (yPos < imgHeight) pdf.addPage();
        }

        setIsCapturing(false);

        const pdfBlob = pdf.output("blob");
        const pdfURL = URL.createObjectURL(pdfBlob);
        window.open(pdfURL, "_blank");
    };


    useEffect(() => {
        document.title = surfaceData.BOOKMARK_NAME;
    }, [surfaceData]);

    const onGetLinkForTemplate = async (language: string) => {
        try {
            const [templateDataRes, imagesRes] = await Promise.all([
                templatesApi.getRRSurfacesTemplateData({...templateDataForRequest, lang: language}),
                propertiesApi.getRRSurfacesTemplateImages(templateDataForRequest.row_surf)
            ]);
            utilityDataHelper.setRRSurfaceTemplateData(templateDataRes.data)
            utilityDataHelper.setRRSurfaceTemplateImages(imagesRes.data)
            utilityDataHelper.setRRSurfaceTemplateDataForRequest({...templateDataForRequest, lang: language})
            window.location.reload()
        } catch (error) {
            console.error("Error fetching template data:", error);
        }
    };
    const items = surfaceData.FULL_DESCRIPTION !== null ? surfaceData!?.FULL_DESCRIPTION!?.split("\r\n") : [];
    const chunkSize = 7;

    // Split array into chunks of max 7 items per list
    const groupedItems = [];
    for (let i = 0; i < items.length; i += chunkSize) {
        groupedItems.push(items.slice(i, i + chunkSize));
    }

    return (
        <div className={s.inner} ref={componentRef}>
            <div className={s.header}>
                <h1 style={{
                    fontFamily: surfaceData.COMPANY_ABBR_FONT,
                    fontSize: surfaceData.COMPANY_ABBR_SIZE.replace("font-size:", "").trim(),
                    color: surfaceData.COMPANY_ABBR_COLOR,
                    fontWeight: 400
                }}>
                    {surfaceData.COMPANY_ABBR}
                </h1>
                <div className={s.header__logo}>
                    <img src={`data:image/png;base64,${surfaceData.COMPANY_LOGO}`} alt="Company Logo"/>
                    {/* Print & PDF Buttons */}
                    {
                        !isCapturing
                        &&
                        <div className={s.actions}>
                            <Select
                                value={language}
                                onChange={handleChange}
                                style={{width: 150}}
                                options={[
                                    {value: "EN", label: "English"},
                                    {value: "CZ", label: "Čeština"}
                                ]}
                            />
                            {/*<button onClick={handlePrint}>*/}
                            {/*    <svg style={{*/}
                            {/*        width: '28px',*/}
                            {/*        height: '28px'*/}
                            {/*    }} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="print"*/}
                            {/*         className="svg-inline--fa fa-print fa-w-16 " role="img"*/}
                            {/*         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">*/}
                            {/*        <path fill="grey"*/}
                            {/*              d="M448 192V77.25c0-8.49-3.37-16.62-9.37-22.63L393.37 9.37c-6-6-14.14-9.37-22.63-9.37H96C78.33 0 64 14.33 64 32v160c-35.35 0-64 28.65-64 64v112c0 8.84 7.16 16 16 16h48v96c0 17.67 14.33 32 32 32h320c17.67 0 32-14.33 32-32v-96h48c8.84 0 16-7.16 16-16V256c0-35.35-28.65-64-64-64zm-64 256H128v-96h256v96zm0-224H128V64h192v48c0 8.84 7.16 16 16 16h48v96zm48 72c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"></path>*/}
                            {/*    </svg>*/}
                            {/*</button>*/}
                            {/*<button onClick={handleOpenPDF}>*/}
                            {/*    <svg style={{*/}
                            {/*        width: '28px',*/}
                            {/*        height: '28px'*/}
                            {/*    }} aria-labelledby="svg-inline--fa-title-tuMOwHeZ6IBU" data-prefix="fas"*/}
                            {/*         data-icon="file-pdf" className="svg-inline--fa fa-file-pdf fa-w-12 ml-2" role="img"*/}
                            {/*         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><title*/}
                            {/*        id="svg-inline--fa-title-tuMOwHeZ6IBU">Pdf is ready to download</title>*/}
                            {/*        <path fill="#00aced"*/}
                            {/*              d="M181.9 256.1c-5-16-4.9-46.9-2-46.9 8.4 0 7.6 36.9 2 46.9zm-1.7 47.2c-7.7 20.2-17.3 43.3-28.4 62.7 18.3-7 39-17.2 62.9-21.9-12.7-9.6-24.9-23.4-34.5-40.8zM86.1 428.1c0 .8 13.2-5.4 34.9-40.2-6.7 6.3-29.1 24.5-34.9 40.2zM248 160h136v328c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V24C0 10.7 10.7 0 24 0h200v136c0 13.2 10.8 24 24 24zm-8 171.8c-20-12.2-33.3-29-42.7-53.8 4.5-18.5 11.6-46.6 6.2-64.2-4.7-29.4-42.4-26.5-47.8-6.8-5 18.3-.4 44.1 8.1 77-11.6 27.6-28.7 64.6-40.8 85.8-.1 0-.1.1-.2.1-27.1 13.9-73.6 44.5-54.5 68 5.6 6.9 16 10 21.5 10 17.9 0 35.7-18 61.1-61.8 25.8-8.5 54.1-19.1 79-23.2 21.7 11.8 47.1 19.5 64 19.5 29.2 0 31.2-32 19.7-43.4-13.9-13.6-54.3-9.7-73.6-7.2zM377 105L279 7c-4.5-4.5-10.6-7-17-7h-6v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-74.1 255.3c4.1-2.7-2.5-11.9-42.8-9 37.1 15.8 42.8 9 42.8 9z"></path>*/}
                            {/*    </svg>*/}
                            {/*</button>*/}
                        </div>
                    }

                </div>
            </div>
            <div style={{
                marginTop: '80px',
                border: "1px solid #000",
                marginBottom: '80px',
            }}>
                {
                    surfaceImages[0]!?.raw_data && surfaceImages[0]!?.raw_data !== null
                        ?
                        <img src={`data:image/png;base64,${surfaceImages[0]!?.raw_data}`} alt="Company Logo"
                             style={{width: '100%', maxHeight: '750px'}}/>
                        :
                        <img
                            src={houseImg}
                            alt="propertyLogo"
                            style={{
                                width: '100%',
                                height: '750px',
                                objectFit: 'contain',
                            }}
                        />
                }
            </div>
            <div style={{
                borderTop: '1px solid rgb(237, 28, 36)',
            }}>
                <div>
                    <p style={{
                        fontFamily: surfaceData.PROP_ADDRESS_FONT,
                        fontSize: surfaceData.PROP_ADDRESS_SIZE.replace("font-size:", "").trim(),
                        color: surfaceData.PROP_ADDRESS_COLOR,
                        fontWeight: 400,
                        marginTop: '20px'
                    }}>
                        {surfaceData.PROP_ADDRESS}
                    </p>
                </div>
                <div>
                    <p className={s.mdText} style={{
                        fontFamily: surfaceData.CONTACT_DESCRIPTION_FONT,
                        fontSize: surfaceData.CONTACT_DESCRIPTION_SIZE.replace("font-size:", "").trim(),
                        color: surfaceData.CONTACT_DESCRIPTION_COLOR,
                        marginBottom: '42px',
                        textAlign: 'start'
                    }}>
                        {surfaceData.CONTACT_DESCRIPTION}
                    </p>
                </div>
                <div className={s.links}>
                    {
                        surfaceData.EMAIL !== null
                        &&
                        <div className={s.links__link}>
                            <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="4.33203" y="4" width="48" height="48" rx="24" fill="#F2F4F7"/>
                                <rect x="4.33203" y="4" width="48" height="48" rx="24" stroke="#F9FAFB" strokeWidth="8"/>
                            </svg>
                            <img
                                src={mailImg}
                                alt="mail"
                                style={{
                                    width: '24px',
                                    height: '24px',
                                    position: 'absolute',
                                    top: '15px',
                                    mixBlendMode: 'darken'
                                }}
                            />
                            <p style={{
                                fontFamily: surfaceData.CONTACT_TITLE_FONT,
                                fontSize: surfaceData.CONTACT_TITLE_SIZE.replace("font-size:", "").trim(),
                                color: surfaceData.CONTACT_TITLE_COLOR
                            }}>{surfaceData.EMAIL_TITLE}</p>
                            <a href={`mailto:${surfaceData.EMAIL}`} style={{
                                fontFamily: surfaceData.CONTACT_SIZE,
                                fontSize: surfaceData.CONTACT_FONT.replace("font-size:", "").trim(),
                                color: surfaceData.CONTACT_COLOR
                            }}>{surfaceData.EMAIL}</a>
                        </div>
                    }
                    <div className={s.links__link}>
                        <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="4.33203" y="4" width="48" height="48" rx="24" fill="#F2F4F7"/>
                            <rect x="4.33203" y="4" width="48" height="48" rx="24" stroke="#F9FAFB" strokeWidth="8"/>
                        </svg>
                        <img
                            src={locationImg}
                            alt="location"
                            style={{
                                width: '24px',
                                height: '24px',
                                position: 'absolute',
                                top: '15px',
                                mixBlendMode: 'darken'
                            }}
                        />
                        <p style={{
                            fontFamily: surfaceData.CONTACT_TITLE_FONT,
                            fontSize: surfaceData.CONTACT_TITLE_SIZE.replace("font-size:", "").trim(),
                            color: surfaceData.CONTACT_TITLE_COLOR
                        }}>{surfaceData.OFFICE_TITLE}</p>
                        <a href="https://www.google.com/maps/search/?api=1&query=Vancouverstra%C3%9Fe+2a,+20457+Hamburg"
                           style={{
                               fontFamily: surfaceData.CONTACT_SIZE,
                               fontSize: surfaceData.CONTACT_FONT.replace("font-size:", "").trim(),
                               color: surfaceData.CONTACT_COLOR
                           }}>{surfaceData.OFFICE}</a>
                    </div>
                    <div className={s.links__link}>
                        <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="4.33203" y="4" width="48" height="48" rx="24" fill="#F2F4F7"/>
                            <rect x="4.33203" y="4" width="48" height="48" rx="24" stroke="#F9FAFB" strokeWidth="8"/>
                        </svg>
                        <img
                            src={phoneImg}
                            alt="phone"
                            style={{
                                width: '24px',
                                height: '24px',
                                position: 'absolute',
                                top: '15px',
                                mixBlendMode: 'darken'
                            }}
                        />
                        <p style={{
                            fontFamily: surfaceData.CONTACT_TITLE_FONT,
                            fontSize: surfaceData.CONTACT_TITLE_SIZE.replace("font-size:", "").trim(),
                            color: surfaceData.CONTACT_TITLE_COLOR
                        }}>{surfaceData.PHONE_TITLE}</p>
                        <a href="tel:+4940361310" style={{
                            fontFamily: surfaceData.CONTACT_SIZE,
                            fontSize: surfaceData.CONTACT_FONT.replace("font-size:", "").trim(),
                            color: surfaceData.CONTACT_COLOR
                        }}>{surfaceData.PHONE}</a>
                    </div>
                </div>
                <div>
                    {
                        surfaceData.FLOOR_AREA !== null
                        &&
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>

                            <p style={{
                                fontFamily: surfaceData.PROP_ADDRESS_FONT,
                                fontSize: surfaceData.PROP_ADDRESS_SIZE.replace("font-size:", "").trim(),
                                color: '#000',
                                fontWeight: 400,
                                marginBottom: '10px',
                                marginTop: 0
                            }}>
                                {surfaceData.FLOOR_AREA_TITLE}
                            </p>
                            <p style={{
                                fontFamily: surfaceData.PROP_ADDRESS_FONT,
                                fontSize: surfaceData.PROP_ADDRESS_SIZE.replace("font-size:", "").trim(),
                                color: '#000',
                                fontWeight: 400,
                                marginBottom: '10px',
                                marginTop: 0
                            }}>
                                {surfaceData.FLOOR_AREA}
                            </p>
                        </div>
                    }
                    {
                        surfaceData.LAYOUT !== null
                        &&
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <p style={{
                                fontFamily: surfaceData.PROP_ADDRESS_FONT,
                                fontSize: surfaceData.PROP_ADDRESS_SIZE.replace("font-size:", "").trim(),
                                color: '#000',
                                fontWeight: 400,
                                marginBottom: '10px',
                                marginTop: 0
                            }}>
                                {surfaceData.LAYOUT_TITLE}
                            </p>
                            <p style={{
                                fontFamily: surfaceData.PROP_ADDRESS_FONT,
                                fontSize: surfaceData.PROP_ADDRESS_SIZE.replace("font-size:", "").trim(),
                                color: '#000',
                                fontWeight: 400,
                                marginBottom: '10px',
                                marginTop: 0
                            }}>
                                {surfaceData.LAYOUT}
                            </p>
                        </div>
                    }
                    {
                        surfaceData.NET_LEASE !== null
                        &&
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <p style={{
                                fontFamily: surfaceData.PROP_ADDRESS_FONT,
                                fontSize: surfaceData.PROP_ADDRESS_SIZE.replace("font-size:", "").trim(),
                                color: '#000',
                                fontWeight: 400,
                                marginBottom: '10px',
                                marginTop: 0
                            }}>
                                {surfaceData.NET_LEASE_TITLE}
                            </p>
                            <p style={{
                                fontFamily: surfaceData.PROP_ADDRESS_FONT,
                                fontSize: surfaceData.PROP_ADDRESS_SIZE.replace("font-size:", "").trim(),
                                color: '#000',
                                fontWeight: 400,
                                marginBottom: '10px',
                                marginTop: 0
                            }}>
                                {surfaceData.NET_LEASE}
                            </p>
                        </div>
                    }
                    {
                        surfaceData.UTILITY !== null
                        &&
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <p style={{
                                fontFamily: surfaceData.PROP_ADDRESS_FONT,
                                fontSize: surfaceData.PROP_ADDRESS_SIZE.replace("font-size:", "").trim(),
                                color: '#000',
                                fontWeight: 400,
                                marginBottom: '10px',
                                marginTop: 0
                            }}>
                                {surfaceData.UTILITY_TITLE}
                            </p>
                            <p style={{
                                fontFamily: surfaceData.PROP_ADDRESS_FONT,
                                fontSize: surfaceData.PROP_ADDRESS_SIZE.replace("font-size:", "").trim(),
                                color: '#000',
                                fontWeight: 400,
                                marginBottom: '10px',
                                marginTop: 0
                            }}>
                                {surfaceData.UTILITY}
                            </p>
                        </div>
                    }

                </div>
                {/* ✅ Map Container: Real map replaces with image when capturing */}
                    <div
                        style={{
                            border: "1px solid #000",
                            marginTop: '35px'
                        }}
                    >
                        {!isCapturing && (
                            <MapContainer
                                center={surfaceData.PROP_ADDR_LATI !== null && surfaceData.PROP_ADDR_LONGI !== null ? [surfaceData.PROP_ADDR_LATI, surfaceData.PROP_ADDR_LONGI] : [50.0875, 14.4214]}
                                zoom={13}
                                style={{height: "450px", width: "100%"}}
                                minZoom={11}
                                ref={mapRef}
                            >
                                <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                />
                                <Marker
                                    position={surfaceData.PROP_ADDR_LATI !== null && surfaceData.PROP_ADDR_LONGI !== null ? [surfaceData.PROP_ADDR_LATI, surfaceData.PROP_ADDR_LONGI] : [50.0875, 14.4214]}
                                    icon={defaultIcon}
                                />
                            </MapContainer>
                        )}

                        {isCapturing && mapImage && (
                            <img
                                src={mapImage}
                                alt="Captured Map"
                                style={{width: "100%", height: "450px"}}
                            />
                        )}
                    </div>
                {
                    surfaceData.FULL_DESCRIPTION !== null
                    &&
                    <div>
                        <p style={{
                            fontFamily: surfaceData.DESCRIPTION_FONT,
                            fontSize: surfaceData.DESCRIPTION_SIZE.replace("font-size:", "").trim(),
                            color: surfaceData.DESCRIPTION_COLOR,
                            margin: '35px 0 0 0',
                            fontWeight: 400,
                        }}>Surface features</p>
                        <div style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            fontFamily: surfaceData.FULL_DESCRIPTION_FONT,
                            fontSize: surfaceData.FULL_DESCRIPTION_SIZE.replace("font-size:", "").trim(),
                            color: surfaceData.FULL_DESCRIPTION_COLOR,
                            fontWeight: 400,
                            justifyContent: "space-between",
                            padding: '0 20px'
                        }}>
                            {groupedItems.map((group, index) => (
                                <div key={index}>
                                    <ul style={{
                                        padding: '0'
                                    }}>
                                        {group.map((item: string, idx: number) => (
                                            <li key={idx}>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                }

                <div style={{
                    marginTop: '35px',
                    border: "1px solid #000",
                }}>
                    {
                        surfaceImages[1]!?.raw_data && surfaceImages[1]!?.raw_data !== null
                            ?
                            <img src={`data:image/png;base64,${surfaceImages[1]!?.raw_data}`} alt="Company Logo"
                                 style={{width: '100%', maxHeight: '750px'}}/>
                            :
                            <img
                                src={houseImg}
                                alt="propertyLogo"
                                style={{
                                    width: '100%',
                                    height: '750px',
                                    objectFit: 'contain',
                                }}
                            />
                    }
                </div>
                <div style={{
                    marginTop: '35px',
                    border: "1px solid #000",
                }}>
                    {
                        surfaceImages[2]!?.raw_data && surfaceImages[2]!?.raw_data !== null
                            ?
                            <img src={`data:image/png;base64,${surfaceImages[2]!?.raw_data}`} alt="Company Logo"
                                 style={{width: '100%', maxHeight: '750px'}}/>
                            :
                            <img
                                src={houseImg}
                                alt="propertyLogo"
                                style={{
                                    width: '100%',
                                    height: '750px',
                                    objectFit: 'contain',
                                }}
                            />
                    }
                </div>
                <div style={{
                    marginTop: '35px'
                }}>
                    {
                        language === 'EN'
                            ?
                            <div style={{
                                fontSize: '14px',
                                fontFamily: 'Arial, sans-serif',
                                lineHeight: '16px'
                            }}>ENGEL & VÖLKERS COMMERCIAL PRAGUE / Česká EuV Commercial s.r.o. is offering the above
                                described property/company for rent as a broker („broker“) and has therefore closed a
                                broker agreement with the owner of the property/company („owner“) . The broker is
                                authorized to offer his services also to the potential leaseholder of the
                                property/company and also for Owner of the property/company and accordingly he is
                                entitled to a commission from both parties. The broker is not responsible for the
                                correctness and completeness of the information described in this exposé relating to the
                                property/company. The results of the activities of the broker in relation to the renting
                                of the above mentioned property/company and the information presented in this exposé are
                                exclusively addressed to the owner and to single potential buyers and are confidential.
                                It is not allowed to pass this information over to a third party without agreement of
                                the broker. The broker is entitled by contract to receive a fee for his services. The
                                fee is already deserved by offering the possibility to close a lease contract for the
                                property/company between the owner and a possible leaseholder. Brokerage contract
                                between the lease candidate and Engel & Völkers is formed through a written agreement or
                                by using our services on the basis of this exposé. The commission in the amount of the
                                one month rent + VAT will be charged to the tenant upon signing a rental agreement and
                                should be paid to the Engel & Völkers account based on the issued invoice. The right of
                                the broker to receive a fee is not touched/reduced by the fact, that the lease contract
                                or a similar contract between the owner and a possible leaseholder will be closed
                                without further participation or knowledge of the broker. If the property/company should
                                already be known to the possible leaseholder, then he is bound to inform the broker
                                immediately about it, which means latest within 3 days. If this doesn’t happen, then the
                                possible leaseholder will be taken as a leaseholder found by the broker.</div>
                            :
                            <div style={{
                                fontSize: '14px',
                                fontFamily: 'Arial, sans-serif',
                                lineHeight: '16px'
                            }}>
                                ENGEL & VÖLKERS COMMERCIAL PRAGUE / Česká EuV Commercial s.r.o. působí jako
                                zprostředkovatel („Zprostředkovatel“) prodeje/pronájmu výše popsané
                                nemovitosti/společnosti na základě zprostředkovatelské smlouvy uzavřené s vlastníkem
                                („Vlastníkem“) nemovitosti/společnosti. Zprostředkovatel je oprávněn poskytovat své
                                služby vedle Vlastníka současně i zájemci o pronájem nemovitosti/společnosti a v souladu
                                s tím mu vzniká nárok na provizi od obou stran. Zprostředkovatel neručí za pravdivost
                                ani úplnost výše uvedených údajů týkající se nemovitosti/společnosti. Výsledky činnosti
                                Zprostředkovatele v souvislosti s pronájmem shora uvedené nemovitosti/společnosti a
                                informace z tohoto exposé jsou určeny výhradně pro Vlastníka nemovitosti/společnosti a
                                jednotlivé zájemce a jsou důvěrné. Jejich sdělení třetí osobě není možné bez předchozího
                                souhlasu Zprostředkovatele. Zprostředkovateli náleží za jeho činnost pro zájemce smluvně
                                dohodnutá provize Zprostředkovatelská smlouva mezi zájemcem o nájem a Zprostředkovatelem
                                vzniká skrz písemnou dohodu nebo konkludentně využitím služeb Zprostředkovatele na
                                základě tohoto exposé nemovitosti. Nárok na provizi vzniká již zprostředkováním možnosti
                                uzavřít příslušnou nájemní smlouvu mezi Vlastníkem nemovitosti/společnosti a zájemcem a
                                to pouze za předpokladu, že je obchod realizován. Provize od nájemce ve výši jednoho
                                měsíčního nájemného + DPH je zasloužená v okamžiku uzavření nájemní smlouvy a je nutno
                                ji uhradit na účet Zprostředkovatele oproti vystavení faktury do deseti dnů od podpisu
                                nájemní smlouvy. Nárok na provizi není dotčen tím, že k uzavření příslušné smlouvy mezi
                                Vlastníkem nemovitosti/společnosti a ze strany Zprostředkovatele doporučeným zájemcem
                                dojde bez další součinnosti Zprostředkovatele, popř. bez jeho vědomí. Pokud je zájemci
                                nemovitost/společnost již známa, je povinen Zprostředkovateli tuto skutečnost
                                neprodleně, tj. nejpozději do 3 dnů, sdělit. V opačném případě se zájemce považuje za
                                zájemce zprostředkovaného Zprostředkovatelem.
                            </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default SurfaceDetails;



