import React from 'react';
import s from "../../EditProperty.module.scss";
import {Checkbox, Input} from "antd";
import {
    BrokeragePropertyPicturesTabData,
    onSetDefaultPropertyPictureFilteredFields,
    onSetPropertyPictureFilteredFields, onSetReadyToDeletePropertyPictureFilteredFields,
    UpdatePropertyPictureIndex, UpdatePropertyPictureRankOrder
} from '../../../../../store/propertiesReducer';
import {useAppDispatch} from "../../../../../app/hooks";
import SecondaryButton from "../../../../common/Buttons/SecondaryButton/SecondaryButton";
import {onSetDefaultSurfacePictureFilteredFields, onSetSurfacePictureFilteredFields } from '../../../../../store/surfacesReducer';

type ShowMorePropsType = {
    onFormInputChange: (id: number, e: React.ChangeEvent<HTMLInputElement> | boolean, inputName: string, type: 'Edit' | 'Add') => void
    formFields: BrokeragePropertyPicturesTabData[]
    i: BrokeragePropertyPicturesTabData
    propertyPictureType: string
    sortMode: string
    pictureMode: 'Surfaces' | 'Properties'
}

const ShowMore = ({onFormInputChange, i, formFields, propertyPictureType, sortMode, pictureMode}: ShowMorePropsType) => {
    const dispatch = useAppDispatch()


    const onChangePictureIndex = (direction: string) => {
        // Find the index of the item with the matching id
        const currentIndex = formFields.findIndex(item => item.id === i.id);

        if (currentIndex === -1) {
            console.log("Item not found");
            return;
        }

        let targetIndex;

        // Determine the index of the item before or after
        if (direction === 'before') {
            targetIndex = currentIndex - 1;
        } else if (direction === 'after') {
            targetIndex = currentIndex + 1;
        } else {
            console.log("Invalid direction");
            return;
        }

        // Ensure the target index is within bounds
        if (targetIndex < 0 || targetIndex >= formFields.length) {
            console.log("No item in the specified direction");
            return;
        }

        const targetItem = formFields[targetIndex];
        const currentItem = formFields[currentIndex];
        const updatedFormFields = formFields.map(item => {
            if (item.id === targetItem.id) {
                return { ...item, index: currentItem.index };
            } else if (item.id === currentItem.id) {
                return { ...item, index: targetItem.index };
            }
            return item;
        })
        dispatch(UpdatePropertyPictureIndex({id: i.id, reqData: {index_old: i.index, index_new: targetItem.index}}))
            .then(() => {
                if(pictureMode === 'Surfaces'){
                    dispatch(onSetSurfacePictureFilteredFields(updatedFormFields.sort((a, b) => a.index - b.index)))
                }
                else{
                    dispatch(onSetPropertyPictureFilteredFields(updatedFormFields.sort((a, b) => a.index - b.index)))
                }

            })
    };
    function sortByTypeTextAndAssignExportOrder(arr: any[]) {
        // Step 1: Set for_portal = 1 for all objects
        const updatedArray = arr.map(item => ({ ...item, for_portal: 1 }));

        // Step 2: Sort by type_text alphabetically
        const sortedByTypeText = updatedArray.sort((a, b) => a.type_text.localeCompare(b.type_text));

        // Step 3: Sort within each type_text group by export_order (keeping non-null and non-zero values first)
        sortedByTypeText.sort((a, b) => {
            if (a.type_text !== b.type_text) {
                return a.type_text.localeCompare(b.type_text); // Sort by type_text first
            }
            if (a.export_order && b.export_order) {
                return a.export_order - b.export_order; // Sort by export_order if both are non-null and non-zero
            }
            if (a.export_order) return -1; // a comes first if it has a valid export_order
            if (b.export_order) return 1; // b comes first if it has a valid export_order
            return a.index - b.index; // Sort by index if both have null or 0 export_order
        });

        // Step 4: Assign export_order to items where export_order is null or 0
        let maxExportOrder = Math.max(
            ...sortedByTypeText.map(item => (item.export_order && item.export_order > 0) ? item.export_order : 0),
            0
        );

        return sortedByTypeText.map(item => ({
            ...item,
            export_order: (item.export_order && item.export_order > 0) ? item.export_order : ++maxExportOrder, // Assign new export_order if null or 0
        })).sort((a, b) => a.export_order - b.export_order);
    }
    const onChangePictureRankOrder = (direction: string) => {
        // Find the index of the item with the matching id
        const currentIndex = formFields.findIndex(item => item.id === i.id);

        if (currentIndex === -1) {
            console.log("Item not found");
            return;
        }

        let targetIndex;

        // Determine the index of the item before or after
        if (direction === 'before') {
            targetIndex = currentIndex - 1;
        } else if (direction === 'after') {
            targetIndex = currentIndex + 1;
        } else {
            console.log("Invalid direction");
            return;
        }

        // Ensure the target index is within bounds
        if (targetIndex < 0 || targetIndex >= formFields.length) {
            console.log("No item in the specified direction");
            return;
        }

        const targetItem = formFields[targetIndex];
        const currentItem = formFields[currentIndex];
        const updatedFormFields = formFields.map(item => {
            if (item.id === targetItem.id) {
                return { ...item, export_order: currentItem.export_order };
            } else if (item.id === currentItem.id) {
                return { ...item, export_order: targetItem.export_order };
            }
            return item;
        })
        dispatch(UpdatePropertyPictureRankOrder({id: i.id, reqData: {export_order_old: Number(currentItem.export_order!!), export_order_new: Number(targetItem.export_order!!)}}))
            .then(() => {
                if(pictureMode === 'Surfaces'){
                    // @ts-ignore
                    dispatch(onSetSurfacePictureFilteredFields(sortByTypeTextAndAssignExportOrder(updatedFormFields)))
                    dispatch(onSetDefaultSurfacePictureFilteredFields(sortByTypeTextAndAssignExportOrder(updatedFormFields)))
                }
                else{
                    // @ts-ignore
                    dispatch(onSetPropertyPictureFilteredFields(sortByTypeTextAndAssignExportOrder(updatedFormFields)))
                    dispatch(onSetDefaultPropertyPictureFilteredFields(sortByTypeTextAndAssignExportOrder(updatedFormFields)))
                }
            })
    };
    return (
        <>
            <div className={s.additionalParams}>
                <p>Additional parameters</p>
                <div className={s.additionalParams__checkboxes}>
                    <div style={{
                        display: 'flex',
                        gap: '24px'
                    }}>
                        <div style={{
                            display: 'flex',
                            gap: '6px',
                            alignItems: 'center'
                        }} className={s.form__checkbox}>
                            <Checkbox
                                onChange={(e) => onFormInputChange(i.id, e.target.checked, 'for_expose', 'Edit')}
                                checked={i.for_expose === 1}/>
                            <label className={s.form__label}> Web
                                Exposé</label>
                        </div>
                        <div style={{
                            display: 'flex',
                            gap: '6px',
                            alignItems: 'center'
                        }} className={s.form__checkbox}>
                            <label className={s.form__label}>Index: {i.index}</label>
                        </div>
                        <div style={{
                            display: 'flex',
                            gap: '6px',
                            alignItems: 'center'
                        }} className={s.form__checkbox}>
                            <Checkbox
                                onChange={(e) => onFormInputChange(i.id, e.target.checked, 'for_portal', 'Edit')}
                                checked={i.for_portal === 1}/>
                            <label className={s.form__label}>Web
                                Portal, Rank order</label>
                            <Input value={i.export_order!!} disabled={i.for_portal === 0 || sortMode === 'All Items'} min={1} onChange={(e) => onFormInputChange(i.id, e, 'export_order', 'Edit')} type={'number'} style={{
                                width: '90px',
                                height: '24px',
                                padding: '0 0 0 8px',
                                marginLeft: '5px'
                            }}/>
                        </div>
                    </div>
                    {
                        propertyPictureType !== 'All'
                        &&
                        <div style={{
                            display: 'flex',
                            gap: '10px'
                        }}>
                            <div onClick={() => onChangePictureIndex('before')}>
                                <SecondaryButton text={'Up'} width={'30px'} height={'25px'} isWhiteBg={true} disabled={i.index === 1}/>
                            </div>
                            <div onClick={() => onChangePictureIndex('after')}>
                                <SecondaryButton text={'Down'} width={'30px'} height={'25px'} isWhiteBg={true}/>
                            </div>
                        </div>
                    }
                    {
                        sortMode === 'All Items'
                        &&
                        <div style={{
                            display: 'flex',
                            gap: '10px'
                        }}>
                            <div onClick={() => onChangePictureRankOrder('before')}>
                                <SecondaryButton text={'Up'} width={'30px'} height={'25px'} isWhiteBg={true} disabled={i.export_order === 1}/>
                            </div>
                            <div onClick={() => onChangePictureRankOrder('after')}>
                                <SecondaryButton text={'Down'} width={'30px'} height={'25px'} isWhiteBg={true}/>
                            </div>
                        </div>
                    }
                </div>
            </div>

        </>
    );
};

export default ShowMore;