import React from 'react';
import s from './CreationModal.module.scss'
import SecondaryButton from "../Buttons/SecondaryButton/SecondaryButton";
import PrimaryButton from "../Buttons/PrimaryButton/PrimaryButton";
import {useAppSelector} from "../../../app/hooks";
import {selectCurrentScreenWidth} from "../../../store/utilityReducer";
import Tooltip from "../../Tooltip/Tooltip";

interface CreationModalPropsType {
    image: any
    title: string
    children: any
    onSave: () => void
    onCancel: () => void
    onCreate?: () => void
    loading: boolean
    withMargin?: boolean
    customButtons?: boolean
    width?: string
    isSearchBtn?: boolean
    disabledBtn?: boolean
    buttonOkText: string
    buttonCancelText: string
    createButton?: boolean
    disabledSearchBtn?: boolean
    isHiddenBtn?: boolean
    withTooltip?: boolean
    topAndLeftFreePosition?: boolean
    currentGrid?: "Requirements" | "Requirements/Cold Offer" | "Map"
    primaryBtnWidth?: string
}


const CreationModal = ({
                           currentGrid,
                           isHiddenBtn,
                           withTooltip,
                           disabledSearchBtn,
                           onCreate,
                           image,
                           title,
                           children,
                           onCancel,
                           onSave,
                           loading,
                           withMargin,
                           createButton,
                           width,
                           disabledBtn,
                           buttonCancelText,
                           buttonOkText,
                           topAndLeftFreePosition,
                           primaryBtnWidth
                       }: CreationModalPropsType) => {
    const currentScreenWidth = useAppSelector(selectCurrentScreenWidth)
    return (
        <div className={topAndLeftFreePosition ? `${s.modal} ${s.modal_freePos}` : s.modal}>
            <div className={`${s.modal__inner} updateContactAndDomain`} style={{
                width: '100%',
                maxWidth: width ? width : '435px',
            }}>
                <div className={s.modal__header}>
                    {image}
                    <h1 style={{
                        margin: withMargin ? '20px 0' : '0 0 15px 0'
                    }}>{title}</h1>
                </div>
                {children}
                <div style={{
                    display: 'flex',
                    width: '100%',
                    gap: '12px',
                    marginTop: '16px',
                    justifyContent: 'space-between'
                }}
                     className={s.buttons}
                >
                    {
                        !isHiddenBtn
                        &&
                        <div onClick={onCancel} onDoubleClick={() => {
                        }}>
                            <SecondaryButton
                                text={buttonCancelText}
                                width={currentScreenWidth!! > 480 ? primaryBtnWidth ? primaryBtnWidth : '189px' : '100%'}
                                height={'44px'}
                                isWhiteBg={true}
                                fontSize={'14px'}
                            />
                        </div>
                    }
                    {
                        createButton && withTooltip
                        &&
                        <div onClick={onCreate} onDoubleClick={() => {
                        }}>
                            <Tooltip text={currentGrid === 'Requirements'
                                ?
                                "Create new contact, organization and requirement for them from scratch. Try to find your contact or organization at first."
                                :
                                currentGrid === 'Map'
                                    ?
                                    "Create new contact and organization for your owner. Try to find your contact or organization at first."
                                    :
                                    "Create new contact and organization and edit requirement for them. Try to find your contact or organization at first."
                            }
                                     classname={'addressButtonsTooltip '}>
                                <SecondaryButton
                                    text={currentGrid === 'Requirements' || currentGrid === 'Requirements/Cold Offer' ? 'Create CON/ORG' : 'Create owner'}
                                    width={currentScreenWidth!! > 480 ? primaryBtnWidth ? primaryBtnWidth : '189px' : '100%'}
                                    height={'44px'}
                                    isWhiteBg={false}
                                    fontSize={'14px'}
                                    disabled={disabledBtn}
                                />
                            </Tooltip>
                        </div>
                    }
                    <div onClick={onSave} onDoubleClick={() => {
                    }}>
                        {
                            withTooltip
                                ?
                                <Tooltip text={currentGrid === 'Requirements'
                                    ?
                                    "Search requirements by contact or organization and create new requirement for them. You need to find contact and organization first."
                                    :
                                    currentGrid === 'Map'
                                        ?
                                        "Add owner with your searched contact and organization data to property. Search and select contact and organization first."
                                        :
                                        "Search requirements by contact or organization and edit a requirement for them. You need to find contact and organization first."
                                }
                                         classname={'addressButtonsTooltip '}>
                                    <PrimaryButton
                                        text={buttonOkText}
                                        isAddButton={false}
                                        width={currentScreenWidth!! > 480 ? !isHiddenBtn ? primaryBtnWidth ? primaryBtnWidth : '189px' : '100%' : '100%'}
                                        height={'44px'}
                                        fontSize={'14px'}
                                        disabled={disabledSearchBtn}
                                        loading={loading}
                                    />
                                </Tooltip>
                                :
                                <PrimaryButton
                                    text={buttonOkText}
                                    isAddButton={false}
                                    width={currentScreenWidth!! > 480 ? !isHiddenBtn ? primaryBtnWidth ? primaryBtnWidth : '189px' : '100%' : '100%'}
                                    height={'44px'}
                                    fontSize={'14px'}
                                    disabled={disabledSearchBtn}
                                    loading={loading}
                                />
                        }

                    </div>
                </div>
            </div>
        </div>

    );
};

export default CreationModal;