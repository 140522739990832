import React, {useEffect, useState} from 'react';
import s from "../CreateContactForReq/CreateContactForReq.module.scss";
import {Checkbox, DatePicker, Input, message, Radio, Select} from "antd";
import TextArea from "antd/lib/input/TextArea";
import SecondaryButton from "../../common/Buttons/SecondaryButton/SecondaryButton";
import PrimaryButton from "../../common/Buttons/PrimaryButton/PrimaryButton";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    GetColdOfferRequirementLinkedPropertiesThunk,
    onChangeCreateContactFormField,
    onChangeCreateOrganizationFormField,
    onChangeCreateReqCurrentStep,
    onSetIsOrganizationStepSkipped,
    selectOrganizationStepSkipped, selectRequirementColdOfferContactsEmails,
    selectRequirementsContactsForCreation,
    selectRequirementsOrganizationForCreation
} from "../../../store/requirementsReducer";
import {Moment} from "moment";
import {selectAgentReportsDomains} from "../../../store/agentReportsReducer";
import {useNavigate} from "react-router";
import {
    ContactsTitlesType, GetContactsProfessionTitles, onChangeCurrentGridTab,
    selectContactsGenderTitles,
    selectContactsJobTitles, selectContactsProfessionTitles, selectCountriesArray
} from "../../../store/propertiesReducer";
import {GetLanguages, selectLanguages} from "../../../store/utilityReducer";
import {selectUserData} from "../../../store/userReducer";
import moment from 'moment';
import PhoneInputComponent from "../../common/PhoneInput/PhoneInputComponent";
import {gridDataHelper, utilityDataHelper} from "../../../helpers/localStorageHelper";
import CreationModal from "../../common/CreationModal/CreationModal";
import {onSetIsCreateOrgSkipped} from '../../../store/propertiesMap';
import {contactsApi, propertiesApi} from "../../../app/api";
import {sessionStorageUtilityValues} from "../../../helpers/sessionStorageHelper";


moment.locale('de')

const CreateContactStep = () => {
    const contactsForCreation = useAppSelector(selectRequirementsContactsForCreation)
    const dispatch = useAppDispatch()
    const departments = useAppSelector(selectAgentReportsDomains)
    const navigate = useNavigate()
    const [fieldsWithError, setFieldsWithError] = React.useState(false)
    const genderTitles = useAppSelector(selectContactsGenderTitles)
    const jobTitles = useAppSelector(selectContactsJobTitles)
    const corrLanguages = useAppSelector(selectLanguages)
    const isCreateOrgStepSkipped = useAppSelector(selectOrganizationStepSkipped)
    const userData = useAppSelector(selectUserData)
    const professionTitles = useAppSelector(selectContactsProfessionTitles)
    const countries = useAppSelector(selectCountriesArray)
    const organizationFormFields = useAppSelector(selectRequirementsOrganizationForCreation)
    const domain = gridDataHelper.getCurrentCreateReqDomain()
    const [isCreateOrgModalOpened, setIsCreateOrgModalOpened] = useState(false)
    const contacts = useAppSelector(selectRequirementsContactsForCreation)
    const company = useAppSelector(selectRequirementsOrganizationForCreation)
    const currentPropertyRefForOwner = gridDataHelper.getCurrentPropertyRefForOwnerCreation()
    const currentReqsGridMode = gridDataHelper.getCurrentRequirementsGridMode()
    const [isOwnerAdding, setISOwnerAdding] = useState(false)
    const currentReqIdForColdOffer = sessionStorageUtilityValues.getCurrentReqIdForReqEdit()
    const contactEmailsFromStore = useAppSelector(selectRequirementColdOfferContactsEmails)
    const contactMode = gridDataHelper.getColdOfferContactMode()

    useEffect(() => {
        if(currentReqsGridMode === "Requirements/Cold Offer"){
            dispatch(GetColdOfferRequirementLinkedPropertiesThunk(currentReqIdForColdOffer))
        }
    }, [dispatch, currentReqsGridMode, currentReqIdForColdOffer])

    const mapAndFilterTitles = (titlesArray: ContactsTitlesType[]) =>
        Array.isArray(titlesArray)
            ? titlesArray.map(t => ({
                value: t.AE_LI_VALUE,
                label: t.AE_LI_VALUE
            })).filter((title) => title.label !== null)
            : [];

    const titles = mapAndFilterTitles(genderTitles)
    const jobTitlesValues = mapAndFilterTitles(jobTitles)
    const professionTitlesValues = mapAndFilterTitles(professionTitles);

    const onFormInputChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>, inputName: string, contactId: number) => {
        const {value} = e.target;
        dispatch(onChangeCreateContactFormField({value, inputName, contactId}))
    }

    const onFormCheckboxChange = (e: boolean, inputName: string, contactId: number) => {
        if (e) {
            dispatch(onChangeCreateContactFormField({value: 1, inputName, contactId}))
        } else {
            dispatch(onChangeCreateContactFormField({value: 0, inputName, contactId}))
        }

    }

    const onFormSelectChange = (value: string, inputName: string, contactId: number) => {
        if(value !== ''){
            dispatch(onChangeCreateContactFormField({value, inputName, contactId}))
        }
    }


    const onBirthdateChange = (value: Moment | null | string, inputName: string, contactId: number) => {
        dispatch(onChangeCreateContactFormField({value, inputName, contactId}))
    }

    const onGoToNextStep = () => {
        const requiredFields = ['pe_first_name', 'pe_last_name', 'pe_email', 'pe_lang_index', 'pe_department']
        // @ts-ignore
        const invalidContacts = contactsForCreation.filter(contact => requiredFields.some(field => !contact[field]));
        if (invalidContacts.length > 0) {
            setFieldsWithError(true)
        } else {
            setFieldsWithError(false)
            if(currentReqsGridMode !== 'Map/Edit Owner' && currentReqsGridMode !== 'Map' && currentReqsGridMode !== 'Requirements/Cold Offer Edit'){
                if (isCreateOrgStepSkipped) {
                    dispatch(onChangeCreateReqCurrentStep('Create requirement'))
                } else {
                    dispatch(onChangeCreateReqCurrentStep('Create organization'))
                }
            }
            else if(currentReqsGridMode === 'Requirements/Cold Offer Edit'){
                dispatch(onChangeCreateReqCurrentStep('Create requirement'))
            }
            else{
                setIsCreateOrgModalOpened(true)
            }
        }
    }

    const onEditOwner = () => {
        setISOwnerAdding(true)
        contactsApi.updateContactPropertyOwner(Number(contacts[0].pe_ref), contacts.map(({contact_tel, contact_mob, id, pe_department, ...rest}: any) => {
            return {
                ...rest,
                user_ref: Number(userData.user_ref),
            };
        })[0])
            .then(() => {
                setISOwnerAdding(false)
                message.success('Owner was succesfully edited')
                utilityDataHelper.setIsRedirectedAfterOwnerUpdate(true)
                setTimeout(() => {
                    navigate('/properties')
                }, 1500)
            })
            .catch(() => {
                setISOwnerAdding(false)
            })
    }


    useEffect(() => {
        dispatch(GetLanguages())
        dispatch(GetContactsProfessionTitles())
    }, [dispatch])

    const corrLanguagesOptions = corrLanguages.map((a: { id: number, value: string }) => ({
        value: a.id,
        label: a.value,
    }))


    const countryOptions = countries.map((a: any) => ({
        value: a.pk_country,
        label: a.cntry_desc_default,
    }))

    const emailContacts = contactEmailsFromStore.map((a: any) => ({
        value: a.EMAILS,
        label: a.EMAILS,
    }))

    const onFormOrganizationSelectChange = (value: string, inputName: string) => {
        dispatch(onChangeCreateOrganizationFormField({value, inputName}))
    }


    const onFormOrganizationInputChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>, inputName: string) => {
        const {value} = e.target;
        dispatch(onChangeCreateOrganizationFormField({value, inputName}))
    }


    const handleStreetAddressChange = (e: React.ChangeEvent<HTMLInputElement>, inputName: string) => {
        const value = e.target.value;
        if (/^[0-9]{0,5}$/.test(value)) {
            onFormOrganizationInputChange(e, inputName);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        const isValid = /^[/-]*$/.test(newValue); // Regex to allow only '/' and '-'

        if (isValid) {
            dispatch(onChangeCreateOrganizationFormField({value: e.target.value, inputName: 'co_no_connect'}));
        }
    };


    const onCreateOrgForPropMap = () => {
        dispatch(onChangeCreateReqCurrentStep('Create organization'))
        dispatch(onSetIsCreateOrgSkipped(false))
    }


    const onCreateOwner = () => {
        setISOwnerAdding(true)
        const req = {
            department: contacts[0].pe_department!!,
            property_ref: currentPropertyRefForOwner,
            user_ref: Number(userData.user_ref),
            company: (() => {
                const {co_fax, co_phone, ...filteredCompany} = {
                    ...company,
                    co_city: company.co_city !== null ? company.co_city : 'Invalid',
                    co_country_ref: company.co_country_ref !== null ? company.co_country_ref : 420,
                    co_first_contact: null,
                    co_name: `${contacts[0]?.pe_last_name} ${contacts[0]?.pe_profession_title !== null ? contacts[0]?.pe_profession_title : ''} ${contacts[0]?.pe_first_name}`,
                    co_no_connect: company.co_no_connect,
                    co_no_from: company.co_no_from,
                    co_no_from_rest: company.co_no_from_rest,
                    co_no_to: company.co_no_to,
                    co_no_to_rest: company.co_no_to_rest,
                    co_quarter: company.co_quarter,
                    co_ref: null,
                    co_source_index: null,
                    co_street_address: company.co_street_address !== null ? company.co_street_address : 'Invalid',
                    co_zip: company.co_zip !== null ? company.co_zip : -1,
                    co_tel_cntry_code: null,
                    co_tel_number: null,
                    co_tel_region_code: null,
                    co_fax_cntry_code: null,
                    co_fax_number: null,
                    co_fax_region_code: null
                };
                return filteredCompany;
            })(),
            contact: contacts.map(({contact_tel, contact_mob, id, pe_department, ...rest}: any) => {
                const parsePhone = (phone: string | null) => {
                    if (!phone) return {country: null, region: null, number: null};
                    const parts = phone.split(' ');
                    const country = parts[0];
                    const regionAndNumber = parts.slice(1).join('');
                    const region = regionAndNumber.slice(0, 3);
                    const number = regionAndNumber.slice(3);

                    return {country, region, number};
                };

                const parsedTel = parsePhone(contact_tel);
                const parsedMob = parsePhone(contact_mob);

                return {
                    ...rest,
                    pe_tel_cntry_code: parsedTel.country,
                    pe_tel_region_code: parsedTel.region,
                    pe_tel_number: parsedTel.number,
                    pe_mob_cntry_code: parsedMob.country,
                    pe_mob_region_code: parsedMob.region,
                    pe_mob_number: parsedMob.number,
                    user_ref: Number(userData.user_ref),
                    pe_is_mail: contacts[0].pe_is_mail ? 1 : 0
                };
            })[0]
        }
        // @ts-ignore
        propertiesApi.onCreateOwner(req)
            .then((res) => {
                setISOwnerAdding(false)
                message.success('Owner was succesfully added')
                utilityDataHelper.setIsRedirectedAfterOwnerUpdate(true)
                utilityDataHelper.setIsCreatedOwnerForScrollToIt(true)
                gridDataHelper.setCurrentSearchedContactId(res.data)
                setTimeout(() => {
                    navigate('/properties')
                }, 1500)
            })
            .catch(() => {
                setISOwnerAdding(false)
            })
    }

    const handleBlur = () => {
        // Do nothing to prevent value loss
    };
    return (
        <div className={s.form}>
            {
                isCreateOrgStepSkipped
                    ?
                    <p className={s.title}>Creation of a contact as an individual</p>
                    :
                    <p className={s.title}>{currentReqsGridMode !== 'Map/Edit Owner' && currentReqsGridMode !== 'Requirements/Cold Offer Edit' && currentReqsGridMode !== 'Requirements/Cold Offer Edit' ? 'Create' : 'Edit'} contact</p>
            }

            <div>
                {contactsForCreation.map((contact: any, index) => (
                    <div className={s.form__inner} key={index}>
                        {
                            contactsForCreation.length > 1
                            &&
                            <p className={s.contactNumber}>{index + 1}. Contact</p>
                        }
                        <div className={s.form__rowDouble}>
                            <div className={s.form__row}>
                                <label>First name<span>*</span></label>
                                <Input placeholder={'Enter first name'}
                                       value={contact.pe_first_name}
                                       onChange={(e) => onFormInputChange(e, 'pe_first_name', contact.id)}/>
                                {contact.pe_first_name === null && fieldsWithError &&
                                    <p className={s.form__error}>Please enter your first name!</p>}
                            </div>
                            <div className={s.form__row}>
                                <label>Last name<span>*</span></label>
                                <Input placeholder={'Enter last name'}
                                       value={contact.pe_last_name}
                                       onChange={(e) => onFormInputChange(e, 'pe_last_name', contact.id)}/>
                                {contact.pe_last_name === null && fieldsWithError &&
                                    <p className={s.form__error}>Please enter your last name!</p>}
                            </div>
                        </div>
                        <div style={{
                            display: 'flex',
                            gap: '6px',
                            alignItems: 'center',
                            marginBottom: '16px'
                        }} className={s.form__checkbox}>
                            <Checkbox onChange={(e) => onFormCheckboxChange(e.target.checked, 'pe_is_mail', contact.id)}
                                      checked={contact.pe_is_mail === 1}/>
                            <label>Mail receipients </label>
                        </div>
                        <div className={s.form__rowDouble}>
                            <div className={s.form__row}>
                                <label>Mobile</label>
                                <PhoneInputComponent inputType={'contactMobile'}/>
                                {/*<div className={s.form__rowMobile}>*/}
                                {/*    <Input*/}
                                {/*           value={contact.pe_mob_cntry_code}*/}
                                {/*           type={'tel'}*/}
                                {/*           style={{*/}
                                {/*               width: '24%',*/}
                                {/*           }}*/}
                                {/*           onChange={(e) => handleCountryCodeChange(e, contact.id, 'mobile')}/>*/}
                                {/*    <Input*/}
                                {/*           value={contact.pe_mob_region_code}*/}
                                {/*           type={'tel'}*/}
                                {/*           style={{*/}
                                {/*               width: '24%',*/}
                                {/*           }}*/}
                                {/*           onChange={(e) => handleRegionCodeChange(e, contact.id, 'mobile')}/>*/}
                                {/*    <Input*/}
                                {/*           value={contact.pe_mob_number}*/}
                                {/*           type={'tel'}*/}
                                {/*           style={{*/}
                                {/*               width: '48%',*/}
                                {/*           }}*/}
                                {/*           onChange={(e) => handleMobileNumberChange(e,  contact.id, 'mobile')}/>*/}
                                {/*</div>*/}
                            </div>
                            <div className={s.form__row}>
                                <label>Telephone</label>
                                <PhoneInputComponent inputType={'contactTelephone'}/>
                                {/*<div className={s.form__rowMobile}>*/}
                                {/*    <Input*/}
                                {/*        value={contact.pe_tel_cntry_code}*/}
                                {/*        type={'tel'}*/}
                                {/*        style={{*/}
                                {/*            width: '24%',*/}
                                {/*        }}*/}
                                {/*        onChange={(e) => handleCountryCodeChange(e, contact.id, 'tel')}/>*/}
                                {/*    <Input*/}
                                {/*        value={contact.pe_tel_region_code}*/}
                                {/*        type={'tel'}*/}
                                {/*        style={{*/}
                                {/*            width: '24%',*/}
                                {/*        }}*/}
                                {/*        onChange={(e) => handleRegionCodeChange(e, contact.id, 'tel')}/>*/}
                                {/*    <Input*/}
                                {/*        value={contact.pe_tel_number}*/}
                                {/*        type={'tel'}*/}
                                {/*        style={{*/}
                                {/*            width: '48%',*/}
                                {/*        }}*/}
                                {/*        onChange={(e) => handleMobileNumberChange(e, contact.id, 'tel')}/>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className={s.form__row} style={{
                            marginBottom: '16px'
                        }}>
                            <label>E-mail<span>*</span></label>
                            {
                                contactMode === 'Requirements/Cold Offer/Create Contact'
                                ?
                                    contactEmailsFromStore.length === 1
                                    ?
                                        <Input placeholder={'Enter email'}
                                               value={contact.pe_email}
                                               onChange={(e) => onFormInputChange(e, 'pe_email', contact.id)}
                                               type="email"
                                        />
                                        :
                                        <Select
                                            placeholder="Select or type email"
                                            value={contact.pe_email} // Keeps the entered value
                                            onChange={(value) => onFormSelectChange(value, 'pe_email', contact.id)}
                                            onSearch={(value) => onFormSelectChange(value, 'pe_email', contact.id)} // Updates value while typing
                                            style={{ width: '100%' }}
                                            className="reqCreateFormSelect"
                                            options={emailContacts}
                                            showSearch
                                            allowClear={true}
                                            notFoundContent={null} // Prevents "No data" message
                                            onBlur={handleBlur}
                                            onInputKeyDown={(e) => {
                                                if (e.key === "Enter" && contact.pe_email) {
                                                    onFormSelectChange(contact.pe_email, 'pe_email', contact.id);
                                                }
                                            }}
                                        />

                                    :
                                    <Input placeholder={'Enter email'}
                                           value={contact.pe_email}
                                           onChange={(e) => onFormInputChange(e, 'pe_email', contact.id)}
                                           type="email"
                                    />
                            }

                            {contact.pe_email === null && fieldsWithError &&
                                <p className={s.form__error}>Please enter your email!</p>}
                        </div>
                        <div className={s.form__rowDouble}>
                            <div className={s.form__row}>
                                <label>Corresp. Language<span>*</span></label>
                                <Select
                                    placeholder="Select language"
                                    value={contact.pe_lang_index}
                                    onChange={(value) => onFormSelectChange(value, 'pe_lang_index', contact.id)}
                                    style={{width: '100%'}}
                                    className={'reqCreateFormSelect'}
                                    options={corrLanguagesOptions}
                                />
                                {contact.pe_lang_index === null && fieldsWithError &&
                                    <p className={s.form__error}>Please select language!</p>}
                            </div>
                            <div className={`${s.form__row} birthdayContactPicker`}>
                                <label>Birthday</label>
                                <DatePicker
                                    value={contact.pe_birthday ? moment(contact.pe_birthday, 'DD.MM.YYYY') : null}
                                    format="DD.MM.YYYY"
                                    onChange={(date, dateString) => onBirthdateChange(dateString, 'pe_birthday', contact.id)}
                                />
                            </div>

                        </div>

                        <div className={s.form__rowDouble}>
                            <div className={s.form__row} style={{
                                marginBottom: '16px'
                            }}>
                                <label>Title</label>
                                <Select
                                    placeholder="Select title"
                                    value={contact.pe_gender_title}
                                    onChange={(value) => onFormSelectChange(value, 'pe_gender_title', contact.id)}
                                    style={{width: '100%'}}
                                    className={'reqCreateFormSelect'}
                                    options={titles}
                                />
                            </div>
                            <div className={s.form__row} style={{
                                marginBottom: '16px'
                            }}>
                                <label>Profession title</label>
                                <Select
                                    placeholder="Select profession title"
                                    value={contact.pe_profession_title}
                                    onChange={(value) => onFormSelectChange(value, 'pe_profession_title', contact.id)}
                                    style={{width: '100%'}}
                                    className={'reqCreateFormSelect'}
                                    options={professionTitlesValues}
                                />
                            </div>
                        </div>

                        <div className={s.form__rowDouble}>
                            <div className={s.form__row}>
                                <label>Position</label>
                                <Select
                                    placeholder="Select position"
                                    value={contact.pe_job_position}
                                    onChange={(value) => onFormSelectChange(value, 'pe_job_position', contact.id)}
                                    style={{width: '100%'}}
                                    className={'reqCreateFormSelect'}
                                    options={jobTitlesValues}
                                />
                            </div>
                            <div className={s.form__row}>
                                <label>Department<span>*</span></label>
                                <Select
                                    placeholder="Select department"
                                    value={contact.pe_department !== 'null' ? contact.pe_department : null}
                                    onChange={(value) => onFormSelectChange(value, 'pe_department', contact.id)}
                                    style={{width: '100%'}}
                                    className={'reqCreateFormSelect'}
                                    options={departments}
                                    disabled={(userData.department !== null && userData.department !== '' && userData.department !== 'null') || contactMode === 'Requirements/Cold Offer/Create Contact'}
                                />
                                {contact.pe_department === null && fieldsWithError &&
                                    <p className={s.form__error}>Please select department!</p>}
                            </div>
                        </div>
                        {
                            isCreateOrgStepSkipped
                            &&
                            <>
                                <div className={s.form__row}>
                                    <label>Country</label>
                                    <Select
                                        showSearch
                                        placeholder="Select country"
                                        value={countryOptions.find((c) => Number(c.value) === Number(organizationFormFields.co_country_ref))?.label}
                                        onChange={(value) => onFormOrganizationSelectChange(value, 'co_country_ref')}
                                        style={{width: '100%'}}
                                        className={'reqCreateFormSelect'}
                                        options={countryOptions}
                                        optionFilterProp="label"
                                        filterOption={(input, option) =>
                                            option?.label.toLowerCase().includes(input.toLowerCase())
                                        }
                                    />
                                </div>
                                <div className={s.form__rowDouble} style={{
                                    marginTop: '16px'
                                }}>
                                    <div className={s.form__row}>
                                        <label>Post code</label>
                                        <Input placeholder={'Enter post code'}
                                               value={organizationFormFields.co_zip!!}
                                               onChange={(e) => onFormOrganizationInputChange(e, 'co_zip')}/>
                                    </div>
                                    <div className={s.form__row}>
                                        <label>City</label>
                                        <Input placeholder={'Enter city'}
                                               value={organizationFormFields.co_city!!}
                                               onChange={(e) => onFormOrganizationInputChange(e, 'co_city')}/>
                                    </div>
                                </div>
                                <div className={s.form__rowDouble} style={{
                                    marginTop: '16px'
                                }}>
                                    <div className={s.form__row}>
                                        <label>Street Address</label>
                                        <Input placeholder={'Enter street address'}
                                               value={organizationFormFields.co_street_address!!}
                                               onChange={(e) => onFormOrganizationInputChange(e, 'co_street_address')}/>
                                        {/*{organizationFormFields.co_street_address === null && fieldsWithError &&*/}
                                        {/*    <p className={s.form__error}>Please enter your street address!</p>}*/}
                                    </div>
                                    <div className={s.form__row}>
                                        <label>House number</label>
                                        <div style={{
                                            display: 'flex',
                                            gap: '4px'
                                        }}>
                                            <Input
                                                value={organizationFormFields.co_no_from!!}
                                                style={{
                                                    width: '22.5%'
                                                }}
                                                onChange={(e) => handleStreetAddressChange(e, 'co_no_from')}/>
                                            <Input
                                                style={{
                                                    width: '22.5%'
                                                }}
                                                value={organizationFormFields.co_no_from_rest!!}
                                                onChange={(e) => handleStreetAddressChange(e, 'co_no_from_rest')}/>
                                            <Input
                                                style={{
                                                    width: '10%'
                                                }}
                                                value={organizationFormFields.co_no_connect!!}
                                                placeholder={'/'}
                                                onChange={(e) => handleInputChange(e)}/>
                                            <Input
                                                style={{
                                                    width: '22.5%'
                                                }}
                                                value={organizationFormFields.co_no_to!!}
                                                onChange={(e) => handleStreetAddressChange(e, 'co_no_to')}/>
                                            <Input
                                                style={{
                                                    width: '22.5%'
                                                }}
                                                value={organizationFormFields.co_no_to_rest!!}
                                                onChange={(e) => handleStreetAddressChange(e, 'co_no_to_rest')}/>
                                        </div>

                                    </div>
                                </div>
                            </>
                        }
                        <div className={s.form__row}>
                            <label>Remark</label>
                            <TextArea placeholder={'Enter remark'}
                                      value={contact.pe_remarks}
                                      onChange={(e) => onFormInputChange(e, 'pe_remarks', contact.id)}/>
                        </div>
                    </div>
                ))}
            </div>
            {/*<div>*/}
            {/*    <button onClick={onAddContactToForm} className={s.addBtn}>+ Add contact</button>*/}
            {/*</div>*/}
            <div className={s.form__asterics}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                     fill="none">
                    <path
                        d="M12 8V12M12 16H12.01M7.86 2H16.14L22 7.86V16.14L16.14 22H7.86L2 16.14V7.86L7.86 2Z"
                        stroke="#A0A0A0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <p>Please fill all fields that have an asterisk (<span>*</span>).</p>
            </div>
            {
                domain === 'RR'
                &&
                <div className={s.note}>
                    <p>Note: Remember, filled fields and filters will only be added to the requirement properties, not
                        surfaces.</p>
                </div>
            }
            <div className={s.buttons}>
                <div onClick={() => navigate('/requirements')} style={{
                    width: '100%'
                }}>
                    <SecondaryButton text={'Cancel'} width={'100%'} height={'44px'} isWhiteBg={true}/>
                </div>
                {
                    currentReqsGridMode !== 'Map/Edit Owner'
                        ?
                        <div style={{
                            width: '100%'
                        }}
                             onClick={onGoToNextStep}
                        >
                            <PrimaryButton text={'Next'} isAddButton={false} width={'100%'} height={'44px'}/>
                        </div>
                        :
                        <div style={{
                            width: '100%'
                        }}
                             onClick={onEditOwner}
                        >
                            <PrimaryButton text={'Save'} isAddButton={false} width={'100%'} height={'44px'} loading={isOwnerAdding}/>
                        </div>
                }

            </div>
            {
                isCreateOrgModalOpened
                &&
                <CreationModal
                    title={'Organization Creation'}
                    image={''}
                    onCancel={onCreateOwner}
                    onSave={onCreateOrgForPropMap}
                    loading={isOwnerAdding}
                    withMargin={false}
                    customButtons={true}
                    buttonOkText={'Yes'}
                    buttonCancelText={'Create owner'}
                    isHiddenBtn={false}
                >
                    <div>
                        <p className={s.modalText}>Do you want to create an organization?</p>
                    </div>
                </CreationModal>
            }
        </div>
    );
};

export default CreateContactStep;