import { TokenDataHelperType, UserDataHelperType } from '../types/localStorageDataTypes'
import {ColumnData} from "../components/PropertiesPage/Properties";
import {AvailableUserMenuItem} from "../store/userReducer";


export const tokenDataHelper: TokenDataHelperType = {
  setTokenData(data) {
    localStorage.setItem('tokenData', JSON.stringify(data))
  },
  getTokenData() {
    const tokenData = localStorage.getItem('tokenData')
    return !!tokenData && JSON.parse(tokenData)
  },
  removeTokenData() {
    return localStorage.removeItem('tokenData')
  },
}

export const userDataHelper: UserDataHelperType = {
  setUserData(data) {
    localStorage.setItem('userData', JSON.stringify(data))
  },
  getUserData() {
    const userData = localStorage.getItem('userData')
    return !!userData && JSON.parse(userData)
  },
  removeUserData() {
    return localStorage.removeItem('userData')
  },
  setIsBelongsToGroup(data: boolean) {
    localStorage.setItem('IsBelongsToGroup', JSON.stringify(data))
  },
  getIsBelongsToGroup() {
    const IsBelongsToGroup = localStorage.getItem('IsBelongsToGroup')
    return !!IsBelongsToGroup && JSON.parse(IsBelongsToGroup)
  },
  removeIsBelongsToGroup() {
    return localStorage.removeItem('IsBelongsToGroup')
  },
  setIsIntegratedWithGoogle(data:boolean) {
    localStorage.setItem('IsIntegratedWithGoogle', JSON.stringify(data))
  },
  getIsIntegratedWithGoogle() {
    const IsIntegratedWithGoogle = localStorage.getItem('IsIntegratedWithGoogle')
    return !!IsIntegratedWithGoogle && JSON.parse(IsIntegratedWithGoogle)
  },
  removeIsIntegratedWithGoogle() {
    return localStorage.removeItem('IsIntegratedWithGoogle')
  },
  setAvailableMenuItems(data: AvailableUserMenuItem[]) {
    localStorage.setItem('availableMenuItems', JSON.stringify(data))
  },
  getAvailableMenuItems() {
    const availableMenuItems = localStorage.getItem('availableMenuItems')
    return !!availableMenuItems && JSON.parse(availableMenuItems)
  },
  removeAvailableMenuItems() {
    return localStorage.removeItem('availableMenuItems')
  },
}

export const gridDataHelper: any = {
  setRowId(data: number) {
    localStorage.setItem('gridRowId', JSON.stringify(data))
  },
  getRowId() {
    const gridRowId = localStorage.getItem('gridRowId')
    return !!gridRowId && JSON.parse(gridRowId)
  },
  removeRowId(){
    return localStorage.removeItem('gridRowId')
  },
  setRowTitle(data: number) {
    localStorage.setItem('gridRowTitle', JSON.stringify(data))
  },
  getRowTitle() {
    const gridRowTitle = localStorage.getItem('gridRowTitle')
    return !!gridRowTitle && JSON.parse(gridRowTitle)
  },
  removeRowTitle(){
    return localStorage.removeItem('gridRowTitle')
  },
  getPropertiesGridFilters() {
    const PropertiesGridFilters = localStorage.getItem('PropertiesGridFilters')
    return !!PropertiesGridFilters && JSON.parse(PropertiesGridFilters)
  },
  setPropertiesGridFilters(data: any[]) {
    localStorage.setItem('PropertiesGridFilters', JSON.stringify(data))
  },
  removePropertiesGridFilters(){
    return localStorage.removeItem('PropertiesGridFilters')
  },
  removeConditionGridFiltersString(){
    return localStorage.removeItem('ConditionGridFiltersString')
  },
  setPropertiesGridConfig(data: string) {
    localStorage.setItem('PropertiesGridConfig', JSON.stringify(data))
  },
  getPropertiesGridConfig() {
    const PropertiesGridConfig = localStorage.getItem('PropertiesGridConfig')
    return !!PropertiesGridConfig && JSON.parse(PropertiesGridConfig)
  },
  removePropertiesGridConfig(){
    return localStorage.removeItem('PropertiesGridConfig')
  },
  setPropertiesGridColumnsWithSorting(properties: ColumnData) {
    localStorage.setItem('PropertiesGridColumnsWithSorting', JSON.stringify(properties))
  },
  getPropertiesGridColumnsWithSorting() {
    const PropertiesGridColumnsWithSorting = localStorage.getItem('PropertiesGridColumnsWithSorting')
    return !!PropertiesGridColumnsWithSorting ? JSON.parse(PropertiesGridColumnsWithSorting) : { properties: [] }
  },
  removePropertiesGridColumnsWithSorting(){
    return localStorage.removeItem('PropertiesGridColumnsWithSorting')
  },
  setIsCheckReqMatchFirstTime(isReqMatchFirstTime: boolean) {
    localStorage.setItem('isReqMatchFirstTime', JSON.stringify(isReqMatchFirstTime))
  },
  getIsCheckReqMatchFirstTime() {
    const isReqMatchFirstTime = localStorage.getItem('isReqMatchFirstTime')
    return !!isReqMatchFirstTime && JSON.parse(isReqMatchFirstTime)
  },
  removeIsCheckReqMatchFirstTime(){
    return localStorage.removeItem('isReqMatchFirstTime')
  },
  setReqGridDomain(reqGridDomain: string) {
    localStorage.setItem('reqGridDomain', JSON.stringify(reqGridDomain))
  },
  getReqGridDomain() {
    const reqGridDomain = localStorage.getItem('reqGridDomain')
    return !!reqGridDomain && JSON.parse(reqGridDomain)
  },
  removeReqGridDomain(){
    return localStorage.removeItem('reqGridDomain')
  },
  setReqGridRowData(reqGridRowData: any) {
    localStorage.setItem('reqGridRowData', JSON.stringify(reqGridRowData))
  },
  getReqGridRowData() {
    const reqGridRowData = localStorage.getItem('reqGridRowData')
    return !!reqGridRowData && JSON.parse(reqGridRowData)
  },
  removeReqGridRowData(){
    return localStorage.removeItem('reqGridRowData')
  },
  getAttrGridFilters() {
    const AttrGridFilters = localStorage.getItem('AttrGridFilters')
    return !!AttrGridFilters && JSON.parse(AttrGridFilters)
  },
  setAttrGridFilters(data: any[]) {
    localStorage.setItem('AttrGridFilters', JSON.stringify(data))
  },
  removeAttrGridFilters(){
    return localStorage.removeItem('AttrGridFilters')
  },
  getBrokeragePropertiesSearchValue() {
    const BrokeragePropertiesSearchValue = localStorage.getItem('BrokeragePropertiesSearchValue')
    return !!BrokeragePropertiesSearchValue && JSON.parse(BrokeragePropertiesSearchValue)
  },
  setBrokeragePropertiesSearchValue(data: string) {
    localStorage.setItem('BrokeragePropertiesSearchValue', JSON.stringify(data))
  },
  removeBrokeragePropertiesSearchValue(){
    return localStorage.removeItem('BrokeragePropertiesSearchValue')
  },
  getBrokeragePropertiesStatus() {
    const BrokeragePropertiesStatus = localStorage.getItem('BrokeragePropertiesStatus')
    return !!BrokeragePropertiesStatus && JSON.parse(BrokeragePropertiesStatus)
  },
  setBrokeragePropertiesStatus(data: string | null) {
    localStorage.setItem('BrokeragePropertiesStatus', JSON.stringify(data))
  },
  removeBrokeragePropertiesStatus(){
    return localStorage.removeItem('BrokeragePropertiesStatus')
  },
  getCurrentSearchedContactId() {
    const currentSearchedContactId = localStorage.getItem('currentSearchedContactId')
    return !!currentSearchedContactId && JSON.parse(currentSearchedContactId)
  },
  setCurrentSearchedContactId(data: number) {
    localStorage.setItem('currentSearchedContactId', JSON.stringify(data))
  },
  removeCurrentSearchedContactId(){
    return localStorage.removeItem('currentSearchedContactId')
  },
  getCurrentCreateReqDomain() {
    const currentCreateReqDomain = localStorage.getItem('currentCreateReqDomain')
    return !!currentCreateReqDomain && JSON.parse(currentCreateReqDomain)
  },
  setCurrentCreateReqDomain(data: string) {
    localStorage.setItem('currentCreateReqDomain', JSON.stringify(data))
  },
  removeCurrentCreateReqDomain(){
    return localStorage.removeItem('currentCreateReqDomain')
  },
  getCurrentSearchedCompanyId() {
    const currentSearchedCompanyId = localStorage.getItem('currentSearchedCompanyId')
    return !!currentSearchedCompanyId && JSON.parse(currentSearchedCompanyId)
  },
  setCurrentSearchedCompanyId(data: number) {
    localStorage.setItem('currentSearchedCompanyId', JSON.stringify(data))
  },
  removeCurrentSearchedCompanyId(){
    return localStorage.removeItem('currentSearchedCompanyId')
  },
  getCurrentSearchedContactName() {
    const currentSearchedContactName = localStorage.getItem('currentSearchedContactName')
    return !!currentSearchedContactName && JSON.parse(currentSearchedContactName)
  },
  setCurrentSearchedContactName(data: number) {
    localStorage.setItem('currentSearchedContactName', JSON.stringify(data))
  },
  removeCurrentSearchedContactName(){
    return localStorage.removeItem('currentSearchedContactName')
  },
  getCurrentRequirementsGridMode() {
    const currentRequirementsGridMode = localStorage.getItem('currentRequirementsGridMode')
    return !!currentRequirementsGridMode && JSON.parse(currentRequirementsGridMode)
  },
  setCurrentRequirementsGridMode(data: 'Requirements' | 'Requirements/Cold Offer' | 'Map') {
    localStorage.setItem('currentRequirementsGridMode', JSON.stringify(data))
  },
  removeCurrentRequirementsGridMode(){
    return localStorage.removeItem('currentRequirementsGridMode')
  },
  getCurrentPropertyRefForOwnerCreation() {
    const currentPropertyRefForOwnerCreation = localStorage.getItem('currentPropertyRefForOwnerCreation')
    return !!currentPropertyRefForOwnerCreation && JSON.parse(currentPropertyRefForOwnerCreation)
  },
  setCurrentPropertyForOwnerCreation(data: number) {
    localStorage.setItem('currentPropertyRefForOwnerCreation', JSON.stringify(data))
  },
  removeCurrentPropertyForOwnerCreation(){
    return localStorage.removeItem('currentPropertyRefForOwnerCreation')
  },
  getCurrentOwnerForEdit() {
    const currentOwnerForEdit = localStorage.getItem('currentOwnerForEdit')
    return !!currentOwnerForEdit && JSON.parse(currentOwnerForEdit)
  },
  setCurrentOwnerForEdit(data: any) {
    localStorage.setItem('currentOwnerForEdit', JSON.stringify(data))
  },
  removeCurrentOwnerForEdit(){
    return localStorage.removeItem('currentOwnerForEdit')
  },
  getColdOfferContactMode() {
    const coldOfferContactMode = localStorage.getItem('coldOfferContactMode')
    return !!coldOfferContactMode && JSON.parse(coldOfferContactMode)
  },
  setColdOfferContactMode(data: 'Requirements/Cold Offer/Create Contact' | 'Requirements/Cold Offer/Existing Contact') {
    localStorage.setItem('coldOfferContactMode', JSON.stringify(data))
  },
  removeColdOfferContactMode(){
    return localStorage.removeItem('coldOfferContactMode')
  },
}

export const parametersDataHelper: any = {
  setCurrentTemplateForEmail(data: string) {
    localStorage.setItem('CurrentTemplateForEmail', JSON.stringify(data))
  },
  getCurrentTemplateForEmail() {
    const CurrentTemplateForEmail = localStorage.getItem('CurrentTemplateForEmail')
    return !!CurrentTemplateForEmail && JSON.parse(CurrentTemplateForEmail)
  },
  removeCurrentTemplateForEmail(){
    return localStorage.removeItem('CurrentTemplateForEmail')
  },
}

export const activitiesDataHelper = {
  setActivityDateStart(data: Date) {
    localStorage.setItem('activityDateStart', JSON.stringify(data))
  },
  getActivityDateStart() {
    const activityDateStart = localStorage.getItem('activityDateStart')
    return !!activityDateStart && JSON.parse(activityDateStart)
  },
  removeActivityDateStart() {
    return localStorage.removeItem('activityDateStart')
  },
  //
  setActivityDateEnd(data: Date) {
    localStorage.setItem('activityDateEnd', JSON.stringify(data))
  },
  getActivityDateEnd() {
    const activityDateEnd = localStorage.getItem('activityDateEnd')
    return !!activityDateEnd && JSON.parse(activityDateEnd)
  },
  removeActivityDateEnd() {
    return localStorage.removeItem('activityDateEnd')
  },
}

export const utilityDataHelper = {
  setUserLastEmailLogged(data: string) {
    localStorage.setItem('userLastEmailLogged', JSON.stringify(data))
  },
  getUserLastEmailLogged() {
    const userLastEmailLogged = localStorage.getItem('userLastEmailLogged')
    return !!userLastEmailLogged && JSON.parse(userLastEmailLogged)
  },
  removeUserLastEmailLogged() {
    return localStorage.removeItem('userLastEmailLogged')
  },
  setSoldPropertiesReqData(data: any) {
    localStorage.setItem('soldPropertiesReqData', JSON.stringify(data))
  },
  getSoldPropertiesReqData() {
    const soldPropertiesReqData = localStorage.getItem('soldPropertiesReqData')
    return !!soldPropertiesReqData && JSON.parse(soldPropertiesReqData)
  },
  removeSoldPropertiesReqData() {
    return localStorage.removeItem('soldPropertiesReqData')
  },
  setIsAppVisited(data: any) {
    localStorage.setItem('isAppVisited', JSON.stringify(data))
  },
  getIsAppVisited() {
    const isAppVisited = localStorage.getItem('isAppVisited')
    return !!isAppVisited && JSON.parse(isAppVisited)
  },
  removeIsAppVisited() {
    return localStorage.removeItem('isAppVisited')
  },
  setSearchedRequirementsContactRef(data: any) {
    localStorage.setItem('searchedRequirementsContactRef', JSON.stringify(data))
  },
  getSearchedRequirementsContactRef() {
    const searchedRequirementsContactRef = localStorage.getItem('searchedRequirementsContactRef')
    return !!searchedRequirementsContactRef && JSON.parse(searchedRequirementsContactRef)
  },
  removeSearchedRequirementsContactRef() {
    return localStorage.removeItem('searchedRequirementsContactRef')
  },
  setRRSurfaceTemplateData(data: any) {
    localStorage.setItem('RRSurfaceTemplateData', JSON.stringify(data))
  },
  getRRSurfaceTemplateData() {
    const RRSurfaceTemplateData = localStorage.getItem('RRSurfaceTemplateData')
    return !!RRSurfaceTemplateData && JSON.parse(RRSurfaceTemplateData)
  },
  removeRRSurfaceTemplateData() {
    return localStorage.removeItem('RRSurfaceTemplateData')
  },
  setRRSurfaceTemplateImages(data: any) {
    localStorage.setItem('RRSurfaceTemplateImages', JSON.stringify(data))
  },
  getRRSurfaceTemplateImages() {
    const RRSurfaceTemplateImages = localStorage.getItem('RRSurfaceTemplateImages')
    return !!RRSurfaceTemplateImages && JSON.parse(RRSurfaceTemplateImages)
  },
  removeRRSurfaceTemplateImages() {
    return localStorage.removeItem('RRSurfaceTemplateImages')
  },
  setRRSurfaceTemplateDataForRequest(data: any) {
    localStorage.setItem('RRSurfaceTemplateDataForRequest', JSON.stringify(data))
  },
  getRRSurfaceTemplateDataForRequest() {
    const RRSurfaceTemplateDataForRequest = localStorage.getItem('RRSurfaceTemplateDataForRequest')
    return !!RRSurfaceTemplateDataForRequest && JSON.parse(RRSurfaceTemplateDataForRequest)
  },
  removeRRSurfaceTemplateDataForRequest() {
    return localStorage.removeItem('RRSurfaceTemplateDataForRequest')
  },
  setIsRedirectedAfterOwnerUpdate(data: boolean) {
    localStorage.setItem('isRedirectedAfterOwnerUpdate', JSON.stringify(data))
  },
  getIsRedirectedAfterOwnerUpdate() {
    const isRedirectedAfterOwnerUpdate = localStorage.getItem('isRedirectedAfterOwnerUpdate')
    return !!isRedirectedAfterOwnerUpdate && JSON.parse(isRedirectedAfterOwnerUpdate)
  },
  removeIsRedirectedAfterOwnerUpdate() {
    return localStorage.removeItem('isRedirectedAfterOwnerUpdate')
  },
  setIsCreatedOwnerForScrollToIt(data: boolean) {
    localStorage.setItem('isCreatedOwnerForScrollToIt', JSON.stringify(data))
  },
  getIsCreatedOwnerForScrollToIt() {
    const isCreatedOwnerForScrollToIt = localStorage.getItem('isCreatedOwnerForScrollToIt')
    return !!isCreatedOwnerForScrollToIt && JSON.parse(isCreatedOwnerForScrollToIt)
  },
  removeIsCreatedOwnerForScrollToIt() {
    return localStorage.removeItem('isCreatedOwnerForScrollToIt')
  },
  setMapPropertiesDomain(data: string | null) {
    localStorage.setItem('mapPropertiesDomain', JSON.stringify(data))
  },
  getMapPropertiesDomain() {
    const mapPropertiesDomain = localStorage.getItem('mapPropertiesDomain')
    return !!mapPropertiesDomain && JSON.parse(mapPropertiesDomain)
  },
  removeMapPropertiesDomain() {
    return localStorage.removeItem('mapPropertiesDomain')
  },
  setEditPicturesMode(data: string | null) {
    localStorage.setItem('editPicturesMode', JSON.stringify(data))
  },
  getEditPicturesMode() {
    const editPicturesMode = localStorage.getItem('editPicturesMode')
    return !!editPicturesMode && JSON.parse(editPicturesMode)
  },
  removeEditPicturesMode() {
    return localStorage.removeItem('editPicturesMode')
  },
  // setIsOrganizationStepSkipped(data: string) {
  //   localStorage.setItem('userLastEmailLogged', JSON.stringify(data))
  // },
  // getUserLastEmailLogged() {
  //   const userLastEmailLogged = localStorage.getItem('userLastEmailLogged')
  //   return !!userLastEmailLogged && JSON.parse(userLastEmailLogged)
  // },
  // removeUserLastEmailLogged() {
  //   return localStorage.removeItem('userLastEmailLogged')
  // },
}