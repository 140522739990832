import React, {useEffect, useState} from 'react';
import s from './SoldPropertiesPage.module.scss'
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {GetSoldPropertiesPageValues, selectSoldPropertiesPageValues} from "../../store/propertiesReducer";
import {GetMarketingTemplateThunk} from "../../store/parametersReducer";
import {utilityDataHelper} from "../../helpers/localStorageHelper";
import houseImg from "../../img/icons/no-image.svg";
import locationImg from '../../img/EV_BP_88x88_Location.jpg'
import phoneImg from '../../img/EV_BP_88x88_Phone.jpg'
import mailImg from '../../img/EV_BP_88x88_Mail.jpg'

interface WindowSize {
    width: number | undefined;
    height: number | undefined;
}

const SoldPropertiesPage = () => {
    const pageValues = useAppSelector(selectSoldPropertiesPageValues)
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState(true)
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        const reqData = utilityDataHelper.getSoldPropertiesReqData()
        dispatch(GetSoldPropertiesPageValues(reqData))
            .then(() => {
                dispatch(GetMarketingTemplateThunk('SOLD_PAGE_HTML'))
                    .then(() => {
                        setIsLoading(false)
                    })
            })
    }, [dispatch])

    useEffect(() => {
        document.title = pageValues.BOOKMARK_NAME;
    }, [pageValues]);

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []);



    const onValueChange = (value: string) => {
        const reqData = utilityDataHelper.getSoldPropertiesReqData()
        dispatch(GetSoldPropertiesPageValues({...reqData, lang: value}))
            .then(() => {
                dispatch(GetMarketingTemplateThunk('SOLD_PAGE_HTML'))
                    .then(() => {
                        const newLang = value
                        let path = window.location.href;
                        let segments = path.split('/');
                        segments[segments.length - 1] = segments[segments.length - 1].replace(/(-EN-|-CZ-)/, (match) => {
                            return match === '-EN-' ? '-CZ-' : '-EN-';
                        });
                        // Join back into a full path
                        path = segments.join('/');
                        window.history.pushState({}, '', path);
                        setIsLoading(false)
                    })
            })
    }

    return (
        isLoading
            ?
            <div style={{
                margin: '0',
                fontFamily: 'Arial, sans-serif',
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: 1.4,
                color: "#212529",
                textAlign: "left",
                padding: '0 15px'

            }}>Loading...</div>
            :
            <div className={s.inner}>
                <div style={{
                    marginTop: '20px',
                    marginBottom: '50px'
                }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%'
                    }}>
                        <p style={{
                            marginBottom: 0,
                            fontFamily: pageValues.COMPANY_ABBR_FONT,
                            fontSize: pageValues.COMPANY_ABBR_SIZE.replace("font-size:", "").trim(),
                            color: pageValues.COMPANY_ABBR_COLOR
                        }}
                           className={s.companyAbbr}>
                            {pageValues.COMPANY_ABBR}
                        </p>
                        <img
                            src={`data:image/png;base64, ${pageValues.COMPANY_LOGO}`}
                            alt="header_logo"
                            style={{
                                width: 'fit-content',
                                height: '37px',
                                objectFit: 'contain',
                                maxWidth: '554px',
                                position: 'absolute',
                                right: '10px'
                            }}
                            className={s.headerLogo}
                        />
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: '25px'
                    }}>
                        <select
                            onChange={(e) => onValueChange(e.target.value)}
                        >
                            <option value="CZ">čeština</option>
                            <option value="EN">english</option>
                        </select>
                    </div>
                </div>
                <div className={s.topLine}></div>
                <div className={s.saleBlock}>
                    <div>
                        <div style={{
                            position: 'absolute',
                            inset: 0,
                            backdropFilter: pageValues.PROPERTY_IMAGE && pageValues.PROPERTY_IMAGE !== null ? 'blur(5px)' : 'none',
                            WebkitBackdropFilter: pageValues.PROPERTY_IMAGE && pageValues.PROPERTY_IMAGE !== null ? 'blur(5px)' : 'none', // For Safari and older browsers
                            border: '1px solid #000'
                        }}>
                        </div>
                        {
                            pageValues.PROPERTY_IMAGE && pageValues.PROPERTY_IMAGE !== null
                                ?
                                <img
                                    src={`data:image/png;base64, ${pageValues.PROPERTY_IMAGE}`}
                                    alt="propertyLogo"
                                    style={{
                                        maxWidth: '1024px',
                                        width: '100%',
                                        height: '646px',
                                        objectFit: 'contain',
                                    }}
                                />
                                :
                                <img
                                    src={houseImg}
                                    alt="propertyLogo"
                                    style={{
                                        maxWidth: '1024px',
                                        width: '100%',
                                        height: '646px',
                                        objectFit: 'contain',
                                    }}
                                />
                        }

                    </div>
                    <div className={s.sale}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="305" height="305" viewBox="0 0 305 305"
                             fill="none">
                            <path d="M0 170.5V304.5L304.799 0H170L0 170.5Z" fill="#ED1C24"/>
                        </svg>
                        <p style={{
                            fontFamily: pageValues.BADGE_FONT,
                            fontSize: windowSize?.width!! > 700 ? pageValues.BADGE_SIZE.replace("font-size:", "").trim() : '40px',
                            color: pageValues.BADGE_COLOR,
                            right: windowSize?.width!! > 700
                                ? pageValues.BADGE_TEXT === 'Pronajatá' ? '45px' : pageValues.BADGE_TEXT === 'Rented' ? '75px' : pageValues.BADGE_TEXT === 'Sold' ? '110px' : '55px'
                                : pageValues.BADGE_TEXT === 'Pronajatá' ? '60px' : pageValues.BADGE_TEXT === 'Rented' ? '95px' : pageValues.BADGE_TEXT === 'Sold' ? '120px' : '71px',
                            top: windowSize?.width!! > 700
                                ? pageValues.BADGE_TEXT === 'Pronajatá' ? '95px' : '85px'
                                : pageValues.BADGE_TEXT === 'Pronajatá' ? '70px' : pageValues.BADGE_TEXT === 'Rented' ? '69px' : pageValues.BADGE_TEXT === 'Sold' ? '70px' : '66px',
                        }}>{pageValues.BADGE_TEXT}</p>
                    </div>
                </div>
                <div className={s.botLine}></div>
                <div
                    className={s.colorfulText}>
                    {
                        pageValues.PROP_DESCRIPTION !== null
                            ?
                            <p style={{
                                fontFamily: pageValues.PROP_DESCRIPTION_FONT,
                                fontSize: pageValues.PROP_DESCRIPTION_SIZE.replace("font-size:", "").trim(),
                                color: pageValues.PROP_DESCRIPTION_COLOR
                            }}>
                                {pageValues.PROP_DESCRIPTION}
                                <br/>
                                <span style={{
                                    fontFamily: pageValues.PROP_ADDRESS_FONT,
                                    fontSize: pageValues.PROP_ADDRESS_SIZE.replace("font-size:", "").trim(),
                                    color: pageValues.PROP_ADDRESS_COLOR
                                }}>{pageValues.PROP_ADDRESS}</span>
                            </p>
                            :
                            <span style={{
                                fontFamily: pageValues.PROP_ADDRESS_FONT,
                                fontSize: pageValues.PROP_ADDRESS_SIZE.replace("font-size:", "").trim(),
                                color: pageValues.PROP_ADDRESS_COLOR
                            }}>{pageValues.PROP_ADDRESS}</span>
                    }

                </div>
                <div>
                    <p className={s.mdText} style={{
                        fontFamily: pageValues.CONTACT_DESCRIPTION_FONT,
                        fontSize: pageValues.CONTACT_DESCRIPTION_SIZE.replace("font-size:", "").trim(),
                        color: pageValues.CONTACT_DESCRIPTION_COLOR,
                        marginBottom: '42px',
                        textAlign: 'start'
                    }}>
                        {pageValues.CONTACT_DESCRIPTION}
                    </p>
                </div>
                <div className={s.links}>
                    {
                        pageValues.EMAIL !== null
                        &&
                        <div className={s.links__link}>
                            <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="4.33203" y="4" width="48" height="48" rx="24" fill="#F2F4F7"/>
                                <rect x="4.33203" y="4" width="48" height="48" rx="24" stroke="#F9FAFB" strokeWidth="8"/>
                            </svg>
                            <img
                                src={mailImg}
                                alt="mail"
                                style={{
                                    width: '24px',
                                    height: '24px',
                                    position: 'absolute',
                                    top: '15px',
                                    mixBlendMode: 'darken'
                                }}
                            />
                            <p style={{
                                fontFamily: pageValues.CONTACT_TITLE_FONT,
                                fontSize: pageValues.CONTACT_TITLE_SIZE.replace("font-size:", "").trim(),
                                color: pageValues.CONTACT_TITLE_COLOR
                            }}>{pageValues.EMAIL_TITLE}</p>
                            <a href={`mailto:${pageValues.EMAIL}`} style={{
                                fontFamily: pageValues.CONTACT_SIZE,
                                fontSize: pageValues.CONTACT_FONT.replace("font-size:", "").trim(),
                                color: pageValues.CONTACT_COLOR
                            }}>{pageValues.EMAIL}</a>
                        </div>
                    }
                    <div className={s.links__link}>
                        <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="4.33203" y="4" width="48" height="48" rx="24" fill="#F2F4F7"/>
                            <rect x="4.33203" y="4" width="48" height="48" rx="24" stroke="#F9FAFB" strokeWidth="8"/>
                        </svg>
                        <img
                            src={locationImg}
                            alt="location"
                            style={{
                                width: '24px',
                                height: '24px',
                                position: 'absolute',
                                top: '15px',
                                mixBlendMode: 'darken'
                            }}
                        />
                        <p style={{
                            fontFamily: pageValues.CONTACT_TITLE_FONT,
                            fontSize: pageValues.CONTACT_TITLE_SIZE.replace("font-size:", "").trim(),
                            color: pageValues.CONTACT_TITLE_COLOR
                        }}>{pageValues.OFFICE_TITLE}</p>
                        <a href="https://www.google.com/maps/search/?api=1&query=Vancouverstra%C3%9Fe+2a,+20457+Hamburg"
                           style={{
                               fontFamily: pageValues.CONTACT_SIZE,
                               fontSize: pageValues.CONTACT_FONT.replace("font-size:", "").trim(),
                               color: pageValues.CONTACT_COLOR
                           }}>{pageValues.OFFICE}</a>
                    </div>
                    <div className={s.links__link}>
                        <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="4.33203" y="4" width="48" height="48" rx="24" fill="#F2F4F7"/>
                            <rect x="4.33203" y="4" width="48" height="48" rx="24" stroke="#F9FAFB" strokeWidth="8"/>
                        </svg>
                        <img
                            src={phoneImg}
                            alt="phone"
                            style={{
                                width: '24px',
                                height: '24px',
                                position: 'absolute',
                                top: '15px',
                                mixBlendMode: 'darken'
                            }}
                        />
                        <p style={{
                            fontFamily: pageValues.CONTACT_TITLE_FONT,
                            fontSize: pageValues.CONTACT_TITLE_SIZE.replace("font-size:", "").trim(),
                            color: pageValues.CONTACT_TITLE_COLOR
                        }}>{pageValues.PHONE_TITLE}</p>
                        <a href="tel:+4940361310" style={{
                            fontFamily: pageValues.CONTACT_SIZE,
                            fontSize: pageValues.CONTACT_FONT.replace("font-size:", "").trim(),
                            color: pageValues.CONTACT_COLOR
                        }}>{pageValues.PHONE}</a>
                    </div>
                </div>

            </div>
    );
};


export default SoldPropertiesPage;





