import React, {useEffect, useRef, useState} from 'react';
import PageWithSidebar from "../../PageWithSidebar/PageWithSidebar";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    GetRRSurfacesGridData, onAddSurfaceToChecked,
    onChangeRRSurfacesGridColumnsPosition,
    onChangeRRSurfacesGridColumnsWidth,
    onSetCurrentSurfaceForEdit,
    onSetFiltersForRRSurfacesGrid,
    onSetFiltersForSurfacesGrid,
    onSetOfferCreationModalOpened,
    onSetRRSurfaces,
    onSetSurfacesLocalSortModel,
    onSurfacesResetFiltersButtonsSelected, selectCheckedSurfacesForOffer,
    selectIsSurfacesDataLoading, selectSurfaceOfferModalMode,
    selectSurfacesDefaultGridRows,
    selectSurfacesGridColumns, selectSurfacesGridColumnsForFilters,
    selectSurfacesGridFilters,
    selectSurfacesGridRows,
    selectSurfacesGridSortModel,
    selectSurfacesHiddenColumns,
    selectSurfacesShownColumns,
    setRRSurfacesGridColumnsForFilters,
    setSurfacesHiddenColumns,
    setSurfacesShownColumns,
    SurfaceRowType
} from "../../../store/surfacesReducer";
import {selectUserData} from "../../../store/userReducer";
import {DataGridPro, useGridApiRef} from "@mui/x-data-grid-pro";
import {Lottie} from "@crello/react-lottie";
import animatedLoader from "../../../img/Loader.json";
import Box from "@mui/material/Box";
import PropertiesViewOptions from "../../PropertiesCommonComponents/PropertiesViewOptions/PropertiesViewOptions";
import s from "./SurfaceGrid.module.scss";
import {Checkbox, Select, Spin} from "antd";
import {InputLabel, Pagination} from "@mui/material";
import {selectCurrentScreenWidth} from "../../../store/utilityReducer";
import {getShownAndHiddenColumns} from "../../../commonGridFunctions/getShownAndHiddenColumns";
import {GridColumns, MuiGridDataColumns} from "../../../types/commonTypes";
import {customSortFunction} from "../../../helpers/sortingDataHelper";
import {propertiesApi, requirementsApi, templatesApi} from "../../../app/api";
import {utilityDataHelper} from "../../../helpers/localStorageHelper";
import {useNavigate} from "react-router";
import {LoadingOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import PropertiesHeaderButtons from "../../PropertiesCommonComponents/PropertiesHeaderButtons/PropertiesHeaderButtons";
import {saveGridConfigs} from "../../../commonGridFunctions/saveGridConfigs";
import {resetGridConfigs} from "../../../commonGridFunctions/resetGridConfigs";
import searchIcon from "../../../img/icons/searchTable.svg";
import PropertiesFilterButtons from "../../PropertiesCommonComponents/PropertiesFilterButtons/PropertiesFilterButtons";
import {
    deleteSurfaceForEmail,
    GetPropertiesContactLang,
    GetPropertiesEmailBody,
    GetPropertiesEmailLinkText, GetPropertiesEmailPhotosForLinks,
    GetPropertiesGridContacts,
    onAddCheckedSurfaces,
    onClearPropertyActivities,
    selectContactsForEmail,
    selectPropertyContactLang, selectSurfacesForEmail,
    ShownAndHiddenColumnsType
} from "../../../store/propertiesReducer";
import {sessionStorageGridFilters, sessionStorageUtilityValues} from "../../../helpers/sessionStorageHelper";
import {gridFiltering} from "../../../customFunctions/gridFiltering";
import PropertyFilterModal from "../../PropertiesPage/PropertyFilterModal/PropertyFilterModal";
import {
    GetDefaultRequirementForMapOfferThunk,
    GetMapPropertiesStatusesThunk, selectDefaultReqForLinks,
    selectMapPropertiesStatuses
} from "../../../store/propertiesMap";
import Tooltip from "../../Tooltip/Tooltip";
import plusImg from "../../../img/icons/plus.svg";
import PropertiesOfferModal from "../../PropertiesPage/PropertiesOfferModal/PropertiesOfferModal";
import {onCheckSurfaces, onCheckSurfacesIds, onSetCurrentModalTab} from "../../../store/activitesGrid";


const SurfaceGrid = () => {
    return (
        <PageWithSidebar>
            <SurfaceGridComponent/>
        </PageWithSidebar>
    )
}

const SurfaceGridComponent = () => {
    const apiRef = useGridApiRef();
    const dispatch = useAppDispatch()
    const userData = useAppSelector(selectUserData)
    const [height, setHeight] = useState(window.innerHeight);
    const isSurfacesDataLoading = useAppSelector(selectIsSurfacesDataLoading)
    const [gridContentHeight, setGridContentHeight] = useState(height - 340);
    const [checkedViewOptions, setCheckedViewOptions] = useState<string[]>([]);
    const [filtersShown, setFiltersShown] = useState(false)
    const currentScreenWidth = useAppSelector(selectCurrentScreenWidth)
    const gridColumns = useAppSelector(selectSurfacesGridColumns)
    const shownColumns = useAppSelector(selectSurfacesShownColumns)
    const hiddenColumns = useAppSelector(selectSurfacesHiddenColumns)
    const surfacesGridRows = useAppSelector(selectSurfacesGridRows)
    const sortModel = useAppSelector(selectSurfacesGridSortModel)
    const [searchTerm, setSearchTerm] = useState('');
    const rowsPerPageOptions = [100, 150, 200];
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
    const [localSortModel, setLocalSortModel] = useState<any>({});
    const gridFilters = useAppSelector(selectSurfacesGridFilters)
    const defaultGridRows = useAppSelector(selectSurfacesDefaultGridRows)
    const [currentStatus, setCurrentStatus] = useState<number | 'All'>(1)
    const mapStatuses = useAppSelector(selectMapPropertiesStatuses)
    const navigate = useNavigate()
    const columnsForFilters = useAppSelector(selectSurfacesGridColumnsForFilters)
    // eslint-disable-next-line
    const threeMonthsAgo = new Date(); // Get today's date
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3); // Set three months ago
    // eslint-disable-next-line
    const today = new Date(); // Get today's date
    const offerModalMode = useAppSelector(selectSurfaceOfferModalMode)
    const divRef = useRef(null);
    const contactLang = useAppSelector(selectPropertyContactLang)
    const defaultReq = useAppSelector(selectDefaultReqForLinks)
    const contactsForEmail = useAppSelector(selectContactsForEmail)

    useEffect(() => {
        dispatch(GetRRSurfacesGridData({status: 1, sortOrder: null, p_agent_ref: Number(userData.user_ref)}))
    }, [dispatch, userData])

    useEffect(() => {
        dispatch(GetMapPropertiesStatusesThunk())
    }, [dispatch])


    let columns = gridColumns.map((g: GridColumns) => {
        if (g.COL_NAME === 'Actions') {
            return {
                field: g.COL_NAME,
                headerName: g.COL_TITLE,
                width: 100,
                dataType: g.COL_TYPE,
                col_pos: 7,
                col_value: g.COL_VALUE,
                col_options: g.COL_OPTIONS,
                withSurface: g.withSurface
            }
        } else {
            return {
                field: g.COL_NAME,
                headerName: g.COL_TITLE,
                width: g.COL_WIDTH,
                dataType: g.COL_TYPE,
                col_pos: g.COL_POS,
                col_value: g.COL_VALUE,
                col_options: g.COL_OPTIONS,
            }
        }
    })

    useEffect(() => {

        dispatch(setSurfacesShownColumns({
            columns: getShownAndHiddenColumns(columns as MuiGridDataColumns[], 'Shown')
        }))
        dispatch(setSurfacesHiddenColumns({
            columns: getShownAndHiddenColumns(columns as MuiGridDataColumns[], 'Hidden')
        }))

        setCheckedViewOptions(columns.filter((c: any) => c?.width === 0).map((c: any) => c.field))
        // eslint-disable-next-line
    }, [dispatch, gridColumns])


    const [page, setPage] = React.useState(1);

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    }

    useEffect(() => {

        const filterColumnsFromSessionStorage = sessionStorageGridFilters.getRRSurfacesGridFilters()
        if (filterColumnsFromSessionStorage && filterColumnsFromSessionStorage.length) {
            dispatch(setRRSurfacesGridColumnsForFilters({
                columns: gridColumns.map((g: GridColumns) => {

                    return {
                        title: g.COL_NAME,
                        headerName: g.COL_TITLE ?? '',
                        value: null,
                        condition: g.COL_TYPE === "class java.lang.String" ? 'LIKE' : '=',
                        dataType: g.COL_TYPE,
                        isModalBtnOpened: false,
                        isFilterSelected: false,
                        filterable: false,
                    }
                })
            }))
            dispatch(onSetFiltersForRRSurfacesGrid({gridFilters: filterColumnsFromSessionStorage}))
        }
        dispatch(setRRSurfacesGridColumnsForFilters({
            columns: gridColumns.map((g: GridColumns) => {
                return {
                    title: g.COL_NAME,
                    headerName: g.COL_TITLE ?? '',
                    value: null,
                    condition: g.COL_TYPE === "class java.lang.String" ? 'LIKE' : '=',
                    dataType: g.COL_TYPE,
                    isModalBtnOpened: false,
                    isFilterSelected: false,
                    filterable: false,
                }
            })
        }))

    }, [dispatch, gridColumns])

    useEffect(() => {

        const filterColumnsFromSessionStorage = sessionStorageGridFilters.getRRSurfacesGridFilters()
        if (filterColumnsFromSessionStorage) {
            gridFiltering(filterColumnsFromSessionStorage, defaultGridRows, columnsForFilters, 'SurfacesRR', dispatch)
        }

        // eslint-disable-next-line
    }, [dispatch, defaultGridRows])

    const rows = customSortFunction(surfacesGridRows, sortModel)
        .map((item: SurfaceRowType) => {
            const {...rest} = item;
            return rest;
        })
        .filter((row: any) => {
            const entries = Object.entries(row);
            const useStartsWith = searchTerm.endsWith('%');
            const processedSearchTerm = (useStartsWith ? searchTerm.slice(0, -1) : searchTerm).toLowerCase();

            return entries.some(([key, value]) => {
                if (key !== 'MODIFIED_BY') {
                    const stringValue = String(value).toLowerCase();
                    return useStartsWith ? stringValue.startsWith(processedSearchTerm) : stringValue.includes(processedSearchTerm);
                }
                return false;
            });
        });

    const paginatedRows = rows.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    const selectMapStatuses = mapStatuses.map((status: any) => ({
        value: `${status.AE_NO_INDEX} `,
        label: `${status.AE_LI_VALUE}`,
    }))

    function handleRowsPerPageChange(event: any) {
        setRowsPerPage(event)

    }

    const handleStateChange = (params: any) => {
        dispatch(onChangeRRSurfacesGridColumnsPosition({columns: params.columns.all}))
        dispatch(onChangeRRSurfacesGridColumnsWidth({columns: params.columns.lookup}))

    };

    const handleSortModelChange = (newModel: any) => {
        const newLocalModel = {...localSortModel};
        newModel.forEach((model: any) => {
            newLocalModel[model.field] = model;
        });
        setLocalSortModel(newLocalModel);
        const mergedArray = [...sortModel, ...newModel];

        // Find the index of the matching field object in the merged array
        const index = mergedArray.findIndex(item => item?.field === sortModel[0]?.field);

        // Check if the sort value is different and update it
        if (index !== -1 && mergedArray[index]?.sort !== newModel[0]?.sort) {
            // eslint-disable-next-line
            const updatedObject = {
                ...mergedArray[index],
                sort: newModel[0]?.sort
            };
            mergedArray[index] = updatedObject;
        }


        dispatch(onSetSurfacesLocalSortModel({sort_model: mergedArray}));
    };

    useEffect(() => {
        const sortModelObject: any = {};
        for (const item of sortModel) {
            sortModelObject[item.field] = {
                field: item.field,
                sort: item.sort
            };
        }
        setLocalSortModel(sortModelObject)
    }, [sortModel])


    const onSaveGridConfigs = () => {
        saveGridConfigs(shownColumns, hiddenColumns, gridColumns.filter((c) => c.COL_NAME !== 'ACTIONS'), localSortModel, userData, threeMonthsAgo, today, 'SurfacesRR', dispatch)
    }
    const onResetConfigs = () => {
        resetGridConfigs(dispatch, userData, threeMonthsAgo, today, setSearchTerm, setLocalSortModel, "SurfacesRR")
    }
    const onSetFiltersShown = () => {
        setFiltersShown(!filtersShown)
    }

    const handleFiltersShownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation(); // Prevent event bubbling to document click event listener
        onSetFiltersShown();
    };

    const handleSearchInputChange = (event: any) => {
        setSearchTerm(event.target.value);
    };

    const onResetFilters = () => {
        dispatch(onSetRRSurfaces({gridRows: defaultGridRows}))
        const resetShownColumns = shownColumns.map((c: ShownAndHiddenColumnsType) => c && {
            ...c,
            isFilterSelected: false,
            isModalBtnOpened: false
        })
        dispatch(setSurfacesShownColumns({columns: resetShownColumns}))
        dispatch(onSurfacesResetFiltersButtonsSelected())
        dispatch(onSetFiltersForSurfacesGrid({gridFilters: []}))
        sessionStorageGridFilters.removeRRSurfacesGridFilters()
    }

    const onResetSorting = () => {
        dispatch(onSetSurfacesLocalSortModel({sort_model: []}))
        setLocalSortModel({})

    }


    const handleStatusChange = (value: string) => {
        setCurrentStatus(Number(value))
        dispatch(GetRRSurfacesGridData({
            status: Number(value),
            sortOrder: null,
            p_agent_ref: Number(userData.user_ref)
        }))
    }

    const onOpenOfferModal = () => {
        dispatch(onSetOfferCreationModalOpened('Opened'))
    }

    const onHideModal = () => {
        dispatch(onSetOfferCreationModalOpened('Hided'))
    }

    const unHideModal = () => {
        dispatch(onSetOfferCreationModalOpened('Opened'))
    }


    useEffect(() => {
        dispatch(GetDefaultRequirementForMapOfferThunk('RR'))
            .then((res) => {
                // @ts-ignore
                dispatch(GetPropertiesContactLang(res.payload))
                    .then((res) => {
                        // @ts-ignore
                        dispatch(GetPropertiesEmailBody({req_id: 0, gridType: 'All Properties/Upcoming', lang: res.payload, domain: 'RR'}))
                    })

            })
    }, [dispatch])

    useEffect(() => {
        if (!contactsForEmail.length) {
            dispatch(GetPropertiesGridContacts())
        }
    }, [dispatch, contactsForEmail])


    return (
        <div className={s.inner}>
            <div className={s.tableInner}>
                <div className={s.tableInner__header}>
                    <h1 className={s.tableTitle}>Surfaces</h1>
                    <div
                        style={{
                            display: 'flex'
                        }}
                        className={s.tableInner__headerButtons}
                    >
                        {
                            currentScreenWidth! > 768
                            &&
                            <PropertiesHeaderButtons
                                onSetFiltersShown={handleFiltersShownClick}
                                onResetConfigs={onResetConfigs}
                                onSaveGridConfigs={onSaveGridConfigs}
                                // isDisabledSaveBtn={!shownColumns.length}
                                // disabledBtns={!shownColumns.length}
                                gridType={'SurfacesRR'}
                                resetFilters={() => {
                                }}
                            />
                        }
                        <button
                            className={s.addActivityBtn}
                            onClick={onOpenOfferModal}
                            style={{
                                marginLeft: '8px',
                                minWidth: currentScreenWidth!! > 450 ? '121px' : '100px',
                                marginRight: '0px'
                            }}
                            // disabled={!loadedRows.length}
                        >
                            <img
                                src={plusImg}
                                alt={'plus'}
                                style={{
                                    marginRight: '8px',
                                }}
                            />
                            Offer
                        </button>
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '8px',
                    marginLeft: '17px'
                }}>
                    <InputLabel htmlFor="searchInput" style={{marginRight: '8px'}}>
                        <img src={searchIcon} alt="search"/>
                    </InputLabel>
                    <input
                        className={s.search_input}
                        type="text"
                        value={searchTerm}
                        placeholder={'Search by Prop_ID, Surf_ID, Addr street ...'}
                        onChange={handleSearchInputChange}
                    />

                    <div>
                        {searchTerm !== ''
                            &&
                            <p
                                onClick={() => setSearchTerm('')}
                                style={{
                                    marginBottom: '0',
                                    fontSize: '12px',
                                    color: '#9CA3AF',
                                    cursor: "pointer"
                                }}>x</p>
                        }
                    </div>
                </div>
                <div style={{
                    marginLeft: '16px',
                    marginBottom: '10px',
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center'
                }}
                >
                    <Select
                        style={{width: 105, marginRight: '12px'}}
                        className={'mapSelect'}
                        placeholder={'Status'}
                        suffixIcon={<svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M6.30026 8.3L3.70026 5.7C3.38359 5.38333 3.31292 5.021 3.48826 4.613C3.66292 4.20433 3.97526 4 4.42526 4H9.57526C10.0253 4 10.3376 4.20433 10.5123 4.613C10.6876 5.021 10.6169 5.38333 10.3003 5.7L7.70026 8.3C7.60026 8.4 7.49192 8.475 7.37526 8.525C7.25859 8.575 7.13359 8.6 7.00026 8.6C6.86692 8.6 6.74192 8.575 6.62526 8.525C6.50859 8.475 6.40026 8.4 6.30026 8.3Z"
                                fill={'#191559'}/>
                        </svg>}
                        onChange={handleStatusChange}
                        defaultValue={'Active'}
                        options={selectMapStatuses}
                    />
                    <Tooltip
                        text="Use this select to change surfaces status. Note that offer can be created only with surfaces with active status."
                        classname={'rrSurfacesTooltip'}>
                        <QuestionCircleOutlined/>
                    </Tooltip>
                </div>
                <div style={{
                    padding: '0 16px'
                }}>
                    {
                        currentScreenWidth! > 768
                        &&
                        <div className={s.filterButtons}>
                            <PropertiesFilterButtons
                                shownColumns={shownColumns}
                                onResetFilters={onResetFilters}
                                onResetSorting={onResetSorting}
                                gridType={'SurfacesRR'}
                            />
                        </div>
                    }
                </div>
                {
                    currentScreenWidth! > 768
                    &&
                    <div style={{
                        position: 'relative',
                        width: '100%',
                    }}>
                        {shownColumns.map((c: any) => {
                            return (
                                <PropertyFilterModal
                                    gridType={'SurfacesRR'}
                                    textValue={c.field}
                                    isMobile={false}
                                />
                            )
                        })}
                    </div>
                }
                {
                    isSurfacesDataLoading
                        ?
                        <div style={{
                            height: gridContentHeight + 115,
                            width: '400px',
                            margin: '0 auto',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}>
                            <div style={{
                                height: '300px',
                                width: '100%',
                                textAlign: 'center'
                            }}>
                                <Lottie
                                    // @ts-ignore
                                    config={{animationData: animatedLoader, loop: true, height: '60px !important'}}
                                />
                            </div>

                            <p style={{
                                textAlign: 'center'
                            }}>Loading</p>
                        </div>
                        :
                        <Box sx={{position: 'relative', width: '100%', padding: '0 10px'}}>
                            <>
                                <PropertiesViewOptions
                                    checked={checkedViewOptions}
                                    filtersShown={filtersShown}
                                    setChecked={(checkedArray: string[]) => setCheckedViewOptions(checkedArray)}
                                    setFilterShown={(isShown: boolean) => setFiltersShown(isShown)}
                                    gridType={'SurfacesRR'}
                                />
                                <DataGridPro
                                    sx={{
                                        borderRight: 'none',
                                        borderLeft: 'none',
                                        borderBottom: 'none',
                                        borderRadius: '0px',
                                        "& .MuiDataGrid-cellContent": {
                                            fontFamily: 'Inter, sans-serif',
                                            fontWeight: 500,
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            color: '#111827'
                                        },
                                        "& .MuiDataGrid-columnHeaderTitle": {
                                            fontFamily: 'Inter, sans-serif',
                                            fontWeight: 600,
                                            color: '#6B7280',
                                            fontSize: '12px',
                                            lineHeight: '20px',
                                        },
                                        "& .MuiDataGrid-row:hover": {
                                            backgroundColor: 'transparent'
                                        },
                                        "& .MuiDataGrid-row.Mui-selected": {
                                            backgroundColor: 'transparent'
                                        },
                                        "& .MuiDataGrid-row.Mui-selected:hover": {
                                            backgroundColor: 'transparent'
                                        },
                                        "& .MuiDataGrid-cell:focus": {
                                            outline: 'none'
                                        },
                                        "& .MuiDataGrid-toolbarContainer": {
                                            color: '#454545',
                                            position: 'absolute',
                                            top: '-50px',
                                            right: '0px'
                                        },
                                        "& .MuiTablePagination-displayedRows": {
                                            margin: '0',
                                            fontFamily: 'Inter, sans-serif !important',
                                            fontWeight: 400,
                                            color: '#6B7280',
                                            fontSize: '12px',
                                            lineHeight: '20px',
                                        },
                                        "& .MuiPagination-root": {
                                            display: 'flex',
                                            justifyContent: 'center',
                                            padding: '7px 0px'
                                        },
                                        "& .MuiButtonBase-root.Mui-selected": {
                                            backgroundColor: '#F9FAFB',
                                            borderRadius: '0px'
                                        },
                                        "& .MuiButtonBase-root.MuiPaginationItem-root": {
                                            width: '40px',
                                            height: '40px',
                                            margin: 0,
                                            borderRight: '1px solid #D0D5DD',
                                            borderLeft: '1px solid #D0D5DD',
                                            borderTop: 'none',
                                            borderBottom: 'none',
                                            fontFamily: 'Inter, sans-serif !important',
                                            fontWeight: 500,
                                            color: '#1D2939',
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            borderRadius: '0px'
                                        },
                                        "& .MuiPagination-ul": {
                                            border: '1px solid #D0D5DD',
                                            borderRadius: '8px'
                                        },
                                        "& .MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-previousNext": {
                                            borderRadius: '0px',
                                            border: 'none'
                                        },
                                        "& .MuiDataGrid-virtualScroller": {
                                            overflowY: 'scroll !important',
                                            height: currentScreenWidth!! < 480 ? `${gridContentHeight - 60}px !important` : `${gridContentHeight - 80}px !important`,
                                            minHeight: currentScreenWidth!! < 480 ? '300px' : 'initial'
                                        },
                                        "& .MuiDataGrid-columnHeaders": {
                                            backgroundColor: '#FCFDFE'
                                        },
                                        "& .MuiDataGrid-cell": {
                                            borderBottom: 'none'
                                        },
                                        "& .MuiDataGrid-columnHeader--moving": {
                                            backgroundColor: 'transparent'
                                        },
                                        "& .MuiDataGrid-pinnedColumnHeaders--right": {
                                            paddingRight: currentScreenWidth!! > 600 ? '17px !important' : '0px !important'
                                        },
                                        "& .MuiDataGrid-row:nth-of-type(odd)": {
                                            backgroundColor: '#F9FAFB',
                                            borderTop: '1px solid #E5E7EB',
                                            borderBottom: '1px solid #E5E7EB'
                                        },
                                    }}
                                    apiRef={apiRef}
                                    getRowId={e => e.SURF_REF!!}
                                    columns={shownColumns.filter((col: any) => col?.width !== 0).map((column: any, index: number) => ({
                                        ...column,
                                        renderCell: (params: any) =>
                                            <CustomCell field={column.field}
                                                        value={params.value}
                                                        params={params}
                                                        key={index}
                                            />,
                                        renderHeader: (params: any) => {
                                            return (
                                                <div style={{
                                                    display: 'flex',
                                                }}>
                                                        <span
                                                            className={s.columnHeader}>
                                                            {params?.colDef?.headerName === 'ACTIVITY_EXISTED'
                                                                ? 'ACTIVITY'.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase())
                                                                : params?.colDef?.headerName?.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase())}
                                                        </span>
                                                </div>
                                            );
                                        },
                                    }))}
                                    rows={paginatedRows}
                                    {...paginatedRows}
                                    headerHeight={39}
                                    onStateChange={handleStateChange}
                                    hideFooterSelectedRowCount
                                    onSortModelChange={handleSortModelChange}
                                    sortingOrder={['desc', 'asc']}
                                    sortModel={Object.values(localSortModel)}
                                    initialState={{pinnedColumns: {left: [], right: ['VIEW_ACTION', "EDIT_ACTION"]}}}
                                    autoPageSize={true}
                                    rowHeight={40}
                                    autoHeight={true}
                                    components={{
                                        Footer: () =>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: currentScreenWidth! > 768 ? 'space-between' : 'center',
                                                alignItems: 'center',
                                                width: '100%'
                                            }}>
                                                {
                                                    currentScreenWidth! > 768
                                                    &&
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center'
                                                        }}>
                                                        <p
                                                            className={s.footerText}
                                                        >
                                                            Rows per page:
                                                        </p>
                                                        <Select
                                                            value={rowsPerPage}
                                                            onChange={handleRowsPerPageChange}
                                                            className={s.rowsPerPage}
                                                        >
                                                            {rowsPerPageOptions.map((option) => (
                                                                <Select.Option key={option} value={option}>
                                                                    {option}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </div>
                                                }
                                                <Pagination
                                                    count={Math.ceil(gridFilters.length ? (rows.length / rowsPerPage) : (defaultGridRows.length / rowsPerPage))}
                                                    page={page}
                                                    onChange={handlePageChange}
                                                    variant="outlined"
                                                    shape="rounded"
                                                />
                                                {
                                                    currentScreenWidth! > 768
                                                    &&
                                                    <p className={s.footerText}>
                                                        Total{gridFilters.length ? '/Filtered' : ''} surfaces: {gridFilters.length ? defaultGridRows.length : rows.length}{gridFilters.length ? `/${rows.length}` : ''}
                                                    </p>
                                                }
                                            </div>
                                    }}
                                />
                            </>

                        </Box>


                }
            </div>
            <PropertiesOfferModal
                height={height}
                hiddenModal={false}
                gridContentHeight={gridContentHeight}
                onHideModal={onHideModal}
                unHideModal={unHideModal}
                divRef={divRef}
                gridType={'RRSurfaces'}
                contactLang={contactLang}
                rowReqId={defaultReq}
                isModalOpened={offerModalMode === 'Opened'}
                isModalHided={offerModalMode === 'Hided'}
            />
        </div>
    );
};

const CustomCell = React.memo(({
                                   value,
                                   field,
                                   currentGrid,
                                   params
                               }: any) => {
    const defaultGridRows = useAppSelector(selectSurfacesDefaultGridRows)
    const currentSurface = defaultGridRows.find((p: any) => Number(p.SURF_REF) === Number(params.row.SURF_REF))
    const [isSurfaceLayoutLoading, setIsSurfaceLayoutLoading] = useState(false)
    const propertiesLinkStart = window.env.OFFER_PROPERTIES_EXTERNAL_ADDR
    const userData = useAppSelector(selectUserData)
    const offerModalMode = useAppSelector(selectSurfaceOfferModalMode)
    const dispatch = useAppDispatch()
    const checkedSurfaces = useAppSelector(selectSurfacesForEmail)
    const defaultReq = useAppSelector(selectDefaultReqForLinks)
    const contactLang = useAppSelector(selectPropertyContactLang)
    const navigate = useNavigate()

    const onGetCurrentRow = (row: SurfaceRowType) => {
        // https://webexpostage.primoportal.com/#/s/51095-22143-136116-EN-RR
        onGetLinkForTemplate(`${propertiesLinkStart}/#/s/0-${row.PROP_REF}-${row.SURF_REF}-EN-RR`)
    }

    const onGetLinkForTemplate = async (url: string) => {
        setIsSurfaceLayoutLoading(true)
        try {
            const linkRes = await templatesApi.getLinkForRRSurfacesTemplate({
                url: url,
                user_ref: Number(userData.user_ref),
                is_quick_view: true
            });


            const checkRes = await requirementsApi.checkSoldPropertyUrl(linkRes.data.message);


            const {langCd, domain, reqRef, propRef, surfRef} = checkRes.data.offerLink;


            const [templateDataRes, imagesRes] = await Promise.all([
                templatesApi.getRRSurfacesTemplateData({
                    lang: langCd,
                    domain: domain,
                    row_req: reqRef,
                    row_prop: propRef,
                    row_surf: surfRef,
                }),
                propertiesApi.getRRSurfacesTemplateImages(surfRef)
            ]);


            utilityDataHelper.setRRSurfaceTemplateData(templateDataRes.data);
            utilityDataHelper.setRRSurfaceTemplateImages(imagesRes.data);
            utilityDataHelper.setRRSurfaceTemplateDataForRequest({
                lang: langCd,
                domain: domain,
                row_req: reqRef,
                row_prop: propRef,
                row_surf: surfRef,
            })
            setIsSurfaceLayoutLoading(false)
            window.open(`/surfaces/detailed-view/${surfRef}`, '_blank');
        } catch (error) {
            setIsSurfaceLayoutLoading(false)
        }
    };



    const handleCheckSurfaces = (value: any) => {
        dispatch(onClearPropertyActivities())
        dispatch(onSetCurrentModalTab('Existing Links'))
        // setDisabledCheckbox(true)
        if(checkedSurfaces.length === 0){
            dispatch(GetPropertiesEmailBody({
                req_id: Number(defaultReq!!),
                gridType: 'All Properties/Upcoming',
                lang: contactLang,
                domain: 'RR'
            }))
        }
        if (value === true) {
            dispatch(onAddCheckedSurfaces({surface: Number(params.row.SURF_REF), isChecked: value}))
            // if (!isCreateActivityModalOpened) {
            dispatch(GetPropertiesEmailLinkText({
                prop_id: params.row.PROP_REF,
                req_id: defaultReq!!,
                propertyType: 'Surface',
                surfaceId: params.row.SURF_REF,
                lang: contactLang,
                gridType: 'Properties',
                currentDomain: 'RR'
            }))


            dispatch(GetPropertiesEmailPhotosForLinks({
                    p_fk_type: 'Surface',
                    p_row_fk: Number(params.row.SURF_REF),
                    p_row_req: defaultReq!!,
                    prop_id: params.row.PROP_REF,
                    gridType: 'Properties',
                    surfaceId: params.row.SURF_REF,
                }))


            dispatch(onCheckSurfacesIds({
                surfaceId: Number(params.row.SURF_REF),
                isChecked: value
            }))
            dispatch(onCheckSurfaces({surface: params.row, isChecked: value}))
            // dispatch(onCheckActivitiesPropertiesIds({property: value.target.name, isChecked: value.target.checked}))
            // dispatch(onCheckActivitiesProperties({property: props.row, isChecked: value.target.checked}))
        } else {
            dispatch(onAddCheckedSurfaces({surface: Number(params.row.SURF_REF), isChecked: value}))
            dispatch(deleteSurfaceForEmail(Number(params.row.SURF_REF)))
            dispatch(onCheckSurfacesIds({
                surfaceId: Number(params.row.SURF_REF),
                isChecked: value
            }))
            dispatch(onCheckSurfaces({surface: params.row, isChecked: value}))
            // dispatch(onCheckActivitiesPropertiesIds({property: value.target.name, isChecked: value.target.checked}))
            // dispatch(onCheckActivitiesProperties({property: props.row, isChecked: value.target.checked}))
        }
        // setTimeout(() => {
        //     setDisabledCheckbox(false)
        // }, 50)
    }

    const onEditCurrentSurface = () => {
        dispatch(onSetCurrentSurfaceForEdit(params.row))
        // onPropertyEdit(params.row.PROP_ID)
        sessionStorageUtilityValues.setCurrentSurfacesForEdit(params.row)
        utilityDataHelper.setEditPicturesMode('Surfaces')
        navigate(`/surfaces/${params.row.SURF_REF}`)
    }

    if (field === 'VIEW_ACTION') {
        return <button
            className={s.editReq}
        >
            {
                isSurfaceLayoutLoading
                    ?
                    <Spin indicator={<LoadingOutlined spin/>} size="small"/>
                    :
                    <>
                        <span onClick={() => onGetCurrentRow(params.row)}>View</span>
                    </>

            }

        </button>
    }
    else if(field === 'EDIT_ACTION'){
        return <button
            className={s.editReq}
        >
            {
                isSurfaceLayoutLoading
                    ?
                    <Spin indicator={<LoadingOutlined spin/>} size="small"/>
                    :
                    <>
                        <span onClick={() => onEditCurrentSurface()}>Edit</span>
                    </>

            }

        </button>
    }
    else if(field === 'SURF_REF'){
        return (
            <div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center',
                }}>
                    <div style={{
                        display: 'flex',
                        gap: '8px'
                    }}>
                        {
                            offerModalMode === 'Opened'
                                ?
                                // params.row.STATUS_SURF !== 'active'
                                // ?
                                //     <Tooltip
                                //         text={`You can select only properties with Active status`}
                                //         classname={'propertiesMapTooltip'}>
                                //         <Checkbox
                                //             onChange={(e) => handleCheckSurfaces(e.target.checked)}
                                //             name={value}
                                //             checked={checkedSurfaces.some((s: any) => Number(s.surfaceId) === Number(params.row.SURF_REF))}
                                //             // disabled={disabledCheckbox || (isCreateActivityModalOpened && checkedProperties.some(
                                //             //     (property: any) => property.propId === value && property.contactId !== params.row.PE_REF
                                //             // ))}
                                //             disabled={params.row.STATUS_SURF !== 'active'}
                                //             style={{
                                //                 marginRight: '8px'
                                //             }}
                                //         />
                                //     </Tooltip>
                                //     :
                                <Checkbox
                                    onChange={(e) => handleCheckSurfaces(e.target.checked)}
                                    name={value}
                                    checked={checkedSurfaces.some((s: any) => Number(s.surfaceId) === Number(params.row.SURF_REF))}
                                    // disabled={disabledCheckbox || (isCreateActivityModalOpened && checkedProperties.some(
                                    //     (property: any) => property.propId === value && property.contactId !== params.row.PE_REF
                                    // ))}
                                    disabled={params.row.STATUS_SURF !== 'active'}
                                    style={{
                                        marginRight: '8px'
                                    }}
                                />
                                :
                                    null
                        }
                        <span>{value}</span>
                    </div>
                </div>
            </div>

        )
    }
    else {
        return (

            <div>{value}</div>
        )
    }


});


export default SurfaceGrid;