import React, {useEffect, useState} from 'react';
import s from "../../EditProperty.module.scss";
import {Empty, message, Modal, Upload} from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
    BrokeragePropertyPicturesTabData,
    DeleteBrokeragePropertyPicture, GetBrokeragePropertyPicturesByRef,
    onSetPropertyPictureFilteredFields
} from "../../../../../store/propertiesReducer";
import { useAppDispatch } from "../../../../../app/hooks";
import { sessionStorageUtilityValues } from "../../../../../helpers/sessionStorageHelper";
import {DeleteSurfacePicture, GetSurfacePicturesByRef} from "../../../../../store/surfacesReducer";

type PictureUploaderPropsType = {
    i: BrokeragePropertyPicturesTabData;
    isMainImage: boolean
    isTableView?: boolean
    pictureMode?: 'Surfaces' | 'Properties'
};

const PictureUploader = ({ i,isMainImage, isTableView, pictureMode}: PictureUploaderPropsType) => {
    const currentBrokerageProperty = sessionStorageUtilityValues.getCurrentBrokerageReqProperty();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const currentSurface = sessionStorageUtilityValues.getCurrentSurfacesForEdit()

    const handleChange = (file: File) => {
        const reader = new FileReader();
        reader.readAsDataURL(file); // Read the file as data URL
    };


    // useEffect(() => {
    //     // @ts-ignore
    //     if (i.raw_data !== null){
    //         setImageUrl(`data:image/jpeg;base64,${i.raw_data}`)
    //     }
    // }, [i.raw_data])

    const beforeUpload = (file: File) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            Modal.error({ title: "Invalid file format", content: "Only JPG/PNG is allowed." });
            return false;
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            Modal.error({ title: "File too large", content: "Image must be smaller than 2MB." });
            return false;
        }
        handleChange(file);
        // Convert file to Base64
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onerror = (error) => {
            console.error("Error converting file:", error);
        };
        return false; // Prevent automatic upload
    };

    const confirmDelete = (propId: number) => {
        Modal.confirm({
            title: "Are you sure you want to delete this image?",
            content: "This action cannot be undone.",
            okText: "Yes, delete it",
            cancelText: "Cancel",
            onOk: () => handleDelete(propId),
        });
    };

    const handleDelete = (propId: number) => {
        if(pictureMode === 'Surfaces'){
            dispatch(DeleteSurfacePicture(propId))
                .then(() => {
                    setTimeout(() => {
                        dispatch(onSetPropertyPictureFilteredFields([]));
                        dispatch(GetSurfacePicturesByRef(currentSurface.SURF_REF));
                        message.success('Surface image has been deleted')
                    } ,500)
                });
        }
        else{
            dispatch(DeleteBrokeragePropertyPicture(propId))
                .then(() => {
                    setTimeout(() => {
                        dispatch(onSetPropertyPictureFilteredFields([]));
                        dispatch(GetBrokeragePropertyPicturesByRef(currentBrokerageProperty.PROP_ID));
                        message.success('Property image has been deleted')
                    } ,500)
                });
        }
    };

    const uploadButton = (
        <div>
            {loading ? <PlusOutlined spin /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <div className={`${s.picture__img} ${isTableView ? 'tableViewUploader' : 'editPicturesUploader'}`}>
            <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={beforeUpload}
                disabled={true}
            >
                {i.raw_data !== null ? (
                    <div className="image-container">
                        <img src={`data:image/jpeg;base64,${i.raw_data}`} alt="Base64" draggable="false" onDragStart={(e) => e.preventDefault()}/>
                        {
                            !isTableView
                            &&
                            <div className={s.picture__overlay}>
                                <DeleteOutlined
                                    className={s.picture__icon}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        confirmDelete(i.id);
                                    }}
                                />
                            </div>
                        }
                    </div>
                ) :
                 <Empty/>}
            </Upload>
        </div>
    );
};

export default PictureUploader;
