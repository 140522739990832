import { configureStore } from '@reduxjs/toolkit'
import appStatus, { AppStatusType } from './appStatusReducer'
import user from './userReducer'
import report from './reportReducer'
import parameters from './parametersReducer'
import properties from  './propertiesReducer'
import activitiesGrid from './activitesGrid'
import companies from './companies'
import mapProperties from './propertiesMap'
import events from './eventsReducer'
import requirements from './requirementsReducer'
import filterButtons from './filterButtonsReducer'
import brokerageReqs from './brokerageReqsReducer'
import agentReports from './agentReportsReducer'
import utility from './utilityReducer'
import protocols  from './protocolsReducer'
import surfaces from './surfacesReducer'

export const store = configureStore({
  reducer: {
    appStatus,
    user,
    report,
    parameters,
    properties,
    activitiesGrid,
    companies,
    mapProperties,
    events,
    requirements,
    filterButtons,
    brokerageReqs,
    agentReports,
    utility,
    protocols,
    surfaces
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),
  devTools: true,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AsyncThunkConfig = {
  fulfilledMeta: {
    appStatus?: AppStatusType.succeeded | AppStatusType.idle | AppStatusType.loading
    appMessage?:  string
  },
  state: RootState
}
export type fulfilledMetaType = {
  arg: unknown;
  requestId: string;
  requestStatus: 'fulfilled',
  appStatus: AppStatusType.succeeded | AppStatusType.idle | AppStatusType.loading
  appMessage?:  string
}
