import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AsyncThunkConfig, RootState} from "./store";
import {AppStatusType, setAppStatus} from "./appStatusReducer";
import {gridApi, propertiesApi, surfacesApi, UpdatePropertyPictures} from "../app/api";
import {GridResData} from "../types/commonGridsTypes";
import {GridColumns} from "../types/commonTypes";
import moment from "moment";
import {GridSortItem} from "@mui/x-data-grid";
import {
    BrokeragePropertyPicturesTabData, BrokerageReqsPropertyType,
    ColumnsForFiltersType, CreateNewPropertyPicture, CreateNewSurfacePicture, GetBrokeragePropertyPicturesByRef,
    onSetIsBrokeragePropertyPicturesDataLoading,
    ShownAndHiddenColumnsType
} from "./propertiesReducer";
import {ColumnsFiltersType, RequirementsSearchedContact, RequirementsSearchedOrganization} from "./activitesGrid";

interface InitialStateType {
    gridRows: SurfaceRowType[]
    gridColumns: GridColumns[]
    defaultGridRows: SurfaceRowType[]
    surfacesSortingString: string | null
    surfacesSortingModel: GridSortItem[] | []
    isGridDataLoading: boolean
    surfacesShownColumns: ShownAndHiddenColumnsType[]
    surfacesHiddenColumns: ShownAndHiddenColumnsType[]
    gridFiltersForColumns: ColumnsFiltersType[],
    gridColumnsForFilters: ColumnsForFiltersType[],
    offerCreationModalMode: 'Opened' | 'Hided' | 'Closed',
    checkedSurfaces: SurfaceRowType[]
    surfacesSearchedOrganization: RequirementsSearchedOrganization | null
    requirementsSearchedContact: RequirementsSearchedContact | null
    surfacesPicturesTabData: BrokeragePropertyPicturesTabData[]
    surfacesPicturesTabDefaultData: BrokeragePropertyPicturesTabData[]
    surfacesPicturesTabDataLoading: boolean
    currentSurfaceForEdit: BrokerageReqsPropertyType
    createNewSurfaceImage: CreateNewSurfacePicture
}
const initialState: InitialStateType = {
    gridRows: [],
    gridColumns: [],
    defaultGridRows: [],
    surfacesSortingString: null,
    surfacesSortingModel: [],
    isGridDataLoading: false,
    surfacesShownColumns: [],
    surfacesHiddenColumns: [],
    gridColumnsForFilters: [],
    gridFiltersForColumns: [],
    offerCreationModalMode: 'Closed',
    checkedSurfaces: [],
    surfacesSearchedOrganization: null,
    requirementsSearchedContact: null,
    surfacesPicturesTabData: [],
    surfacesPicturesTabDefaultData: [],
    surfacesPicturesTabDataLoading: false,
    currentSurfaceForEdit: {
        ADDR_CITY: null,
        ADDR_COUNTRY_CODE: null,
        ADDR_LATI: 0,
        ADDR_LONGI: 0,
        ADDR_STREET: null,
        ADDR_ZIP: null,
        AGENT_NAME: null,
        CREATED_BY: null,
        DATE_CREATED: null,
        DESCRIPTION: null,
        LOT_SIZE: null,
        PROP_ID: null,
        REALMAN_ALLOWED: null,
        STATUS: null,
        SURFACE_TOTAL: null,
        id: 0,
        isFiltered: false,
        isOpened: false,
        reqs: [],
        withSurface: false,
    },
    createNewSurfaceImage: {
        "surface-image": {
            filename: null,
            format: null,
            title: null,
            description: null,
            type: 0,
            for_expose: 0,
            for_portal: 0,
            for_presentation: 0,
            is_ref: 0,
            is_pme: 0,
            is_locked: 0,
            index: 0,
            width: 0,
            height: 0,
            export_order: null,
        },
        photo: null
    },
}

export const surfacesSlice = createSlice({
    name: 'surfaces',
    initialState,
    reducers: {
        resetState: () => initialState,
        onSetSurfacesSortingString: (state, action: PayloadAction<{ sorting_string: string | null }>) => {
            state.surfacesSortingString = action.payload.sorting_string
        },
        onSetSurfacesLocalSortModel: (state, action: PayloadAction<{ sort_model: GridSortItem[] }>) => {
            state.surfacesSortingModel = action.payload.sort_model
        },
        onSetSurfacesGridDataLoading: (state, action: PayloadAction<boolean>) => {
            state.isGridDataLoading = action.payload
        },
        setSurfacesShownColumns: (state, action: PayloadAction<{ columns: ShownAndHiddenColumnsType[] }>) => {
            state.surfacesShownColumns = action.payload.columns.sort((a: any, b: any) => a.col_pos - b.col_pos)
        },
        setSurfacesHiddenColumns: (state, action: PayloadAction<{ columns: ShownAndHiddenColumnsType[] }>) => {
            state.surfacesHiddenColumns = action.payload.columns
        },
        setSurfacesGridColumns: (state, action: PayloadAction<{ columns: GridColumns[] }>) => {
            state.gridColumns = action.payload.columns.sort((a: any, b: any) => a.col_pos - b.col_pos)
        },
        onSetFiltersForSurfacesGrid: (state, action: PayloadAction<{ gridFilters: ColumnsFiltersType[] }>) => {
            state.gridFiltersForColumns = action.payload.gridFilters
        },
        onSetRRSurfaces: (state, action: PayloadAction<{ gridRows: SurfaceRowType[] }>) => {
            state.gridRows = action.payload.gridRows
        },
        onSurfacesResetFiltersButtonsSelected: (state) => {

            state.gridColumnsForFilters = state.gridColumnsForFilters.map((m: ColumnsForFiltersType) => m && {
                ...m,
                isFilterSelected: false,
                isModalBtnOpened: false,
                value: '',
                startValue: '',
                endValue: '',
                conditionValue: ''
            })

        },
        onSetGridFiltersButtonsModalOpened: (state, action: PayloadAction<{ headerName: string, isModalOpened: boolean }>) => {
            state.gridColumnsForFilters = state.gridColumnsForFilters.map((m: ColumnsForFiltersType) => m.headerName === action.payload.headerName ? {
                ...m,
                isModalBtnOpened: action.payload.isModalOpened
            } : {...m, isModalBtnOpened: false})
        },
        onFilterRRSurfacesGrid: (state, action: PayloadAction<{ gridFilters: ColumnsFiltersType[], gridType: string }>) => {
            state.gridFiltersForColumns = action.payload.gridFilters
        },
        onSetRRSurfacesGridFiltersButtonsModalOpened: (state, action: PayloadAction<{ headerName: string, isModalOpened: boolean }>) => {
            state.gridColumnsForFilters = state.gridColumnsForFilters.map((m: ColumnsForFiltersType) => m.headerName === action.payload.headerName ? {
                ...m,
                isModalBtnOpened: action.payload.isModalOpened
            } : {...m, isModalBtnOpened: false})
        },
        onSetRRSurfacesGridFiltersButtonsSelected: (state, action: PayloadAction<{ headerName?: string, isFilterSelected: boolean, columnTitle?: string }>) => {
            state.gridColumnsForFilters = state.gridColumnsForFilters.map((m: any) => m.headerName === action.payload.headerName || m.title === action.payload.columnTitle ? {
                ...m,
                isFilterSelected: action.payload.isFilterSelected
            } : m)
        },
        onChangeRRSurfacesGridFilterValue: (state, action: PayloadAction<{ title?: string, value: string| number, startValue?: string, endValue?: string, columnTitle?: string }>) => {
            state.gridColumnsForFilters = state.gridColumnsForFilters.map((m: any) => m.headerName === action.payload.title || m.title === action.payload.columnTitle ? {
                ...m,
                value: action.payload.value,
                startValue: action.payload.startValue,
                endValue: action.payload.endValue
            } : m)
        },
        onChangeRRSurfacesGridFilterConditionValue: (state, action: PayloadAction<{ title: string, value: string }>) => {
            state.gridColumnsForFilters = state.gridColumnsForFilters.map((m: any) => m.headerName === action.payload.title ? {
                ...m,
                conditionValue: action.payload.value
            } : m)
        },
        setRRSurfacesGridColumnsForFilters: (state, action: PayloadAction<{ columns: ColumnsForFiltersType[] }>) => {
            state.gridColumnsForFilters = action.payload.columns
        },
        onSetFiltersForRRSurfacesGrid: (state, action: PayloadAction<{ gridFilters: ColumnsFiltersType[] }>) => {
            state.gridFiltersForColumns = action.payload.gridFilters
        },
        onChangeRRSurfacesGridFilterStartValue: (state, action: PayloadAction<{ title: string, value: any }>) => {
            state.gridColumnsForFilters = state.gridColumnsForFilters.map((m: any) => m.headerName === action.payload.title ? {
                ...m,
                startValue: action.payload.value
            } : m)
        },
        onChangeRRSurfacesGridFilterEndValue: (state, action: PayloadAction<{ title: string, value: string }>) => {
            state.gridColumnsForFilters = state.gridColumnsForFilters.map((m: ColumnsForFiltersType) => m.headerName === action.payload.title ? {
                ...m,
                endValue: action.payload.value
            } : m)
        },
        onChangeRRSurfacesGridColumnsPosition: (state, action: PayloadAction<{ columns: string[] }>) => {
            state.surfacesShownColumns = state.surfacesShownColumns.sort((a, b) => action.payload.columns.indexOf(a.field!!) - action.payload.columns.indexOf(b.field!!))
        },
        onChangeRRSurfacesGridColumnsWidth: (state, action: PayloadAction<{ columns: ShownAndHiddenColumnsType[] }>) => {
            const currentStateColumns = state.surfacesShownColumns
            Object.entries(action.payload.columns).forEach((item: [string, ShownAndHiddenColumnsType]) => {
                const columnName = item[0];
                const gridColumnParams = item[1];
                const currentColumn = currentStateColumns.find(obj => obj.field === columnName);
                if (currentColumn && gridColumnParams && gridColumnParams.width) {
                    currentColumn.width = gridColumnParams.width;
                }
            });
        },
        onSetOfferCreationModalOpened: (state, action: PayloadAction<'Opened' | 'Hided' | 'Closed'>) => {
            state.offerCreationModalMode = action.payload
        },
        onAddSurfaceToChecked: (state, action: PayloadAction<{checked: boolean, surface: SurfaceRowType}>) => {
            if(action.payload.checked){
                state.checkedSurfaces.push(action.payload.surface)
            }
            else{
                state.checkedSurfaces = state.checkedSurfaces.filter((s: SurfaceRowType) => Number(s.SURF_REF) !== Number(action.payload.surface.SURF_REF))
            }
        },
        onSetCurrentSurfaceForEdit(state, action: PayloadAction<BrokerageReqsPropertyType>){
            state.currentSurfaceForEdit = action.payload
        },
        onUpdateCreateNewSurfaceImageField(
            state,
            action: PayloadAction<{ fieldKey: string; value: string | number | null | any }>
        ) {
            const { fieldKey, value } = action.payload;
            if (fieldKey.startsWith("surface-image.")) {
                const imageKey = fieldKey.split(".")[1] as keyof typeof state.createNewSurfaceImage["surface-image"];
                if (imageKey in state.createNewSurfaceImage["surface-image"]) {
                    if(imageKey === 'type'){
                        console.log(imageKey)
                        // @ts-ignore
                        state.createNewSurfaceImage["surface-image"][imageKey] = Number(value);
                    }
                    else{
                        // @ts-ignore
                        state.createNewSurfaceImage["surface-image"][imageKey] = value;
                    }


                }
            } else if (fieldKey === "photo") {
                state.createNewSurfaceImage.photo = value as string | null;
            } else {
                console.warn(`Unknown fieldKey: ${fieldKey}`);
            }
        },
        onUpdateSurfacePictureField(
            state,
            action: PayloadAction<{ id: number; fieldKey: string; value: string | number | null | boolean }>
        ) {
            const { id, fieldKey, value } = action.payload;
            const targetObject = state.surfacesPicturesTabData.find(item => item.id === id);
            if (targetObject) {
                // @ts-ignore
                targetObject[fieldKey] = value as typeof targetObject[typeof fieldKey];
                targetObject.isEdited = true;
            }
        },
        onResetCreateNewSurfaceImageFields(state) {
            state.createNewSurfaceImage = {
                "surface-image": {
                    filename: null,
                    format: null,
                    title: null,
                    description: null,
                    type: 0,
                    for_expose: 0,
                    for_portal: 0,
                    for_presentation: 0,
                    is_ref: 0,
                    is_pme: 0,
                    is_locked: 0,
                    index: 0,
                    width: 0,
                    height: 0,
                    export_order: 0,
                },
                photo: null
            }
        },
        onSetSurfacePictureFilteredFields(state, action: PayloadAction< BrokeragePropertyPicturesTabData[]>){
            state.surfacesPicturesTabData = action.payload
        },
        onResetReadyToDeletePropertySurfaceFilteredFields(state){
            state.surfacesPicturesTabData = state.surfacesPicturesTabData.map((p) => p ? {...p, isDeleteChecked: false} : p)
        },
        onSetReadyToDeleteSurfacePictureFilteredFields(state, action: PayloadAction<{id: number, readyToDelete: boolean}>){
            state.surfacesPicturesTabData = state.surfacesPicturesTabData.map((p) => p.id === action.payload.id ? {...p, isDeleteChecked: action.payload.readyToDelete} : p)
        },
        onSetDefaultSurfacePictureFilteredFields(state, action: PayloadAction< BrokeragePropertyPicturesTabData[]>){
            state.surfacesPicturesTabDefaultData = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetRRSurfacesGridData.fulfilled, (state, action) => {
                const gridData = action.payload.grid_column_data;
                // eslint-disable-next-line
                const surfaces = gridData.map((innerArr: GridColumns[], index: number) => {
                    if (innerArr !== null) {
                        const obj: any = {};
                        innerArr.forEach((currentValue: GridColumns) => {
                            if (currentValue.COL_TYPE === 'class java.math.BigDecimal') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null
                                    ? parseFloat(currentValue.COL_VALUE.toString().replace(/\./g, '').replace(',', '.'))
                                    : null;
                            } else if (currentValue.COL_TYPE === 'class java.time.LocalDateTime') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null
                                    ? moment(currentValue.COL_VALUE).format('YYYY-MM-DD HH:mm:ss')
                                    : null;
                            } else {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE;
                            }
                        });
                        obj['id'] = index;
                        obj['isFiltered'] = false;
                        return obj;
                    }
                })
                state.gridRows = surfaces
                state.defaultGridRows = surfaces
                state.gridColumns = gridData[0]

            })
            .addCase(GetSurfacePicturesByRef.fulfilled, (state, action) => {
                function sortByIndexAndTypeText(arr: any[]) {
                    // Step 1: Sort by index
                    const sortedByIndex = [...arr].sort((a, b) => a.index - b.index);

                    // Step 2: Group by type_text
                    const groupedByTypeText: Record<string, any[]> = {};
                    sortedByIndex.forEach(item => {
                        if (!groupedByTypeText[item.type_text]) {
                            groupedByTypeText[item.type_text] = [];
                        }
                        groupedByTypeText[item.type_text].push(item);
                    });

                    // Step 3: Flatten grouped data while maintaining order
                    return Object.values(groupedByTypeText).flat();
                }
                const data = action.payload
                    .map((item, index) => ({ ...item, isDeleteChecked: false}))
                state.surfacesPicturesTabData = sortByIndexAndTypeText(data)
                state.surfacesPicturesTabDefaultData = action.payload
                    .map((item, index) => ({ ...item, isDeleteChecked: false}))
                    .sort((a, b) => a.index - b.index);
            })
    }
})

export const GetRRSurfacesGridData = createAsyncThunk<GridResData, {
    "status": number
    "sortOrder": string | null
    "p_agent_ref": number
}, AsyncThunkConfig>(
    'surfaces/getRRSurfacesGridData',
    async (reqData, thunkAPI) => {
        thunkAPI.dispatch(setAppStatus(1))
        thunkAPI.dispatch(onSetSurfacesGridDataLoading(true))
        try {
            const {status, data} = await gridApi.getRRSurfacesGridData(reqData)
            if (status === 200 && data) {
                if (data.sorting_rule !== null) {
                    const sortingCondition = data.sorting_rule.split(',').map((item: any) => {
                        const [field, sort] = item.trim().split(' ');
                        return {field, sort: sort.toLowerCase()};
                    });
                    thunkAPI.dispatch(onSetSurfacesSortingString({sorting_string: data.sorting_rule}));
                    thunkAPI.dispatch(onSetSurfacesLocalSortModel({sort_model: sortingCondition}));
                } else {
                    thunkAPI.dispatch(onSetSurfacesSortingString({sorting_string: null}));
                    thunkAPI.dispatch(onSetSurfacesLocalSortModel({sort_model: []}));
                }
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
        finally {
            thunkAPI.dispatch(onSetSurfacesGridDataLoading(false))
        }
    }
)

export const GetSurfacePicturesByRef = createAsyncThunk<BrokeragePropertyPicturesTabData[],
    number, // Argument type for the payload creator.
    AsyncThunkConfig // Extra configuration for the thunk.
    >(
    'surfaces/getSurfacePicturesByRef',
    async (ref, thunkAPI) => {
        thunkAPI.dispatch(onSetIsBrokeragePropertyPicturesDataLoading(true))
        try {
            const {status, data} = await surfacesApi.findSurfaceImagesByPropRef(ref)
            if (status === 200) {
                return thunkAPI.fulfillWithValue(data.property_images, {appStatus: AppStatusType.idle});
            } else {
                return thunkAPI.rejectWithValue(data.property_images);
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message);
        }
        finally {
            thunkAPI.dispatch(onSetIsBrokeragePropertyPicturesDataLoading(false))
        }
    }
);


export const CreateSurfacePicture = createAsyncThunk<void, {surf_ref: number, reqData: any}, AsyncThunkConfig>(
    'surfaces/createSurfacePicture',
    async (requestData, thunkAPI) => {
        try {
            const {status, data} = await surfacesApi.createSurfaceImageBySurfRef(requestData.surf_ref, requestData.reqData)
            if (status === 200) {
                return thunkAPI.fulfillWithValue(data, {
                    appStatus: AppStatusType.idle,
                })
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)


export const UpdateSurfacePictures = createAsyncThunk<void, {reqData: UpdatePropertyPictures, ref: number}, AsyncThunkConfig>(
    'surfaces/updateSurfacePictures',
    async (requestData, thunkAPI) => {
        try {
            const {status, data} = await surfacesApi.updateSurfaceImagesByPropRef(requestData.ref, requestData.reqData)
            if (status === 200) {
                return thunkAPI.fulfillWithValue(data, {
                    appStatus: AppStatusType.succeeded,
                    appMessage: 'Surface pictures data has been updated'
                })
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
        // finally {
        //     setTimeout(() => {
        //         thunkAPI.dispatch(onChangeIsAddressModalOpened(false))
        //         window.location.reload()
        //     }, 1500)
        // }
    }
)

export const DeleteSurfacePicture = createAsyncThunk<void, number, AsyncThunkConfig>(
    'surfaces/deleteSurfacePicture',
    async (pictureId, thunkAPI) => {
        try {
            const {status, data} = await surfacesApi.deleteSurfaceImageByPropRef(pictureId)
            if (status === 200) {
                return thunkAPI.fulfillWithValue(data, {
                    appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
        // finally {
        //     setTimeout(() => {
        //         thunkAPI.dispatch(onChangeIsAddressModalOpened(false))
        //         window.location.reload()
        //     }, 1500)
        // }
    }
)

export const {
    onSetSurfacesSortingString,
    onSetSurfacesLocalSortModel,
    onSetSurfacesGridDataLoading,
    setSurfacesShownColumns,
    setSurfacesHiddenColumns,
    setSurfacesGridColumns,
    onSetFiltersForSurfacesGrid,
    onSetRRSurfaces,
    onSurfacesResetFiltersButtonsSelected,
    onSetGridFiltersButtonsModalOpened,
    onFilterRRSurfacesGrid,
    onSetRRSurfacesGridFiltersButtonsModalOpened,
    onSetRRSurfacesGridFiltersButtonsSelected,
    onChangeRRSurfacesGridFilterValue,
    onChangeRRSurfacesGridFilterConditionValue,
    setRRSurfacesGridColumnsForFilters,
    onSetFiltersForRRSurfacesGrid,
    onChangeRRSurfacesGridFilterStartValue,
    onChangeRRSurfacesGridFilterEndValue,
    onChangeRRSurfacesGridColumnsPosition,
    onChangeRRSurfacesGridColumnsWidth,
    onSetOfferCreationModalOpened,
    onAddSurfaceToChecked,
    onSetCurrentSurfaceForEdit,
    onUpdateCreateNewSurfaceImageField,
    onUpdateSurfacePictureField,
    onResetCreateNewSurfaceImageFields,
    onSetSurfacePictureFilteredFields,
    onResetReadyToDeletePropertySurfaceFilteredFields,
    onSetReadyToDeleteSurfacePictureFilteredFields,
    onSetDefaultSurfacePictureFilteredFields
} = surfacesSlice.actions

export const selectIsSurfacesDataLoading = (state: RootState): boolean  => state.surfaces.isGridDataLoading
export const selectSurfacesShownColumns = (state: RootState): ShownAndHiddenColumnsType[] => state.surfaces.surfacesShownColumns
export const selectSurfacesHiddenColumns = (state: RootState): ShownAndHiddenColumnsType[] => state.surfaces.surfacesHiddenColumns
export const selectSurfacesGridColumns = (state: RootState): GridColumns[] => state.surfaces.gridColumns
export const selectSurfacesGridRows = (state: RootState): SurfaceRowType[] => state.surfaces.gridRows
export const selectSurfacesGridFilters = (state: RootState): ColumnsFiltersType[] => state.surfaces.gridFiltersForColumns
export const selectSurfacesGridColumnsForFilters = (state: RootState): ColumnsForFiltersType[] => state.surfaces.gridColumnsForFilters
export const selectSurfacesGridSortModel = (state: RootState): [] | GridSortItem[] => state.surfaces.surfacesSortingModel
export const selectSurfacesDefaultGridRows = (state: RootState): SurfaceRowType[] => state.surfaces.defaultGridRows
export const selectSurfaceOfferModalMode = (state: RootState): 'Opened' | 'Hided' | 'Closed' => state.surfaces.offerCreationModalMode
export const selectCheckedSurfacesForOffer = (state: RootState): SurfaceRowType[] => state.surfaces.checkedSurfaces
export const selectSurfacesSearchedOrganization = (state: RootState): RequirementsSearchedOrganization | null => state.activitiesGrid.requirementsSearchedOrganization
export const selectSurfacesSearchedContact = (state: RootState): RequirementsSearchedContact | null => state.activitiesGrid.requirementsSearchedContact
export const selectSurfacesTypeColumnFilterValue = (state: RootState): CreateNewSurfacePicture => state.surfaces.createNewSurfaceImage
export const selectSurfacesPicturesTabFields = (state: RootState) : BrokeragePropertyPicturesTabData[] => state.surfaces.surfacesPicturesTabData
export const selectSurfacesPicturesTabDefaultFields = (state: RootState) : BrokeragePropertyPicturesTabData[] => state.surfaces.surfacesPicturesTabDefaultData

export interface SurfaceRowType{
    "SURF_REF": number | null
    "PROP_REF": number | null
    "DATE_CREATED": string | null
    "CREATED_BY": string | null
    "DATE_MODIFIED": string | null
    "MODIFIED_BY": string | null
    "ADDR_CITY": string | null
    "ADDR_QUARTER": string | null
    "ADDR_STREET_ADDRESS": string | null
    "ADDR_ZIP": string | null
    "RENT_TOTAL": number | null
    "COMMISION": boolean| string | null | number
    "CURRENCY": string | null
    "BUILDING_CONDITION": null,
    "BUILDING_CONSTRUCTION": null,
    "DISPOSITION": string | null
    "SURFACE_TOTAL": number | null
    "PU_FLOORLVL": number | null
    "PU_FLOOR_SHORT": string | null
    "BALCONY": boolean| string | null | number
    "ELEVATOR": boolean| string | null | number
    "CELLAR": boolean| string | null | number
    "BASIN": boolean| string | null | number
    "TERRACE": boolean| string | null | number
    "FURNISHED": boolean| string | null | number
    "PU_TYPE": string | null
    "PU_DT_SF_AVAIL": null,
    "PU_SF_AVAIL_TIMEFRAME": string | null
    "DESCRIPTION": string | null
    "REMARKS": string | null
    "id": number
    "isFiltered": boolean
}


export default surfacesSlice.reducer