import React, {useEffect, useState} from 'react';
import PageWithSidebar from "../../PageWithSidebar/PageWithSidebar";
import PageTitle from "../../common/PageTitle/PageTitle";
import {sessionStorageUtilityValues} from "../../../helpers/sessionStorageHelper";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    GetBrokeragePropertyByRef, GetBrokeragePropertyPicturesByRef,
    GetPropertiesCountries, onSetPropertyPictureFilteredFields, selectBrokeragePropertyPicturesTabFields,
    selectBrokerageUpdatedPropertyDefaultFields,
    selectBrokerageUpdatedPropertyFields,
    selectIsAllPropertiesAddressModalOpened,
    UpdateBrokerageProperty, UpdateBrokeragePropertyPictures
} from "../../../store/propertiesReducer";
import s from './EditProperty.module.scss'
import {format} from 'date-fns';
import AllPropertiesAddressForm from "../../AllPropertiesAddressForm/AllPropertiesAddressForm";
import {GetPropertiesPictureTypes, GetPropertiesTypes, selectCurrentScreenWidth} from "../../../store/utilityReducer";
import SecondaryButton from "../../common/Buttons/SecondaryButton/SecondaryButton";
import PrimaryButton from "../../common/Buttons/PrimaryButton/PrimaryButton";
import {useNavigate} from "react-router";
import EditAddressTab from "./EditAddressTab/EditAddressTab";
import EditPicturesTab from "./EditPicturesTab/EditPicturesTab";
import {Alert, Snackbar} from '@mui/material';
import {message, Modal} from "antd";
import {propertiesApi} from "../../../app/api";
import {utilityDataHelper} from "../../../helpers/localStorageHelper";
import {GetSurfacePicturesByRef} from "../../../store/surfacesReducer";

const EditProperty = () => {
    return (
        <PageWithSidebar>
            <EditPropertyComponent/>
        </PageWithSidebar>
    )
}

const EditPropertyComponent = () => {
    const currentBrokerageProperty = sessionStorageUtilityValues.getCurrentBrokerageReqProperty()
    const [currentPageTab, setCurrentPageTab] = useState<'Address' | 'Images'>('Address')
    const dispatch = useAppDispatch()
    const editBrokeragePropertyDefaultFormFields = useAppSelector(selectBrokerageUpdatedPropertyDefaultFields)
    const [fieldsWithError, setFieldsWithError] = React.useState<boolean>(false)
    const formFields = useAppSelector(selectBrokerageUpdatedPropertyFields)
    const formFieldsForPicturesTab = useAppSelector(selectBrokeragePropertyPicturesTabFields)
    const isAddressModalOpened = useAppSelector(selectIsAllPropertiesAddressModalOpened)
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const navigate = useNavigate()
    const [successMessage, showSuccessMessage] = useState(false)
    const [openedAddImageModal, setOpenedAddImageModal] = useState<boolean>(false)
    const currentScreenWidth = useAppSelector(selectCurrentScreenWidth)


    useEffect(() => {
        dispatch(GetBrokeragePropertyByRef(currentBrokerageProperty.PROP_ID))
    }, [dispatch, currentBrokerageProperty.PROP_ID])

    useEffect(() => {
        dispatch(GetPropertiesCountries())
        dispatch(GetPropertiesTypes())
        dispatch(GetPropertiesPictureTypes())
    }, [dispatch])

    const onUpdateProperty = () => {
        if (isProcessing) return;
        setIsProcessing(true);
        if (currentPageTab === 'Address') {
            if ((formFields.address.cntry_desc_local === null || Number(formFields.address.cntry_desc_local) === 0)
                ||
                (formFields.address.addr_city === null || formFields.address.addr_city === '')
                ||
                (formFields.address.addr_zip === null || formFields.address.addr_zip === '')
                ||
                (formFields.address.addr_street === null || formFields.address.addr_street === '')) {
                setFieldsWithError(true)
                setIsProcessing(false)
            } else {
                dispatch(UpdateBrokerageProperty({
                    ref: currentBrokerageProperty.PROP_ID,
                    reqData: {...formFields,
                        address: {
                            ...formFields.address,
                            addr_street: formFields.address.addr_street !== null ? formFields.address.addr_street!.replace(/[\d/]+/g, '').trim()!! : ''
                        }
                    }
                }))
                    .then(() => {
                        setIsProcessing(false)
                    })
            }
        } else {
            const formFieldsForUpdate = formFieldsForPicturesTab.filter((i) => i.isEdited === true).map((field, index) => ({
                filename: field.filename || "",
                format: field.format || "",
                title: field.title || "",
                description: field.description || "",
                type: field.type || 0,
                for_expose: field.for_expose || 0,
                for_portal: field.for_portal || 0,
                for_presentation: field.for_presentation || 0,
                is_ref: field.is_ref || 0,
                is_pme: field.is_pme || 0,
                is_locked: field.is_locked || 0,
                index: field.index,
                width: field.width || 0,
                height: field.height || 0,
                export_order: field.for_portal === 0 ? null : field.export_order === null || field.export_order === '' ? null : Number(field.export_order),
                id: field.id
            }));
            if (formFieldsForUpdate.length) {
                setIsProcessing(true);
                const updatePromises = formFieldsForUpdate.map((i) =>
                    dispatch(UpdateBrokeragePropertyPictures({ reqData: (({ id, ...rest }) => rest)(i), ref: i.id }))
                );
                Promise.all(updatePromises).then(() => {
                    dispatch(GetBrokeragePropertyPicturesByRef(currentBrokerageProperty.PROP_ID));
                    setIsProcessing(false);
                }).catch((error) => {
                    setIsProcessing(false);
                });
            }
            else {
                setIsProcessing(false)
                showSuccessMessage(true)
            }
        }
    }


    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        showSuccessMessage(false);
    };

    const confirmDelete = () => {
        const imageIdsToDelete = formFieldsForPicturesTab
            .filter((i) => i.isDeleteChecked)
            .map((i) => i.id)
        Modal.confirm({
            title: imageIdsToDelete.length > 1 ? "Are you sure you want to delete these images?" : "Are you sure you want to delete this image?",
            content: "This action cannot be undone.",
            okText: "Yes, delete it",
            cancelText: "Cancel",
            onOk: () => handleImagesDelete(),
        });
    };

    const handleImagesDelete = async () => {
        try {
            // Extract IDs of images that are marked for deletion
            const imageIdsToDelete = formFieldsForPicturesTab
                .filter((i) => i.isDeleteChecked) // Filter checked items
                .map((i) => i.id); // Extract IDs

            if (imageIdsToDelete.length === 0) {
                message.warning("No images selected for deletion");
                return;
            }

            // Dispatch a single delete action with all IDs
            await propertiesApi.deletePropertiesImagesByIds(imageIdsToDelete)

            setTimeout(() => {
                dispatch(onSetPropertyPictureFilteredFields([])); // Clear selection
                dispatch(GetBrokeragePropertyPicturesByRef(currentBrokerageProperty.PROP_ID));
                message.success("Property images have been deleted");
            }, 500);

        } catch (error) {
            console.error("Error deleting images:", error);
            message.error("Failed to delete property images");
        }
    };


    useEffect(() => {
        if(openedAddImageModal){
            document.body.style.overflow = "hidden";
        }
        else{
            document.body.style.overflow = "";
        }
    }, [openedAddImageModal])


    return (
        <>
            <Snackbar
                open={successMessage}
                autoHideDuration={3000}
                onClose={handleClose}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                    Property pictures data has been updated
                </Alert>
            </Snackbar>
            <div className={s.inner}>
                <div className={s.inner__content} style={{
                    width: currentPageTab === 'Address' ? '83%' : '100%'
                }}>
                    <div>
                        <PageTitle
                            isBackButton={true}
                            backLinkSecondText={`Back`}
                            backLink={'/properties'}
                        />
                    </div>
                    <div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                            <p className={s.propertyAddress}>
                                {currentBrokerageProperty.PROP_ID} {editBrokeragePropertyDefaultFormFields?.address?.addr_quarter}, {editBrokeragePropertyDefaultFormFields?.address?.addr_street}, {editBrokeragePropertyDefaultFormFields?.address?.addr_zip} {editBrokeragePropertyDefaultFormFields?.address?.addr_city}
                            </p>
                            {
                                currentPageTab === 'Images'
                                &&

                                    <div onClick={() => setOpenedAddImageModal(true)}>
                                        <PrimaryButton disabled={isProcessing} text={'Add image'}
                                                       isAddButton={true} width={'150px'}
                                                       height={'32px'}/>
                                    </div>
                            }
                        </div>
                        <div className={s.tabButtons}>
                            <button
                                className={currentPageTab === 'Address' ? `${s.tabButtons__button} ${s.tabButtons__button_active}` : `${s.tabButtons__button}`}
                                onClick={() => setCurrentPageTab('Address')}
                                style={{position: "relative"}}
                            >
                                Address
                            </button>
                            <button
                                className={currentPageTab === 'Images' ? `${s.tabButtons__button} ${s.tabButtons__button_active}` : `${s.tabButtons__button}`}
                                onClick={() => setCurrentPageTab('Images')}
                                style={{position: "relative"}}
                            >
                                Images
                            </button>
                        </div>
                    </div>
                    {
                        currentPageTab === 'Address'
                            ?
                            <EditAddressTab fieldsWithError={fieldsWithError}/>
                            :
                            <>
                                <EditPicturesTab openedAddImageModal={openedAddImageModal} setOpenedAddImageModal={setOpenedAddImageModal}/>
                            </>
                    }
                </div>
                {
                    currentPageTab === 'Address'
                    &&
                    <div>
                        <p className={s.created}>
                            <span>Created at</span> {format(new Date(currentBrokerageProperty.DATE_CREATED), 'dd.MM.yyyy')} {currentBrokerageProperty.CREATED_BY}
                        </p>
                    </div>
                }
                {
                    isAddressModalOpened
                    &&
                    <AllPropertiesAddressForm isPropertyEditMode={true}/>
                }
                {
                    currentPageTab === 'Images'
                    ?
                        <div className={'flex-space-between-center'} style={{
                            marginTop: '40px',
                            marginBottom: '50px',
                            position: 'fixed',
                            bottom: '-50px',
                            backgroundColor: '#FFF',
                            zIndex: 500,
                            height: '100px',
                            width: currentScreenWidth!! > 1440 ? '90%' : '85%'
                        }}>
                            {
                                currentPageTab === 'Images'
                                &&
                                <button
                                    className={s.deleteBtn}
                                    disabled={formFieldsForPicturesTab.every((i) => !i.isDeleteChecked)}
                                    style={{
                                        height: '44px',
                                        width: '240px',
                                    }}
                                    onClick={confirmDelete}
                                >
                                    Delete
                                </button>
                            }
                            <div className={s.buttons}>
                                <div onClick={() => navigate('/properties')} onDoubleClick={() => {
                                }} style={{
                                    width: '240px'
                                }}>
                                    <SecondaryButton text={'Cancel'} width={'240px'} height={'44px'} isWhiteBg={true}/>
                                </div>
                                {
                                    isProcessing
                                        ?
                                        <div style={{
                                            width: '100%',
                                            display: 'flex',
                                            gap: '12px'
                                        }}
                                        >
                                            <PrimaryButton disabled={isProcessing} text={'Save'} isAddButton={false} width={'240px'}
                                                           height={'44px'} loading={isProcessing}/>
                                        </div>
                                        :
                                        <div style={{
                                            width: '240px'
                                        }}
                                             onClick={onUpdateProperty}
                                             onDoubleClick={() => {
                                             }}
                                        >
                                            <PrimaryButton disabled={isProcessing} text={'Save'}
                                                           isAddButton={false} width={'240px'}
                                                           height={'44px'}/>
                                        </div>
                                }

                            </div>
                        </div>
                        :
                        <div className={'flex-space-between-center'} style={{
                            marginTop: '40px',
                            marginBottom: '50px'
                        }}>
                            <div className={s.buttons}>
                                <div onClick={() => navigate('/properties')} onDoubleClick={() => {
                                }} style={{
                                    width: '240px'
                                }}>
                                    <SecondaryButton text={'Cancel'} width={'240px'} height={'44px'} isWhiteBg={true}/>
                                </div>
                                {
                                    isProcessing
                                        ?
                                        <div style={{
                                            width: '100%',
                                            display: 'flex',
                                            gap: '12px'
                                        }}
                                        >
                                            <PrimaryButton disabled={isProcessing} text={'Save'} isAddButton={false} width={'240px'}
                                                           height={'44px'} loading={isProcessing}/>
                                        </div>
                                        :
                                        <div style={{
                                            width: '240px'
                                        }}
                                             onClick={onUpdateProperty}
                                             onDoubleClick={() => {
                                             }}
                                        >
                                            <PrimaryButton disabled={isProcessing} text={'Save'}
                                                           isAddButton={false} width={'240px'}
                                                           height={'44px'}/>
                                        </div>
                                }

                            </div>
                        </div>
                }


            </div>
        </>
    );
};

export default EditProperty;