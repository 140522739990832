import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {selectPropertiesPicturesTypes} from "../../../../store/utilityReducer";
import s from "../EditProperty.module.scss";
import {Checkbox, Empty, Input, message, Modal, Select, Upload} from "antd";
import {
    selectBrokeragePropertiesPicturesDataLoading,
} from "../../../../store/propertiesReducer";
import {sessionStorageUtilityValues} from "../../../../helpers/sessionStorageHelper";
import {Lottie} from "@crello/react-lottie";
import animatedLoader from "../../../../img/Loader.json";
import PictureUploader from './PictureUploader/PictureUploader';
import CreationModal from "../../../common/CreationModal/CreationModal";
import {FileImageOutlined} from "@ant-design/icons";
import ShowMore from './ShowMore/ShowMore';
import KindEditor from './KindEditor/KindEditor';
import { format } from 'date-fns';
import DraggableCategories from "../DraggablePictures";
import {
    CreateSurfacePicture,
    GetSurfacePicturesByRef,
    onResetCreateNewSurfaceImageFields,
    onResetReadyToDeletePropertySurfaceFilteredFields,
    onSetReadyToDeleteSurfacePictureFilteredFields,
    onSetSurfacePictureFilteredFields,
    onUpdateCreateNewSurfaceImageField,
    onUpdateSurfacePictureField, selectSurfacesPicturesTabDefaultFields, selectSurfacesPicturesTabFields,
    selectSurfacesTypeColumnFilterValue
} from "../../../../store/surfacesReducer";

interface EditSurfacesTabPropsType {
    openedAddImageModal: boolean
    setOpenedAddImageModal: (opened: boolean) => void
}

const EditSurfacesTab = ({openedAddImageModal, setOpenedAddImageModal}: EditSurfacesTabPropsType) => {
    const addSurfacePictureFields = useAppSelector(selectSurfacesTypeColumnFilterValue)
    const dispatch = useAppDispatch()
    let formFields = useAppSelector(selectSurfacesPicturesTabFields)
    const [fieldsWithError, setFieldsWithError] = React.useState<boolean>(false)
    const isDataLoading = useAppSelector(selectBrokeragePropertiesPicturesDataLoading)
    const [requestRunning, setIsRequestRunning] = useState(false)
    const picturesTypes = useAppSelector(selectPropertiesPicturesTypes)
    const [propertyPictureType, setPropertyPictureType] = useState('All')
    const [sortMode, setSortMode] = useState('By Category')
    const [fileList, setFileList] = useState([]);
    const [viewMode, setViewMode] = useState<'List' | 'Table'>('List')
    const defaultFromFields = useAppSelector(selectSurfacesPicturesTabDefaultFields)
    const currentSurface = sessionStorageUtilityValues.getCurrentSurfacesForEdit()

    const propertiesPicturesTypesValues = [
        {value: "All", label: "All"},
        ...picturesTypes.map((type: { id: number, value: string }) => ({
            value: `${type.id}`,
            label: `${type.value}`,
        })),
    ];

    const sortModeTypesValues = [
        {value: "All Items", label: "All Items"},
        {value: "By Category", label: "By Category"}
    ];

    const onFormInputChange = (id: number, e: React.ChangeEvent<HTMLInputElement> | boolean, inputName: string, type: 'Edit' | 'Add') => {

            if (type === 'Edit') {
                if (typeof e === 'boolean') {
                    dispatch(onUpdateSurfacePictureField({id, fieldKey: inputName, value: e ? 1 : 0}));
                    return;
                } else {
                    const {value} = e.target;
                    dispatch(onUpdateSurfacePictureField({id, fieldKey: inputName, value}));
                }
            } else {
                if (typeof e === 'boolean') {
                    dispatch(onUpdateCreateNewSurfaceImageField({fieldKey: inputName, value: e ? 1 : 0}));
                    return;
                } else {
                    const {value} = e.target
                    console.log(value)
                    dispatch(onUpdateCreateNewSurfaceImageField({fieldKey: inputName, value}));
                }
            }


    };

    const onChangePropertyPictureType = (value: string) => {
        setPropertyPictureType(value)
        if(value === 'All'){
            dispatch(onSetSurfacePictureFilteredFields(sortByIndexAndTypeText(defaultFromFields)))
        }
        else{
            dispatch(onSetSurfacePictureFilteredFields(defaultFromFields.filter((i) => i.type_text === value)))
        }
    }

    const onChangeSortMode = (value: string) => {
        setSortMode(value)
        setPropertyPictureType('All')
        if (value === 'All Items'){
            dispatch(onSetSurfacePictureFilteredFields(sortByTypeTextAndAssignExportOrder(defaultFromFields)))
        }
        else{
            dispatch(onSetSurfacePictureFilteredFields(sortByIndexAndTypeText(defaultFromFields)))
        }

    }

    function sortByTypeTextAndAssignExportOrder(arr: any[]) {
        // Step 1: Set for_portal = 1 for all objects
        const updatedArray = arr.map(item => ({ ...item, for_portal: 1 }));

        // Step 2: Sort by type_text alphabetically
        const sortedByTypeText = updatedArray.sort((a, b) => a.type_text.localeCompare(b.type_text));

        // Step 3: Sort within each type_text group by export_order (keeping non-null and non-zero values first)
        sortedByTypeText.sort((a, b) => {
            if (a.type_text !== b.type_text) {
                return a.type_text.localeCompare(b.type_text); // Sort by type_text first
            }
            if (a.export_order && b.export_order) {
                return a.export_order - b.export_order; // Sort by export_order if both are non-null and non-zero
            }
            if (a.export_order) return -1; // a comes first if it has a valid export_order
            if (b.export_order) return 1; // b comes first if it has a valid export_order
            return a.index - b.index; // Sort by index if both have null or 0 export_order
        });

        // Step 4: Assign export_order to items where export_order is null or 0
        let maxExportOrder = Math.max(
            ...sortedByTypeText.map(item => (item.export_order && item.export_order > 0) ? item.export_order : 0),
            0
        );

        return sortedByTypeText.map(item => ({
            ...item,
            export_order: (item.export_order && item.export_order > 0) ? item.export_order : ++maxExportOrder, // Assign new export_order if null or 0
        })).sort((a, b) => a.export_order - b.export_order);
    }




    function sortByIndexAndTypeText(arr: any[]) {
        // Step 1: Sort by index
        const sortedByIndex = [...arr].sort((a, b) => a.index - b.index);

        // Step 2: Group by type_text
        const groupedByTypeText: Record<string, any[]> = {};
        sortedByIndex.forEach(item => {
            if (!groupedByTypeText[item.type_text]) {
                groupedByTypeText[item.type_text] = [];
            }
            groupedByTypeText[item.type_text].push(item);
        });

        // Step 3: Flatten grouped data while maintaining order
        return Object.values(groupedByTypeText).flat();
    }



    const beforeUpload = (file: File) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            Modal.error({
                title: "Invalid file format",
                content: "Only JPG/PNG files are allowed.",
            });
            return Upload.LIST_IGNORE; // Ignore the file in Ant Design Upload
        }

        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            Modal.error({
                title: "File too large",
                content: "Each image must be smaller than 2MB.",
            });
            return Upload.LIST_IGNORE;
        }
        // Handle multiple file uploads properly
        // @ts-ignore
        setFileList((prevList) => [...prevList, {uid: file.uid, name: file.name, url: URL.createObjectURL(file),},]);

        return false; // Prevent automatic upload
    };

    useEffect(() => {
        if (fileList.length > 1) {
            if (addSurfacePictureFields["surface-image"].title === null) {
                dispatch(onUpdateCreateNewSurfaceImageField({fieldKey: 'surface-image.title', value: ''}))
            } else if (addSurfacePictureFields["surface-image"].description === null) {
                dispatch(onUpdateCreateNewSurfaceImageField({fieldKey: 'surface-image.description', value: ''}))
            }
        } else if (fileList.length <= 1) {
            dispatch(onUpdateCreateNewSurfaceImageField({fieldKey: 'surface-image.title', value: null}))
            dispatch(onUpdateCreateNewSurfaceImageField({fieldKey: 'surface-image.description', value: null}))
        }
    }, [fileList])

    const onUploadNewImage = async () => {
        setFieldsWithError(false)
        if (fileList.length === 0) {
            message.warn('Please add image for property in order to save it')
        } else if (
            (fileList.length === 1)
            &&
            (addSurfacePictureFields["surface-image"].type === 0)) {
            setFieldsWithError(true)
        } else if (fileList.length > 1 && addSurfacePictureFields["surface-image"].type === 0) {
            setFieldsWithError(true)
        } else {
            const getImageDimensions = (fileBlob: Blob): Promise<{ width: number; height: number }> => {
                return new Promise((resolve) => {
                    const img = new Image();
                    img.onload = () => {
                        resolve({width: img.width, height: img.height});
                    };
                    img.src = URL.createObjectURL(fileBlob);
                });
            };
            setIsRequestRunning(true);
            const uploadPromises = fileList.map(async (file: any, index: number) => {
                const formData = new FormData();
                const response = await fetch(file.url);
                const blob = await response.blob();
                const fileType = blob.type.split("/")[1] || "png";
                const {width, height} = await getImageDimensions(blob);
                // @ts-ignore
                const lastCategoryItem = formFields.filter((i) => Number(i.type) === Number(addSurfacePictureFields["surface-image"].type)).length
                const updatedPropertyImage = {
                    ...addSurfacePictureFields["surface-image"],
                    filename: file.name,
                    format: fileType,
                    width,
                    height,
                    index: index + 1 + lastCategoryItem
                };
                console.log(updatedPropertyImage)
                formData.append('photo', new File([blob], '', {type: `image/${fileType}`}));
                formData.append('surface-image', new Blob([JSON.stringify(updatedPropertyImage)], {type: 'application/json'}))
                return dispatch(CreateSurfacePicture({
                    surf_ref: currentSurface.SURF_REF,
                    reqData: formData
                }));
            });

            await Promise.all(uploadPromises);

            setTimeout(() => {
                setIsRequestRunning(false);
                dispatch(onResetCreateNewSurfaceImageFields());
                setOpenedAddImageModal(false);
                dispatch(onSetSurfacePictureFilteredFields([]));
                dispatch(GetSurfacePicturesByRef(currentSurface.SURF_REF))
                setFileList([])
                if (fileList.length === 1) {
                    message.success('Surface image has been added')
                } else {
                    message.success('Surface images has been added')
                }
            }, 500);

        }
    };

    const onPictureTypeChange = (value: any) => {
        const numberValue = propertiesPicturesTypesValues.find((p) => p.label === value)?.value || 0
        dispatch(onUpdateCreateNewSurfaceImageField({fieldKey: 'surface-image.type', value: numberValue}))
    }

    const onCloseAddPictureModal = () => {
        dispatch(onResetCreateNewSurfaceImageFields())
        setOpenedAddImageModal(false)
    }


    const onChangeViewMode = (viewMode: 'List' | 'Table') => {
            dispatch(onSetSurfacePictureFilteredFields([]))
            dispatch(onResetReadyToDeletePropertySurfaceFilteredFields())
            dispatch(GetSurfacePicturesByRef(currentSurface.SURF_REF))
                .then(() => {
                    setViewMode(viewMode)
                    if (sortMode === 'All Items'){
                        dispatch(onSetSurfacePictureFilteredFields(sortByTypeTextAndAssignExportOrder(defaultFromFields)))
                    }
                    else{
                        dispatch(onSetSurfacePictureFilteredFields(sortByIndexAndTypeText(defaultFromFields)))
                    }
                })
    }

    return (
        <div>
            {
                isDataLoading
                    ?
                    <div style={{
                        height: '52vh',
                        width: '400px',
                        margin: '0 auto',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }}>
                        <div style={{
                            height: '300px',
                            width: '100%',
                            textAlign: 'center'
                        }}>
                            <Lottie
                                // @ts-ignore
                                config={{animationData: animatedLoader, loop: true, height: '60px !important'}}
                            />
                        </div>

                        <p style={{
                            textAlign: 'center'
                        }}>Loading...</p>
                    </div>
                    :
                    <>
                        <div className={'flex-space-between-flex-end'} style={{
                            marginBottom: '30px',
                        }}>
                            <div style={{
                                display: 'flex',
                                gap: '16px',
                                alignItems: 'flex-end'
                            }}>
                                <div className={s.form__row} style={{
                                    width: '240px',
                                }}>
                                    <label>Image type</label>
                                    <Select
                                        onChange={(e) => onChangePropertyPictureType(e)}
                                        style={{width: '100%'}}
                                        placeholder={'Select type'}
                                        className={'reqCreateFormSelect'}
                                        value={propertyPictureType}
                                        disabled={sortMode === 'All Items'}
                                    >
                                        {
                                            propertiesPicturesTypesValues.map((d: { value: string, label: string }) => {
                                                return (
                                                    <option
                                                        key={d.value}
                                                        value={d.label}
                                                    >
                                                        {d.label}
                                                    </option>
                                                )
                                            })
                                        }
                                    </Select>
                                </div>
                                <div className={s.form__row} style={{
                                    width: '240px',
                                }}>
                                    <label>Sort mode</label>
                                    <Select
                                        onChange={(e) => onChangeSortMode(e)}
                                        style={{width: '100%'}}
                                        placeholder={'Select mode'}
                                        className={'reqCreateFormSelect'}
                                        value={sortMode}
                                    >
                                        {
                                            sortModeTypesValues.map((d: { value: string, label: string }) => {
                                                return (
                                                    <option
                                                        key={d.value}
                                                        value={d.label}
                                                    >
                                                        {d.label}
                                                    </option>
                                                )
                                            })
                                        }
                                    </Select>
                                </div>
                            </div>
                            <div className={s.viewButtons}>
                                <button onClick={() => onChangeViewMode('List')} className={viewMode === 'List' ? `${s.viewButtons__button} ${s.viewButtons__button_active}` : `${s.viewButtons__button}`}>
                                    <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 1H19M6 7H19M6 13H19M1 1H1.01M1 7H1.01M1 13H1.01" stroke="#101828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                                <div className={s.buttonDivider}></div>
                                <button onClick={() => onChangeViewMode('Table')} className={viewMode === 'Table' ? `${s.viewButtons__button} ${s.viewButtons__button_active}` : `${s.viewButtons__button}`}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 1H1V8H8V1Z" stroke="#2D2D2D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M19 1H12V8H19V1Z" stroke="#2D2D2D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M19 12H12V19H19V12Z" stroke="#2D2D2D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M8 12H1V19H8V12Z" stroke="#2D2D2D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        {
                            viewMode === 'List'
                            ?
                                formFields.length
                                ?
                                <div className={s.picturesInner}>
                                    {
                                        formFields.map((i) => {
                                            return (
                                                <div className={s.picturesList}>
                                                    <div className={s.picture}>
                                                        <PictureUploader i={i} isMainImage={false} pictureMode={'Surfaces'}/>
                                                        <div style={{
                                                            width: '100%'
                                                        }}>
                                                            <div className={s.picture__topBlock}>
                                                                <KindEditor i={i} pictureMode={'Surfaces'}/>
                                                                <div>
                                                                    <p className={s.created} style={{
                                                                        marginBottom: '2px'
                                                                    }}>
                                                                        <span>Created at</span> {i.date_created !== null ? format(new Date(i.date_created), 'dd.MM.yyyy') : ''} {i.created_by}
                                                                    </p>
                                                                    <p className={s.created} style={{
                                                                        marginTop: '0px',
                                                                        marginBottom: '0px'
                                                                    }}>
                                                                        <span>Updated at</span> {i.date_modified !== null ? format(new Date(i.date_modified), 'dd.MM.yyyy') : ''} {i.modified_by}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className={s.form__row}>
                                                                    <label>Title</label>
                                                                    <Input placeholder={'Enter title'}
                                                                           value={i.title}
                                                                           onChange={(e) => onFormInputChange(i.id, e, 'title', 'Edit')}/>
                                                                </div>
                                                                <div className={s.form__row} style={{
                                                                    marginTop: '6px'
                                                                }}>
                                                                    <label>Description</label>
                                                                    <Input placeholder={'Enter description'}
                                                                           value={i.description}
                                                                           onChange={(e) => onFormInputChange(i.id, e, 'description', 'Edit')}/>
                                                                </div>
                                                                <ShowMore
                                                                    i={i}
                                                                    onFormInputChange={onFormInputChange}
                                                                    formFields={formFields}
                                                                    propertyPictureType={propertyPictureType}
                                                                    sortMode={sortMode}
                                                                    pictureMode={'Surfaces'}
                                                                />
                                                            </div>
                                                        </div>
                                                        <Checkbox

                                                            className={`${s.deleteCheckbox} picturesCheckbox`}
                                                            // className="red-checkbox"
                                                            checked={i.isDeleteChecked}
                                                            onChange={(e) => dispatch(onSetReadyToDeleteSurfacePictureFilteredFields({id: i.id, readyToDelete: e.target.checked}))}
                                                        />
                                                    </div>


                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                    :
                                    <div style={{
                                        height: '400px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <Empty />
                                    </div>
                                :
                                <DraggableCategories
                                    propertiesPicturesTypesValues={propertiesPicturesTypesValues}
                                    formFields={formFields}
                                    propertyPictureType={propertyPictureType}
                                    sortMode={sortMode}
                                    picturesMode={'Surfaces'}
                                />
                        }
                    </>

            }
            {
                openedAddImageModal
                &&
                <CreationModal
                    title={'Add new surface image'}
                    image={
                        <div style={{
                            width: 48,
                            height: 48,
                            backgroundColor: '#F9F5FF',
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: '15px'
                        }}>
                            <FileImageOutlined style={{color: '#0707FA', width: '24px', height: '28px'}}/>
                        </div>
                    }
                    loading={requestRunning}
                    onSave={onUploadNewImage}
                    onCancel={onCloseAddPictureModal}
                    buttonOkText={'Add'}
                    buttonCancelText={'Cancel'}
                    width={'470px'}
                    primaryBtnWidth={'195px'}
                >
                    <div style={{
                        alignSelf: 'baseline'
                    }}>
                        <p className={s.uploadPropertyText}>Upload surface image</p>
                        <p className={s.uploadMultipleText}>Upload one or multiple images for this surface</p>
                    </div>
                    <div
                        className={`${s.picture__img} createPicturesUploader`}
                        style={{
                            width: '100%',
                            height: "auto",
                            marginRight: '0px',
                            maxWidth: '470px'
                        }}
                    >
                        <div style={{marginBottom: "6px"}}>
                            <Upload
                                name="media"
                                listType="picture-card"
                                className="media-uploader"
                                fileList={fileList}
                                beforeUpload={beforeUpload}
                                multiple={true}  // Allow multiple uploads
                                onRemove={(file) => {
                                    setFileList((prev) => prev.filter((item: any) => item.uid !== file.uid));
                                }}
                            >
                                {fileList.length >= 10 ? null : (  // Limit total file count if needed
                                    <div>
                                        <svg width="46" height="47" viewBox="0 0 46 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="3" y="3.5" width="40" height="40" rx="20" fill="#F2F4F7"/>
                                            <rect x="3" y="3.5" width="40" height="40" rx="20" stroke="#F9FAFB" strokeWidth="6"/>
                                            <g clipPath="url(#clip0_4580_20628)">
                                                <path d="M26.3326 26.8332L22.9992 23.4999M22.9992 23.4999L19.6659 26.8332M22.9992 23.4999V30.9999M29.9909 28.8249C30.8037 28.3818 31.4458 27.6806 31.8158 26.8321C32.1858 25.9835 32.2627 25.0359 32.0344 24.1388C31.8061 23.2417 31.2855 22.4462 30.5548 21.8778C29.8241 21.3094 28.925 21.0005 27.9992 20.9999H26.9492C26.697 20.0243 26.2269 19.1185 25.5742 18.3507C24.9215 17.5829 24.1033 16.9731 23.181 16.5671C22.2587 16.161 21.2564 15.9694 20.2493 16.0065C19.2423 16.0436 18.2568 16.3085 17.3669 16.7813C16.477 17.2541 15.7058 17.9225 15.1114 18.7362C14.517 19.55 14.1148 20.4879 13.9351 21.4794C13.7553 22.4709 13.8027 23.4903 14.0736 24.461C14.3445 25.4316 14.8319 26.3281 15.4992 27.0832" stroke="#475467" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_4580_20628">
                                                    <rect width="20" height="20" fill="white" transform="translate(13 13.5)"/>
                                                </clipPath>
                                            </defs>
                                        </svg>

                                        <div className={s.uploadText}><span>Click to upload</span> or drag and drop</div>
                                    </div>
                                )}
                            </Upload>
                        </div>

                        <div className={s.form__row} style={{
                            maxWidth: '100%'
                        }}>
                            <label>Image type</label>
                            <Select
                                onChange={(e) => onPictureTypeChange(e)}
                                style={{width: '100%'}}
                                placeholder={'Select type'}
                                className={'reqCreateFormSelect'}
                                value={propertiesPicturesTypesValues.find((p) => Number(p.value) === addSurfacePictureFields["surface-image"].type)?.label}
                            >
                                {
                                    propertiesPicturesTypesValues.filter((p) => p.value !== 'All').map((d: { value: string, label: string }) => {
                                        return (
                                            <option
                                                key={d.value}
                                                value={d.label}
                                            >
                                                {d.label}
                                            </option>
                                        )
                                    })
                                }
                            </Select>
                            {addSurfacePictureFields["surface-image"].type === 0 && fieldsWithError &&
                                <p className={s.form__error}>Please select type!</p>}
                        </div>
                        {
                            fileList.length <= 1
                            &&
                            <>
                                <div style={{
                                    marginTop: '6px'
                                }}>
                                    <div className={s.form__row}>
                                        <label>Title</label>
                                        <Input placeholder={'Enter title'}
                                               value={addSurfacePictureFields["surface-image"].title!!}
                                               onChange={(e) => onFormInputChange(0, e, 'surface-image.title', 'Add')}/>
                                    </div>
                                    <div className={s.form__row} style={{
                                        marginTop: '6px'
                                    }}>
                                        <label>Description</label>
                                        <Input placeholder={'Enter description'}
                                               value={addSurfacePictureFields["surface-image"].description!!}
                                               onChange={(e) => onFormInputChange(0, e, 'surface-image.description', 'Add')}/>
                                    </div>
                                </div>
                                <div className={s.additionalParams}>
                                    <p>Additional parameters</p>
                                    <div className={s.additionalParams__checkboxes} style={{
                                        justifyContent: 'space-between'
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            gap: '6px',
                                            alignItems: 'center'
                                        }} className={s.form__checkbox}>
                                            <Checkbox
                                                onChange={(e) => onFormInputChange(0, e.target.checked, 'surface-image.for_expose', 'Add')}
                                                checked={addSurfacePictureFields["surface-image"].for_expose === 1}/>
                                            <label className={s.form__label}>Web Exposé</label>
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            gap: '6px',
                                            alignItems: 'center'
                                        }} className={s.form__checkbox}>
                                            <Checkbox
                                                onChange={(e) => onFormInputChange(0, e.target.checked, 'surface-image.for_portal', 'Add')}
                                                checked={addSurfacePictureFields["surface-image"].for_portal === 1}/>
                                            <label className={s.form__label}>Web
                                                Portal, Rank order</label>
                                            <Input
                                                value={addSurfacePictureFields["surface-image"].export_order!!}
                                                disabled={addSurfacePictureFields["surface-image"].for_portal === 0}
                                                min={1}
                                                onChange={(e) => onFormInputChange(0, e, 'surface-image.export_order', 'Add')}
                                                type={'number'} style={{
                                                width: '90px',
                                                height: '24px',
                                                padding: '0 0 0 8px',
                                                marginLeft: '5px'
                                            }}/>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </CreationModal>
            }
        </div>
    );
};

export default EditSurfacesTab;