import React, {useEffect, useRef, useState} from "react";
import {
    DndContext,
    closestCenter,
    DragEndEvent,
    DragOverlay
} from "@dnd-kit/core";
import {
    SortableContext,
    useSortable,
    horizontalListSortingStrategy,
    rectSortingStrategy
} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import {Checkbox, Empty, message, Modal} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {useAppDispatch} from "../../../app/hooks";
import {
    DeleteBrokeragePropertyPicture, GetBrokeragePropertyPicturesByRef,
    onSetPropertyPictureFilteredFields, onSetReadyToDeletePropertyPictureFilteredFields,
    UpdatePropertyPictureIndex, UpdatePropertyPictureRankOrder,
} from "../../../store/propertiesReducer";
import s from "./EditProperty.module.scss";
import PictureUploader from "./EditPicturesTab/PictureUploader/PictureUploader";
import {sessionStorageUtilityValues} from "../../../helpers/sessionStorageHelper";
import {
    DeleteSurfacePicture,
    GetSurfacePicturesByRef,
    onSetSurfacePictureFilteredFields
} from "../../../store/surfacesReducer";

const SortableItem = ({item, isOverlay, isActive, pictureMode}: any) => {
    const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id: item.id});

    const dispatch = useAppDispatch();
    const currentBrokerageProperty = sessionStorageUtilityValues.getCurrentBrokerageReqProperty();
    const currentSurface = sessionStorageUtilityValues.getCurrentSurfacesForEdit()
    const style = {
        transform: isOverlay ? "none" : CSS.Transform.toString(transform), // Prevent scaling in overlay
        transition: isOverlay ? "none" : transition, // Prevent transition animations in overlay
        opacity: item.placeholder ? 0.3 : 1, // Placeholder transparency
        width: "calc(25% - 10px)", // ✅ Fixed width to prevent expansion
        height: "auto", // ✅ Maintain original height
        maxWidth: "250px", // ✅ Optional max width to ensure consistency
        maxHeight: "300px", // ✅ Optional max height
    };

    const handleDelete = (propId: number) => {
        if(pictureMode === 'Properties'){
            dispatch(DeleteBrokeragePropertyPicture(propId)).then(() => {
                setTimeout(() => {
                    dispatch(onSetPropertyPictureFilteredFields([]));
                    dispatch(GetBrokeragePropertyPicturesByRef(currentBrokerageProperty.PROP_ID));
                    message.success("Property image has been deleted");
                }, 500);
            });
        }
        else{
            dispatch(DeleteSurfacePicture(propId))
                .then(() => {
                    setTimeout(() => {
                        dispatch(onSetPropertyPictureFilteredFields([]));
                        dispatch(GetSurfacePicturesByRef(currentSurface.SURF_REF));
                        message.success('Surface image has been deleted')
                    } ,500)
                });
        }

    };

    const confirmDelete = (propId: number) => {
        Modal.confirm({
            title: "Are you sure you want to delete this image?",
            content: "This action cannot be undone.",
            okText: "Yes, delete it",
            cancelText: "Cancel",
            onOk: () => handleDelete(propId),
        });
    };

    return (
        <div
            ref={isOverlay ? undefined : setNodeRef} // ✅ Prevent overlay from inheriting ref
            {...(isOverlay ? {} : attributes)} // ✅ Prevent overlay from inheriting drag props
            {...(isOverlay ? {} : listeners)}
            className={`${s.pictures} ${isActive ? s.active : ""}`}
            style={style}
        >
            {!item.placeholder ? (
                <div className={s.picture}>
                    <PictureUploader i={item} isMainImage={false} isTableView={true} pictureMode={pictureMode}/>

                    {/* ❌ Prevent dragging when clicking on overlay */}
                    <div className={s.overlayBlock} data-no-dnd="true">
                        <p>Image number {item.id}</p>

                        {/* ❌ Prevent dragging when clicking delete icon */}
                        <DeleteOutlined
                            className={s.picture__icon}
                            data-no-dnd="true"
                            onPointerDown={(e) => {
                                e.stopPropagation();
                                confirmDelete(item.id);
                            }}
                        />
                    </div>

                    {/* ❌ Prevent dragging when clicking on checkbox */}
                    <div data-no-dnd="true" onPointerDown={(e) => e.stopPropagation()}>
                        <Checkbox
                            className={`${s.deleteCheckboxTable} picturesCheckbox`}
                            onChange={(e) =>
                                dispatch(
                                    onSetReadyToDeletePropertyPictureFilteredFields({
                                        id: item.id,
                                        readyToDelete: e.target.checked,
                                    })
                                )
                            }
                        />
                    </div>
                </div>
            ) : (
                <div className={s.placeholder}>Drop images here</div>
            )}
        </div>
    );
};


const DraggableCategories = ({propertiesPicturesTypesValues, formFields, propertyPictureType, sortMode, picturesMode}: any) => {
    const [items, setItems] = useState(formFields)
    const [activeItem, setActiveItem] = useState(null);
    const dispatch = useAppDispatch();
    const [activeCategory, setActiveCategory] = useState<string | null>(null);
    const [activeItemId, setActiveItemId] = useState<string | null>(null);
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setItems(formFields)
    }, [formFields])


    const handleDragOver = (event: any) => {
        const {over} = event;
        if (!over) return;

        const categoryElement = over.rect && document.elementFromPoint(over.rect.left, over.rect.top);
        if (categoryElement) {
            // @ts-ignore
            const hoveredCategory = categoryElement.closest("[data-category]")?.getAttribute("data-category");
            if (hoveredCategory) {
                setActiveCategory(hoveredCategory);
            }
        }
    };


    const handleDragStart = (event: any) => {
        const {active} = event;
        const target = event?.event?.target as HTMLElement; // Get clicked element

        // ❌ If clicking inside `data-no-dnd="true"`, do NOT start dragging
        if (target?.closest('[data-no-dnd="true"]')) {
            event.preventDefault();  // ✅ Stop drag event completely
            return;
        }
        setActiveItemId(event.active.id.toString());
        setActiveItem(items.find((item: any) => item.id === active.id));
    };

    const handleDragEnd = (event: DragEndEvent) => {
        const {active, over} = event;
        if (!over) return; // No valid drop target


        if (sortMode !== 'All Items') {
            let updatedItems = [...items];

            // Find dragged item index
            const oldIndex = updatedItems.findIndex((item: any) => item.id === active.id);
            if (oldIndex === -1) return;

            let newCategoryName: string | null = null;
            let newIndex: number | null = null;

            const targetId = over.id.toString();

            // ✅ 1️⃣ Check if dropping into an empty category (Placeholder)
            if (targetId.startsWith("placeholder-")) {
                newCategoryName = targetId.replace("placeholder-", ""); // Extract category name
                newIndex = 1; // Ensure it goes to the first position
            } else {
                // ✅ 2️⃣ Check if dropping on an actual item
                const targetItem = updatedItems.find((f) => f.id === over.id);
                if (targetItem) {
                    newCategoryName = targetItem.type_text;

                    // ✅ Get category items
                    const categoryItems = updatedItems.filter((item) => item.type_text === newCategoryName);

                    // ✅ Find exact position
                    let foundIndex = updatedItems.findIndex((item) => item.id === over.id);
                    newIndex = foundIndex === 0 ? 1 : foundIndex + 1; // If first item, force index 1

                    // ✅ If placing at the first position, ensure it gets index 1
                    if (over?.data?.current?.sortable.index === 0) {
                        newIndex = 1;
                    }
                } else {
                    // ✅ 3️⃣ If neither, try finding the category container
                    const categoryElement = over.rect && document.elementFromPoint(over.rect.left, over.rect.top);
                    if (categoryElement) {
                        // @ts-ignore
                        newCategoryName = categoryElement.closest("[data-category]")?.getAttribute("data-category");
                    }

                    // ✅ Get category items
                    const categoryItems = updatedItems.filter((item) => item.type_text === newCategoryName);

                    // ✅ Place at the last position if no specific target
                    newIndex = categoryItems.length + 1;
                }
            }

            if (!newCategoryName) return; // Ensure we have a valid category

            // ✅ Clone the dragged item & update category
            let draggedItem = {...updatedItems[oldIndex], type_text: newCategoryName};

            // Remove from old position
            updatedItems.splice(oldIndex, 1);

            // ✅ If placing at index 1, place at the beginning
            if (newIndex === 1) {
                updatedItems.unshift(draggedItem);
            } else {
                updatedItems.splice(newIndex - 1, 0, draggedItem);
            }

            // ✅ Adjust indexes only within the affected category
            updatedItems = updatedItems.map((item, index) => ({
                ...item,
                index: index + 1, // Ensure sequential indexing
            }));

            const type = propertiesPicturesTypesValues.find((i: any) => i.label === newCategoryName)?.value;
            dispatch(UpdatePropertyPictureIndex({
                id: draggedItem.id,
                reqData: {
                    index_old: active?.data?.current?.sortable.index + 1,
                    index_new: over?.data?.current?.sortable.index + 1,
                    type_new: Number(type!!)
                }
            })).then(() => {
                if(picturesMode === 'Properties'){
                    dispatch(onSetPropertyPictureFilteredFields(updatedItems));
                }
                else{
                    dispatch(onSetSurfacePictureFilteredFields(updatedItems));
                }
            });

            setItems(updatedItems);
            setActiveCategory(null);
            setActiveItemId(null);
        }
        else {
            // ✅ New logic for sorting by `export_order`
            let updatedItems = [...items];

            // Find dragged item index
            const oldIndex = updatedItems.findIndex((item: any) => item.id === active.id);
            const newIndex = updatedItems.findIndex((item: any) => item.id === over.id);

            if (oldIndex === -1 || newIndex === -1) return;

            // ✅ Capture old & new export_order before moving
            const oldExportOrder = updatedItems[oldIndex].export_order;
            const newExportOrder = updatedItems[newIndex].export_order;

            // ✅ Move item in array
            const [movedItem] = updatedItems.splice(oldIndex, 1);
            updatedItems.splice(newIndex, 0, movedItem);

            // ✅ Assign new `export_order` values sequentially
            updatedItems = updatedItems.map((item, index) => ({
                ...item,
                export_order: index + 1 // Assign `export_order` sequentially
            }));

            // ✅ Update backend with actual `export_order` values
            dispatch(UpdatePropertyPictureRankOrder({
                id: movedItem.id,
                reqData: {
                    export_order_old: oldExportOrder, // 🔹 Pass old export_order
                    export_order_new: newExportOrder  // 🔹 Pass new export_order
                }
            })).then(() => {
                if(picturesMode === 'Properties'){
                    dispatch(onSetPropertyPictureFilteredFields(updatedItems.sort((a, b) => a.export_order - b.export_order)));
                }
                else{
                    dispatch(onSetSurfacePictureFilteredFields(updatedItems.sort((a, b) => a.export_order - b.export_order)));
                }

            });

            setItems(updatedItems);
        }



    };


    const handleWheel = (event: React.WheelEvent) => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop += event.deltaY; // ✅ Move scroll container with wheel
        }
    };

    return (
        formFields.length
            ?
            <DndContext
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                onDragStart={handleDragStart}
                onDragOver={handleDragOver}
            >
                {
                    sortMode !== 'All Items'
                        ?
                        propertiesPicturesTypesValues
                            .filter((item: any) => item.label !== "All")
                            .map((category: any) => {
                                const matchingItems = items
                                    .filter((i: any) => i.type_text === category.label)
                                    .map((item: any, index: number) => item ? {...item, index: index + 1} : index)
                                    .sort((a: any, b: any) => a.index - b.index);
                                // ✅ Add a placeholder if the category is empty
                                if (matchingItems.length === 0) {
                                    matchingItems.push({
                                        id: `placeholder-${category.label}`,
                                        type_text: category.label,
                                        placeholder: true,
                                    });
                                }

                                return (
                                    propertyPictureType === 'All'
                                        ?
                                        <div
                                            key={category.value}
                                            id={`category-${category.value}`}   // ✅ Unique ID for category
                                            data-category={category.label}     // ✅ Pass category label
                                            className={`${s.categoryContainer} ${activeCategory === category.label ? s.activeCategory : ""}`}
                                        >
                                            <h3>{category.label}</h3>
                                            <SortableContext items={matchingItems} strategy={rectSortingStrategy}>
                                                <div
                                                    className={s.pictureRowsContainer}
                                                    data-category={category.label}
                                                    ref={scrollContainerRef} // ✅ Attach ref to container
                                                    onWheel={handleWheel}// ✅ This will be used to find the category
                                                >
                                                    {matchingItems.map((item: any) => (
                                                        <SortableItem key={item.id} item={item}
                                                                      isActive={activeItemId === item.id} pictureMode={picturesMode}/>
                                                    ))}
                                                </div>
                                            </SortableContext>
                                        </div>
                                        :
                                        matchingItems.length > 0 && propertyPictureType === category.label ?
                                            <div
                                                key={category.value}
                                                id={`category-${category.value}`}   // ✅ Unique ID for category
                                                data-category={category.label}     // ✅ Pass category label
                                                className={s.categoryContainer}
                                            >
                                                {
                                                    propertyPictureType === category.label
                                                    &&
                                                    <>
                                                        <h3>{category.label}</h3>
                                                    </>
                                                }

                                                <SortableContext items={matchingItems}
                                                                 strategy={horizontalListSortingStrategy}>
                                                    <div
                                                        className={s.pictureRowsContainer}
                                                        data-category={category.label}
                                                        ref={scrollContainerRef} // ✅ Attach ref to container
                                                        onWheel={handleWheel}// ✅ This will be used to find the category
                                                    >

                                                        {matchingItems.map((item: any) => (
                                                            <SortableItem key={item.id} item={item}
                                                                          isActive={activeItemId === item.id} pictureMode={picturesMode}/>
                                                        ))}

                                                    </div>
                                                </SortableContext>
                                            </div>
                                            :
                                            null


                                );
                            })
                        :
                        <div
                            className={s.pictureRowsContainer}
                            ref={scrollContainerRef} // ✅ Attach ref to container
                            onWheel={handleWheel}// ✅ This will be used to find the category
                        >


                                        <SortableContext items={items} strategy={horizontalListSortingStrategy}>


                                            {items.map((item: any) => (
                                                <SortableItem key={item.id} item={item}
                                                              isActive={activeItemId === item.id} pictureMode={picturesMode}/>
                                            ))}


                                        </SortableContext>





                        </div>
                }
                {/* ✅ Drag Overlay to prevent UI glitches */}
                <DragOverlay>
                    {activeItem ? <SortableItem item={activeItem}/> : null}
                </DragOverlay>
            </DndContext>
            :
            <div style={{
                height: '400px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Empty/>
            </div>

    );
};

export default DraggableCategories;














