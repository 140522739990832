import React, {useState, useMemo, useEffect, useRef, useCallback} from 'react';
import {
    BrokerageCheckedReq,
    brokerageReqCheckedReqs,
    CheckIfContactIsInBlackListThunk, onAddOwnerContact, onChangeBrokerageCreateContactReqId,
    onRemoveBrokerageReqContactToError, onSetBrokerageOfferContacts,
    onSetBrokerageOfferDefaultContacts, onSetCreateContactModalOpened,
    onSetFilteredPropertiesOfferBCCContacts,
    onSetFilteredPropertiesOfferCCContacts,
    onSetFilteredPropertiesOfferToContacts, onSetOwnerContacts,
    onSetPropertiesOfferFormError,
    selectContactsForEmail,
    selectDefaultContact,
    selectDefaultContactChecked,
    selectIsPropertiesBCCError,
    selectIsPropertiesCCError,
    selectIsPropertiesToError, selectOwnerContacts
} from "../../store/propertiesReducer";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import s from "./Properties.module.scss";
import {InputLabel} from "@mui/material";
import searchIcon from "../../img/icons/searchTable.svg";
import {Empty} from "antd";
import PropertyListComponentItem from "./PropertyListComponentItem";
import {setAppStatus, setErrorMessage} from "../../store/appStatusReducer";
import PropertyListCheckedContact from "./PropertyListCheckedContact";
import PropertyListUncheckedContact from "./PropertyListUncheckedContact";
import {selectBrokeragePropertyOfferType, selectBrokeragePropertyOwnerContacts} from "../../store/brokerageReqsReducer";
import {FacebookCircularProgress} from "../AllPropertiesAddressForm/AllPropertiesAddressForm";
import Tooltip from "../Tooltip/Tooltip";
import plusImg from './../../img/icons/plus-circle.png'
import {selectUserData} from "../../store/userReducer";

export interface ContactDiv {
    label: string
    labelEmail: string
    value: string
    contactRef?: string
    companyRef?: string
}

export interface AllPropertyContact {
    label: string
    labelEmail: string
    labelName: string
    value: string
    contactRef?: string
    companyRef?: string
}

export interface CheckedAndUncheckedPropertyContact {
    IS_MAIL: number | null
    PE_EMAIL: string | null
    PE_FIRST_NAME: string | null
    PE_FORM_GREETING: string | null
    PE_GENDER_TITLE: string | null
    PE_IS_MAIL: number | null
    PE_JOB_POSITION: null | string
    PE_LANG_INDEX: number | null
    PE_LAST_NAME: string | null
    PE_PROFESSION_TITLE: null | string
    REF_REQUEST: number | null
}


const chunkContactsArray = (array: {label: string, labelEmail: string, labelName: string, value: string, contactRef?: string
    companyRef?: string}[], chunkSize: number) => {
    return array.reduce((acc: any[], _, index: number) => {
        if (index % chunkSize === 0) acc.push(array.slice(index, index + chunkSize));
        return acc;
    }, []);
};

function ContactList(props: any) {
    const {
        contacts,
        isVisible,
        inputValue,
        onAddContact,
        contactDivs,
        isBrokerage,
        defaultBrokerageContacts,
        reqId,
        uncheckedBrokerageContacts
    } = props;

    const defaultContact = useAppSelector(selectDefaultContact);
    const uncheckedContact = useAppSelector(selectDefaultContactChecked);
    const dispatch = useAppDispatch();
    const offerType = useAppSelector(selectBrokeragePropertyOfferType);
    const allPropertiesCheckedReqs = useAppSelector(brokerageReqCheckedReqs);
    const [visibleContacts, setVisibleContacts] = useState<{label: string, labelEmail: string, labelName: string, value: string, contactRef?: string
        companyRef?: string}[]>([]);
    const [chunks, setChunks] = useState<{label: string, labelEmail: string, labelName: string, value: string, contactRef?: string
        companyRef?: string}[][]>([]);
    const [currentChunk, setCurrentChunk] = useState(0);
    const observer = useRef<IntersectionObserver | null>(null);

    const defaultContacts = useMemo(() => (
        isBrokerage
            ? allPropertiesCheckedReqs.length > 1
                ? defaultBrokerageContacts
                : defaultContact
            : defaultContact
    ), [isBrokerage, allPropertiesCheckedReqs, defaultBrokerageContacts, defaultContact]);

    const uncheckedContacts = useMemo(() => (
        isBrokerage ? uncheckedBrokerageContacts : uncheckedContact
    ), [isBrokerage, uncheckedBrokerageContacts, uncheckedContact]);

    const filteredContacts = useMemo(() => contacts?.filter((contact: any) => {
        const values = Object.values(contact);
        return values.some((value) =>
            String(value).toLowerCase().includes(inputValue.toLowerCase())
        );
    }).filter((c: any) => c.labelEmail !== 'undefined'), [contacts, inputValue]);

    const handleClick = useCallback((contact: any) => {
        onAddContact(contact);

    }, [onAddContact]);

    const onContactCreate = useCallback(() => {
        dispatch(onSetCreateContactModalOpened(true));
        dispatch(onChangeBrokerageCreateContactReqId(reqId));
    }, [dispatch, reqId]);

    const loadMoreContacts = useCallback(() => {
        if (currentChunk < chunks.length - 1) {
            setCurrentChunk((prevChunk) => {
                const nextChunk = prevChunk + 1;
                setVisibleContacts((prevContacts) => [...prevContacts, ...chunks[nextChunk]]);
                return nextChunk;
            });
        }
    }, [chunks, currentChunk]);


    const lastContactRef = useCallback((node: HTMLDivElement | null) => {
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                loadMoreContacts();
            }
        });
        if (node) observer.current.observe(node);
    }, [loadMoreContacts]);

    useEffect(() => {
        const chunkedContacts = chunkContactsArray(filteredContacts, 100);
        setChunks(chunkedContacts);
        setVisibleContacts(chunkedContacts[0] || []);
    }, [filteredContacts]);

    if (!isVisible) {
        return null;
    }

    return (
        <div className={`${s.offerListInner} offerList`}>
            <div style={{ height: '400px', overflowY: 'scroll', width: '100%', overflowX: 'hidden' }}>
                {props.gridType !== 'All Properties/Upcoming' && offerType !== 'Letter to the owner' && (
                    <>
                        {defaultContacts.some((c: any) => c.PE_EMAIL !== null) && (
                            <p className={s.offerToTitle}>Requirement contacts (checked)</p>
                        )}
                        {defaultContacts.map((defaultContact: any, index: number) => (
                            defaultContact.PE_EMAIL !== null && (
                                <PropertyListCheckedContact
                                    key={index}
                                    defaultContact={defaultContact}
                                    handleClick={handleClick}
                                    contactDivs={contactDivs}
                                />
                            )
                        ))}
                        {uncheckedContacts.some((c: any) => c.PE_EMAIL !== null) && (
                            <p className={s.offerToTitle}>Requirement contacts (unchecked)</p>
                        )}
                        {uncheckedContacts.map((uncheckedContact: CheckedAndUncheckedPropertyContact, index: number) => (
                            uncheckedContact.PE_EMAIL !== null && (
                                <PropertyListUncheckedContact
                                    key={index}
                                    uncheckedContact={uncheckedContact}
                                    handleClick={handleClick}
                                    contactDivs={contactDivs}
                                />
                            )
                        ))}
                    </>
                )}
                <p className={s.offerToTitle}>All contacts</p>
                {visibleContacts.length ? (
                    visibleContacts.map((contact, index) => (
                        <div ref={visibleContacts.length === index + 1 ? lastContactRef : null} key={index}>
                            <PropertyListComponentItem
                                contact={contact}
                                index={index}
                                handleClick={handleClick}
                                contactDivs={contactDivs}
                            />
                        </div>
                    ))
                ) : (
                    <Empty />
                )}
            </div>
            {props.gridType !== 'All Properties/Upcoming' && props.gridType !== 'Map' && props.gridType !== 'RRSurfaces' && (
                <div className={s.offerSelectCreateContact} onClick={onContactCreate}>
                    <img src={plusImg} alt="plus" />
                    <span> Create new contact</span>
                </div>
            )}
        </div>
    );
}

function ContactInput(props: any) {
    const {
        options,
        onAddContact,
        isContactVisible,
        setIsContactVisible,
        isBrokerage,
        defaultContacts,
        reqId,
        uncheckedContacts
    } = props;
    const [inputValue, setInputValue] = useState('');
    const handleInputChange = (event: any) => {
        setInputValue(event.target.value);
        setIsContactVisible(true);

    };

    const handleInputKeyDown = (event: any) => {
        if (event.key === 'Enter' || event.key === 'Tab') {
            const contactMatch = options.flatMap(({options}: any) => options).find((option: any) =>
                option.label.toLowerCase() === inputValue.toLowerCase()
            );
            onAddContact(contactMatch || {value: inputValue, label: inputValue});
            setInputValue('');
        }
    };

    return (
        isContactVisible &&
        <div
            style={{
                width: '100%'
            }}
        >
            <div
                className={s.offerInputInner}
            >
                <InputLabel htmlFor="searchInput" style={{marginRight: '8px'}}>
                    <img src={searchIcon} alt="search"/>
                </InputLabel>
                <input
                    id={'searchInput'}
                    className={s.offerInput}
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                    onFocus={() => setIsContactVisible(true)}
                    disabled={false}
                />

            </div>
            <ContactList
                contacts={options.flatMap(({options}: any) => options)}
                isVisible={isContactVisible}
                inputValue={inputValue}
                onAddContact={onAddContact}
                contactDivs={props.contactDivs}
                gridType={props.gridType}
                isBrokerage={isBrokerage}
                defaultBrokerageContacts={defaultContacts}
                reqId={reqId}
                uncheckedBrokerageContacts={uncheckedContacts}
            />

        </div>
    );
}

function ContactInputCreate(props: any) {
    const {options, onAddContact} = props;
    const [inputValue, setInputValue] = useState('');


    const handleInputChange = (event: any) => {
        setInputValue(event.target.value);
    };

    const [focusedField, setFocusedField] = useState<string | null>(null);

    const handleFocus = (fieldName: string) => {
        setFocusedField(fieldName);
    };

    const handleBlur = () => {
        setFocusedField(null);
    };


    const handleInputKeyDown = (event: any) => {
        if (event.key === 'Enter' || event.key === 'Tab') {
            event.preventDefault()
            const contactMatch = options.flatMap(({options}: any) => options).find((option: any) =>
                option?.label?.toLowerCase() === inputValue?.toLowerCase()
            );
            onAddContact(contactMatch || {value: inputValue, label: inputValue});
            setInputValue('');
        }
    };

    return (
        <div>
            <div
            >
                <input
                    className={s.offerInputSecond}
                    type="text"
                    name={props.inputName}
                    autoComplete="off"
                    readOnly
                    onFocus={(e) => e.target.removeAttribute("readonly")}
                    onBlur={(e) => e.target.setAttribute("readonly", "true")}
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                />

            </div>
        </div>
    );
}


function PropertyListComponent(props: any) {
    const contactsForEmail = useAppSelector(selectContactsForEmail)
    const defaultContacts = useAppSelector(selectDefaultContact)
    const isPropertiesToError = useAppSelector(selectIsPropertiesToError)
    const isPropertiesCCError = useAppSelector(selectIsPropertiesCCError)
    const isPropertiesBCCError = useAppSelector(selectIsPropertiesBCCError)
    const [isContactListVisible, setIsContactListVisible] = useState(false);
    const dispatch = useAppDispatch()
    const brokerageOwnerContacts = useAppSelector(selectBrokeragePropertyOwnerContacts)
    const offerType = useAppSelector(selectBrokeragePropertyOfferType)
    const allPropertiesCheckedReqs = useAppSelector(brokerageReqCheckedReqs)
    const ownerContacts = useAppSelector(selectOwnerContacts)
    const userData = useAppSelector(selectUserData)


    const defaultContact =
        props.isBrokerage
            ?
            props.contactType === 'to'
                ?
                props.req?.defaultContacts!!
                : defaultContacts
            :
            props.gridType === 'All Properties/Upcoming'
                ?
                props.contactType === 'to'
                    ?
                    ownerContacts
                    : defaultContacts
                :
                defaultContacts

    const memoizedOptions = useMemo(() => [
        {
            label: 'Requirement contacts',
            options:
                props.isBrokerage
                    ?
                    offerType === 'Letter to the owner'
                        ?
                        []
                        :
                        [
                            defaultContact[0]?.PE_EMAIL !== null
                            &&
                            {
                                value: defaultContact[0]?.PE_EMAIL,
                                label: `${defaultContact[0]?.PE_FIRST_NAME} ${defaultContact[0]?.PE_LAST_NAME} ${defaultContact[0]?.PE_EMAIL}`,
                                labelName: `${defaultContact[0]?.PE_FIRST_NAME} ${defaultContact[0]?.PE_LAST_NAME}`,
                                labelEmail: `${defaultContact[0]?.PE_EMAIL}`
                            },
                            defaultContact[0]?.PE_EMAIL2 !== null
                            &&
                            {
                                value: defaultContact[0]?.PE_EMAIL2,
                                label: `${defaultContact[0]?.PE_FIRST_NAME} ${defaultContact[0]?.PE_LAST_NAME} ${defaultContact[0]?.PE_EMAIL2}`,
                                labelName: `${defaultContact[0]?.PE_FIRST_NAME} ${defaultContact[0]?.PE_LAST_NAME}`,
                                labelEmail: `${defaultContact[0]?.PE_EMAIL2}`
                            }
                        ]
                    :
                    [
                        defaultContact[0]?.PE_EMAIL !== null
                        &&
                        {
                            value: defaultContact[0]?.PE_EMAIL,
                            label: `${defaultContact[0]?.PE_FIRST_NAME} ${defaultContact[0]?.PE_LAST_NAME} ${defaultContact[0]?.PE_EMAIL}`,
                            labelName: `${defaultContact[0]?.PE_FIRST_NAME} ${defaultContact[0]?.PE_LAST_NAME}`,
                            labelEmail: `${defaultContact[0]?.PE_EMAIL}`
                        },
                        defaultContact[0]?.PE_EMAIL2 !== null
                        &&
                        {
                            value: defaultContact[0]?.PE_EMAIL2,
                            label: `${defaultContact[0]?.PE_FIRST_NAME} ${defaultContact[0]?.PE_LAST_NAME} ${defaultContact[0]?.PE_EMAIL2}`,
                            labelName: `${defaultContact[0]?.PE_FIRST_NAME} ${defaultContact[0]?.PE_LAST_NAME}`,
                            labelEmail: `${defaultContact[0]?.PE_EMAIL2}`
                        }
                    ]
        },
        {
            label: 'All contacts',
            options: props.isBrokerage
                ?
                offerType === 'Letter to the owner'
                    ?
                    brokerageOwnerContacts.filter((c: any) => c.PE_EMAIL !== null).map((contact: any) => ({
                        value: contact.PE_EMAIL,
                        label: `${contact.PE_FIRST_NAME} ${contact.PE_LAST_NAME} ${contact.PE_EMAIL}`,
                        labelName: `${contact.PE_FIRST_NAME} ${contact.PE_LAST_NAME}`,
                        labelEmail: `${contact.PE_EMAIL} `
                    }))
                    :
                    contactsForEmail.map((contact: any) => ({
                        value: contact.PE_EMAIL,
                        label: `${contact.PE_FIRST_NAME} ${contact.PE_LAST_NAME} ${contact.PE_EMAIL}`,
                        labelName: `${contact.PE_FIRST_NAME} ${contact.PE_LAST_NAME}`,
                        labelEmail: `${contact.PE_EMAIL}`,
                        contactRef:  `${contact.PE_REF}`,
                        companyRef: `${contact.PE_XREF_COMPANY}`
                    }))
                :
                contactsForEmail.map((contact: any) => ({
                    value: contact.PE_EMAIL,
                    label: `${contact.PE_FIRST_NAME} ${contact.PE_LAST_NAME} ${contact.PE_EMAIL}`,
                    labelName: `${contact.PE_FIRST_NAME} ${contact.PE_LAST_NAME}`,
                    labelEmail: `${contact.PE_EMAIL}`,
                    contactRef:  `${contact.PE_REF}`,
                    companyRef: `${contact.PE_XREF_COMPANY}`
                }))
        },
    ], [defaultContact, contactsForEmail, brokerageOwnerContacts, offerType, props.isBrokerage]);


    // useEffect(() => {
    //     if (offerType === 'Offer subscription') {
    //         setContactDivs([])
    //     } else {
    //         setContactDivs([])
    //     }
    // }, [offerType])


    const [contactDivs, setContactDivs] = useState(props.contactType === 'to' ? [
        memoizedOptions[0]?.options[0] !== false
        &&
        {
            value: memoizedOptions[0]?.options[0]?.value,
            label: memoizedOptions[0]?.options[0]?.label,
            labelEmail: memoizedOptions[0]?.options[0]?.labelEmail
        },
        memoizedOptions[0]?.options[1] !== false
        &&
        {
            value: memoizedOptions[0]?.options[1]?.value,
            label: memoizedOptions[0]?.options[1]?.label,
            labelEmail: memoizedOptions[0]?.options[1]?.labelEmail
        }
    ].filter((c: any) => c.value !== undefined && c !== false)
        : []);

    useEffect(() => {
        if (props.gridType === 'All Properties/REQ') {
            if (props.req?.contacts) {
                setContactDivs(props.req?.contacts.flat(1))
            } else {
                setContactDivs([])
            }
        }

    }, [props])

    useEffect(() => {
        if (props.gridType === 'All Properties/Upcoming' && props.contactType === 'to') {
            setContactDivs(ownerContacts)
            ownerContacts.forEach((c: any) => {
                if (c.labelEmail) {
                    dispatch(onSetFilteredPropertiesOfferToContacts([...contactDivs, {
                        value: c.value,
                        label: c.label,
                        labelEmail: c.labelEmail
                    }]))
                }
            })
        }
    }, [ownerContacts, contactDivs, dispatch, props.contactType, props.gridType])

    useEffect(() => {
        if (props.contactType === 'bcc') {
            handleAddContact({
                value: userData.username,
                label: userData.username,
                labelEmail: userData.email
            })
        }
    }, [props.gridType, props.contactType])

    // remove error on add the contact
    const handleAddContact = (contact: any) => {
        const contactAlreadyExists = contactDivs.some((c: any) => c.value === contact.value);
        const contactFromAllContactsArray = memoizedOptions[1].options.find((c: any) => c.value.trim() === contact.value.replace(/^[\s\\]+/, '').trim())
        dispatch(CheckIfContactIsInBlackListThunk(contact.value))
            .then((response) => {
                // @ts-ignore
                if (response.payload.is_blacked_list === false) {
                    if (!contactAlreadyExists) {
                        // @ts-ignore
                        setContactDivs([...contactDivs, {value: contactFromAllContactsArray ? contactFromAllContactsArray.value : contact.value, label: contactFromAllContactsArray ? contactFromAllContactsArray.label : contact.label, labelEmail: contactFromAllContactsArray ? contactFromAllContactsArray.value : contact.value, contactRef: contactFromAllContactsArray ? contactFromAllContactsArray.contactRef : `${contact.contactRef}`, companyRef: contactFromAllContactsArray ? contactFromAllContactsArray.companyRef : `${contact.companyRef}`}])
                        if (props.contactType === 'to') {
                            if (props.gridType !== 'All Properties/REQ') {
                                // @ts-ignore
                                dispatch(onSetFilteredPropertiesOfferToContacts([...contactDivs, {value: contactFromAllContactsArray ? contactFromAllContactsArray.value : contact.value, label: contactFromAllContactsArray ? contactFromAllContactsArray.label : contact.label, labelEmail: contactFromAllContactsArray ? contactFromAllContactsArray.value : contact.value, contactRef: contactFromAllContactsArray ? contactFromAllContactsArray.contactRef : `${contact.contactRef}`, companyRef: contactFromAllContactsArray ? contactFromAllContactsArray.companyRef : `${contact.companyRef}`}]))
                                dispatch(onSetPropertiesOfferFormError({fieldType: 'To', isError: false}))
                            }
                            else if(props.gridType === 'All Properties/Upcoming'){
                                dispatch(onAddOwnerContact({
                                    label: contact.label,
                                    labelEmail: contact.value,
                                    labelName: contact.label,
                                    value: contact.value
                                }))
                            }
                            else {
                                dispatch(onSetBrokerageOfferContacts({
                                    reqId: props.req.REQ_ID!, contacts: [...contactDivs, {
                                        value: contact.value,
                                        label: contact.label,
                                        labelEmail: contact.value
                                    }]
                                }))
                                if (allPropertiesCheckedReqs.some((r: BrokerageCheckedReq) => r.isEmptyContactTo)) {
                                    dispatch(onSetPropertiesOfferFormError({fieldType: 'To', isError: true}))
                                } else {
                                    dispatch(onSetPropertiesOfferFormError({fieldType: 'To', isError: false}))
                                }

                            }

                        } else if (props.contactType === 'cc') {
                            // @ts-ignore
                            dispatch(onSetFilteredPropertiesOfferCCContacts([...contactDivs, {value: contactFromAllContactsArray ? contactFromAllContactsArray.value : contact.value, label: contactFromAllContactsArray ? contactFromAllContactsArray.label : contact.label, labelEmail: contactFromAllContactsArray ? contactFromAllContactsArray.value : contact.value, contactRef: contactFromAllContactsArray ? contactFromAllContactsArray.contactRef : `${contact.contactRef}`, companyRef: contactFromAllContactsArray ? contactFromAllContactsArray.companyRef : `${contact.companyRef}`}]))
                            dispatch(onSetPropertiesOfferFormError({fieldType: 'CC', isError: false}))
                        } else if (props.contactType === 'bcc') {
                            // @ts-ignore
                            dispatch(onSetFilteredPropertiesOfferBCCContacts([...contactDivs, {value: contactFromAllContactsArray ? contactFromAllContactsArray.value : contact.value, label: contactFromAllContactsArray ? contactFromAllContactsArray.label : contact.label, labelEmail: contactFromAllContactsArray ? contactFromAllContactsArray.value : contact.value, contactRef: contactFromAllContactsArray ? contactFromAllContactsArray.contactRef : `${contact.contactRef}`, companyRef: contactFromAllContactsArray ? contactFromAllContactsArray.companyRef : `${contact.companyRef}`}]))
                            dispatch(onSetPropertiesOfferFormError({fieldType: 'BCC', isError: false}))
                        }
                    }
                    else {
                        setContactDivs(contactDivs.filter((c: any) => c.value !== contact.value))
                        if (props.contactType === 'to') {
                            if (props.gridType !== 'All Properties/REQ') {
                                dispatch(onSetFilteredPropertiesOfferToContacts(contactDivs.filter((c: any) => c.value !== contact.value)))
                            } else {
                                dispatch(onSetBrokerageOfferContacts({
                                    reqId: props.req.REQ_ID!,
                                    contacts: contactDivs.filter((c: any) => c.value !== contact.value)
                                }))
                            }
                        } else if (props.contactType === 'cc') {
                            dispatch(onSetFilteredPropertiesOfferCCContacts(contactDivs.filter((c: any) => c.value !== contact.value)))
                        } else if (props.contactType === 'bcc') {
                            dispatch(onSetFilteredPropertiesOfferBCCContacts(contactDivs.filter((c: any) => c.value !== contact.value)))
                        }

                    }

                    dispatch(onRemoveBrokerageReqContactToError({reqId: props?.req?.REQ_ID!, isEmptyContactTo: false}))

                } else {
                    dispatch(setAppStatus(3))
                    dispatch(setErrorMessage('This contact can not be selected as a recipient'))
                    setTimeout(() => {
                        dispatch(setAppStatus(0))
                    }, 7000)
                }
            })
        // if(){
        //
        // }

    };

    const handleDeleteContact = (contact: any) => {
        setContactDivs(contactDivs.filter((c: any) => c.value !== contact))
        if (props.contactType === 'to') {
            if (props.gridType !== 'All Properties/REQ') {
                dispatch(onSetFilteredPropertiesOfferToContacts(contactDivs.filter((c: any) => c.value !== contact)))
            } else {
                dispatch(onSetBrokerageOfferContacts({
                    reqId: props.req.REQ_ID!,
                    contacts: contactDivs.filter((c: any) => c.value !== contact)
                }))
                if (contactDivs.length === 1) {
                    dispatch(onRemoveBrokerageReqContactToError({reqId: props.req.REQ_ID!, isEmptyContactTo: true}))
                    dispatch(onSetBrokerageOfferDefaultContacts({reqId: props.req.REQ_ID!, defaultContacts: []}))
                    setContactDivs([])
                }

            }


        } else if (props.contactType === 'cc') {
            dispatch(onSetFilteredPropertiesOfferCCContacts(contactDivs.filter((c: any) => c.value !== contact)))
        } else if (props.contactType === 'bcc') {
            dispatch(onSetFilteredPropertiesOfferBCCContacts(contactDivs.filter((c: any) => c.value !== contact)))
        }
    }

    useEffect(() => {
        if(props.gridType === 'All Properties/Upcoming' && !ownerContacts.length){
            dispatch(onSetFilteredPropertiesOfferToContacts([
                memoizedOptions[0]?.options[0] !== false
                &&
                {
                    value: memoizedOptions[0]?.options[0]?.value,
                    label: memoizedOptions[0]?.options[0]?.label,
                    labelEmail: memoizedOptions[0]?.options[0]?.labelEmail
                },
                memoizedOptions[0]?.options[1] !== false
                &&
                {
                    value: memoizedOptions[0]?.options[1]?.value,
                    label: memoizedOptions[0]?.options[1]?.label,
                    labelEmail: memoizedOptions[0]?.options[1]?.labelEmail
                }
            ]))
        }
        else{
            // @ts-ignore
            dispatch(onSetFilteredPropertiesOfferToContacts([memoizedOptions[0]?.options[0] !== false && {value: memoizedOptions[0]?.options[0]?.value, label: memoizedOptions[0]?.options[0]?.label, labelEmail: memoizedOptions[0]?.options[0]?.labelEmail, contactRef:  `${memoizedOptions[0]?.options[1]?.contactRef}`, companyRef: `${memoizedOptions[0]?.options[1]?.companyRef}`},memoizedOptions[0]?.options[1] !== false && {value: memoizedOptions[0]?.options[1]?.value, label: memoizedOptions[0]?.options[1]?.label, labelEmail: memoizedOptions[0]?.options[1]?.labelEmail, contactRef:  `${memoizedOptions[0]?.options[1]?.contactRef}`, companyRef: `${memoizedOptions[0]?.options[1]?.companyRef}`}]))
        }
    }, [dispatch, memoizedOptions, ownerContacts, props.gridType])

    useEffect(() => {
        if (props.gridType !== 'All Properties/Upcoming' && props.contactType === 'to') {
            // @ts-ignore
            setContactDivs([memoizedOptions[0]?.options[0] !== false && {value: memoizedOptions[0]?.options[0]?.value, label: memoizedOptions[0]?.options[0]?.label, labelEmail: memoizedOptions[0]?.options[0]?.labelEmail, contactRef:  `${memoizedOptions[0]?.options[0]?.contactRef}`, companyRef: `${memoizedOptions[0]?.options[0]?.companyRef}`}, memoizedOptions[0]?.options[1] !== false && {value: memoizedOptions[0]?.options[1]?.value, label: memoizedOptions[0]?.options[1]?.label, labelEmail: memoizedOptions[0]?.options[1]?.labelEmail, contactRef:  `${memoizedOptions[0]?.options[1]?.contactRef}`, companyRef: `${memoizedOptions[0]?.options[1]?.companyRef}`}].filter((c: any) => c !== false && c.labelEmail !== 'undefined' && c.value !== undefined))
        } else if (props.gridType === 'All Properties/REQ' && props.contactType === 'to') {
            setContactDivs([...props.req?.contacts])
        }
    }, [memoizedOptions, props.contactType, props.gridType, props.req?.contacts])


    useEffect(() => {
        if(props.gridType === 'All Properties/Upcoming' && contactDivs.length){
            dispatch(onSetOwnerContacts(contactDivs))
        }
    },[contactDivs, props.gridType, dispatch])

    return (
        <div>
            <div
                className={s.offerContactInput}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginRight: '30px'
                }}

            >
                {/*<div>{contactDivs}</div>*/}
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center'
                    }}
                >
                    {contactDivs.map((c: any) => {
                        return (
                            <div
                                key={c.value}
                                onClick={() => handleDeleteContact(c.value)}
                                className={s.contactItem}

                            >
                                {c.labelEmail}
                                <span className={s.offerContactDelete}>
                                    <svg width="12" height="12"
                                         viewBox="0 0 12 12"
                                         fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <g clipPath="url(#clip0_1387_36685)">
                                                                            <path
                                                                                d="M6.46967 5.99974L9.233 3.2364C9.28761 3.17264 9.31614 3.09061 9.3129 3.00672C9.30966 2.92283 9.27488 2.84325 9.21552 2.78388C9.15616 2.72452 9.07658 2.68974 8.99268 2.6865C8.90879 2.68326 8.82677 2.71179 8.763 2.7664L5.99967 5.52974L3.23633 2.76307C3.17356 2.7003 3.08843 2.66504 2.99967 2.66504C2.9109 2.66504 2.82577 2.7003 2.763 2.76307C2.70023 2.82584 2.66497 2.91097 2.66497 2.99974C2.66497 3.0885 2.70023 3.17364 2.763 3.2364L5.52967 5.99974L2.763 8.76307C2.7281 8.79295 2.69976 8.82972 2.67976 8.87108C2.65975 8.91243 2.64851 8.95748 2.64673 9.00338C2.64496 9.04929 2.65269 9.09507 2.66945 9.13784C2.68621 9.18062 2.71163 9.21947 2.74412 9.25195C2.7766 9.28444 2.81545 9.30986 2.85822 9.32661C2.901 9.34337 2.94678 9.35111 2.99268 9.34933C3.03859 9.34756 3.08363 9.33632 3.12499 9.31631C3.16634 9.2963 3.20312 9.26796 3.233 9.23307L5.99967 6.46974L8.763 9.23307C8.82677 9.28768 8.90879 9.31621 8.99268 9.31297C9.07658 9.30973 9.15616 9.27496 9.21552 9.21559C9.27488 9.15623 9.30966 9.07665 9.3129 8.99275C9.31614 8.90886 9.28761 8.82684 9.233 8.76307L6.46967 5.99974Z"
                                                                                fill={'rgba(0, 0, 0, 0.54)'}/>
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0_1387_36685">
                                                                                <rect width="12" height="12"
                                                                                      fill="white"/>
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                </span>
                            </div>
                        )
                    })}
                    <ContactInputCreate options={memoizedOptions}
                                        onAddContact={handleAddContact} inputName={props.contactType}/>
                </div>

                <div className={s.openListBtn}>
                    {
                        !contactsForEmail.length && offerType === 'Offer subscription'
                            ?
                            <Tooltip text="Please wait, the contacts are loading"
                                     classname={'brokerageOfferTooltip'}>

                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '15px',
                                        right: '-15px',
                                        bottom: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        // background: 'rgba(255, 255, 255, 0.8)',
                                        zIndex: 1000,
                                    }}
                                >
                                    <FacebookCircularProgress size={18}/>
                                </div>


                            </Tooltip>
                            :
                            <button
                                type={'button'}
                                className={s.offerSelectCreateBtn}
                                onClick={() => setIsContactListVisible(!isContactListVisible)}
                            >
                                <svg width="26" height="26" viewBox="0 0 26 26" style={{
                                    transform: isContactListVisible ? 'rotate(0deg)' : 'rotate(-45deg)'
                                }} fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_1387_34978)">
                                        <path
                                            d="M18.5546 7.41803C15.4721 4.35038 10.4853 4.36244 7.41767 7.44495C4.35002 10.5275 4.36207 15.5142 7.44458 18.5818C10.5271 21.6495 15.5138 21.6374 18.5815 18.5549C21.6491 15.4724 21.6371 10.4857 18.5546 7.41803ZM8.38695 17.6349C5.82774 15.0881 5.81773 10.9465 8.3646 8.38731C10.9115 5.8281 15.053 5.81809 17.6122 8.36496C20.1714 10.9118 20.1814 15.0534 17.6346 17.6126C15.0877 20.1718 10.9462 20.1818 8.38695 17.6349Z"
                                            fill="#1890FF"/>
                                        <path
                                            d="M15.6889 14.8827L13.7951 12.998L15.6798 11.1042C15.7344 11.0493 15.7341 10.9598 15.6793 10.9053L15.0813 10.3101C15.0264 10.2555 14.9369 10.2558 14.8824 10.3106L12.9977 12.2044L11.1038 10.3197C11.049 10.2652 10.9595 10.2654 10.9049 10.3202L10.3097 10.9183C10.2552 10.9731 10.2554 11.0626 10.3102 11.1171L12.2041 13.0019L10.3194 14.8957C10.2648 14.9505 10.265 15.04 10.3198 15.0946L10.9179 15.6898C10.9727 15.7443 11.0622 15.7441 11.1168 15.6893L13.0015 13.7954L14.8954 15.6802C14.9502 15.7347 15.0397 15.7345 15.0942 15.6797L15.6894 15.0816C15.744 15.0268 15.7438 14.9373 15.6889 14.8827Z"
                                            fill="#1890FF"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1387_34978">
                                            <rect width="18" height="18" fill="white"
                                                  transform="translate(12.9688 0.272095) rotate(44.8615)"/>
                                        </clipPath>
                                    </defs>
                                </svg>

                            </button>
                    }


                </div>
            </div>
            <ContactInput
                options={memoizedOptions}
                onAddContact={handleAddContact}
                onDeleteContact={handleDeleteContact}
                isContactVisible={isContactListVisible}
                setIsContactVisible={setIsContactListVisible}
                contactDivs={contactDivs.filter((c: any) => c !== false)}
                gridType={props.gridType}
                isBrokerage={props.isBrokerage}
                defaultContacts={props.req?.defaultContacts!!}
                reqId={props.req?.REQ_ID!}
                uncheckedContacts={props.req?.uncheckedContacts!!}
            />
            {
                props.gridType === 'All Properties/REQ'
                    ?
                    <p style={{
                        position: "absolute",
                        bottom: '-20px',
                        marginBottom: '0',
                        fontSize: '12px',
                        color: '#fb000a'
                    }}>
                        {isPropertiesToError && props.req?.isEmptyContactTo && props.contactType === 'to' ? 'Please select To contact' : ''}
                    </p>
                    :
                    <p style={{
                        position: "absolute",
                        bottom: '-20px',
                        marginBottom: '0',
                        fontSize: '12px',
                        color: '#fb000a'
                    }}>
                        {isPropertiesToError && props.contactType === 'to' ? 'Please select To contact' : isPropertiesCCError && props.contactType === 'cc' ? 'Please select CC contact' : isPropertiesBCCError && props.contactType === 'bcc' ? 'Please select BCC contact' : ''}
                    </p>
            }
        </div>

    );
}


export default PropertyListComponent